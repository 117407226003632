import './App.css';
import Login from './Components/Login/Login';
import MainDash from './Components/Dashboard/MainDash';
import Reg from './Components/Registration/Reg';
import Customers from './Components/Tables/Customers';
import ReportMenu from './Components/Tables/ReportMenu';
import Users from './Components/Tables/Users';
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import RequireAuth from './Components/RequireAuth';
import Layout from './Components/Layout';
import NotFount from './Components/NotFount';
import AdminPanel from './Components/AdminPanel/AdminPanel';
import Profile from './Components/Profile/Profile';
import ForgetPassword from './Components/Login/ForgetPassword';
import ResetPassword from './Components/Login/ResetPassword';
import { DataProvider } from './context/DataContext';
import { AuthProvider } from './context/AuthProvider';
import { KeyGenProvider } from './context/KeyGenData';
import KeyByUuid from './Components/KeyByUuid/KeyByUuid';
import MyInput from './Components/MyInput';
import LicenseKey from './Components/KeyGenerate/LicenseKey';
import { SnackbarProvider } from 'notistack';

function App() {

  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    function handlePopState() {
      if (location.state === 'back') {
        navigate(-1);
      } else if (location.state === 'forward') {
        navigate(1);
      }
    }
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location, navigate]);

  return (
    <div >
      <KeyGenProvider>
      <DataProvider>
      
        <AuthProvider>
          
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }} >

            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="forgot-password" element={<ForgetPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="/offline_activation" element={<KeyByUuid />} />

              <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route path="/" element={<MainDash />} />
                  <Route path="dashboard" element={<MainDash />} />
                  <Route path="license-keys" element={<LicenseKey />} />
                  <Route path="partners" element={<Users />} />
                  <Route path="customers" element={<Customers />} />
                  <Route path="admin-panel" element={<AdminPanel />} />
                  <Route path="new-user" element={<Reg />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="*" element={<NotFount />} />
                  <Route path="reports" element={<ReportMenu />} />
                </Route>
              </Route>
            </Routes>
           
          </SnackbarProvider>
          
        </AuthProvider>
        
      </DataProvider>
      </KeyGenProvider>
    </div>
  );
}

export default App;
