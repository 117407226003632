import { Sidebar, Menu, MenuItem, SubMenu  } from 'react-pro-sidebar';
import React from 'react';
import {UilGlobe, UilUserCircle, UilBuilding, UilUsersAlt, 
     UilDatabaseAlt, UilUnlockAlt, UilClipboardAlt , UilUser, UilLockAccess, UilArrowUp , UilCloudUpload   } from "@iconscout/react-unicons"
import { useState } from 'react';

const SideBar = ({ setSelected, selected }) => {

  const [active, setActive] = useState(true)

  const handleMenuClick = (selected) => {
    setSelected(selected);
    setActive(true);
  }


  return (
    <div id='header'>
      
    <Sidebar backgroundColor="#4fc3f7" className='sideBar' style={{ height: 'auto' , width: '270px'}} >
      <Menu className='pt-5'>
        <SubMenu icon={<UilDatabaseAlt />} label="Master Data">
          <MenuItem 
            active={active && selected === 'country'} 
            icon={<UilGlobe />} 
            onClick={() => handleMenuClick("country")} 
            className={active && selected === "country" ? "active" : "subMenu-item"}
          > 
            Country
          </MenuItem>
          <MenuItem 
            active={active} 
            icon={<UilUserCircle/>} 
            onClick={() => handleMenuClick("userTypes")} 
            className={active && selected === "userTypes" ? "active" : "subMenu-item"}
          > 
            User Types
          </MenuItem>
          <MenuItem 
            active={active} 
            icon={<UilBuilding/>} 
            onClick={() => handleMenuClick("industry")} 
            className={active && selected === "industry" ? "active" : "subMenu-item"}
          > 
            Industry
          </MenuItem>
          <MenuItem 
            active={active} 
            icon={<UilUsersAlt/>} 
            onClick={() => handleMenuClick("userGroups")} 
            className={active && selected === "userGroups" ? "active" : "subMenu-item"}
          > 
            User Groups
          </MenuItem>
          <MenuItem 
            active={active} 
            icon={<UilLockAccess/>} 
            onClick={() => handleMenuClick("access")} 
            className={active && selected === "access" ? "active" : "subMenu-item"}
          > 
            Access
          </MenuItem>   
        </SubMenu> 
        <SubMenu icon={<UilUnlockAlt />} label="Access">
        <MenuItem 
            active={active} 
            icon={<UilUsersAlt/>} 
            onClick={() => handleMenuClick("userGroupAccess")} 
            className={active && selected === "userGroupAccess" ? "active" : 'subMenu-item'}
          > 
            User Group 
        </MenuItem> 
          <MenuItem 
            active={active} 
            icon={<UilUser/>} 
            onClick={() => handleMenuClick("userAccess")} 
            className={active && selected === "userAccess" ? "active" : 'subMenu-item'}
          > 
            User 
        </MenuItem> 
        <MenuItem 
            active={active} 
            icon={<UilClipboardAlt />} 
            onClick={() => handleMenuClick("userTypeHasGroup")} 
            className={active && selected === "userTypeHasGroup" ? "active" : 'subMenu-item'}
          > 
            Manage User Groups 
        </MenuItem> 
        </SubMenu> 
         
          <SubMenu icon={<UilArrowUp />} label="Upload">
        <MenuItem 
            active={active} 
            icon={<UilCloudUpload />} 
            onClick={() => handleMenuClick("fileUpload")} 
            className={active && selected === "fileUpload" ? "active" : 'subMenu-item'}
          > 
            File Upload
        </MenuItem> 
         
        </SubMenu>  
      </Menu>
    </Sidebar>
    </div>
  )
}
export default SideBar;