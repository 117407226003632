import './Profile.css';
import React, {useState, useEffect, useRef, useContext} from 'react'
import { MDBContainer, MDBCard, MDBCardBody,
      MDBRow, MDBCol}from 'mdb-react-ui-kit';
import axios from "../../api/axios";
import { BiEdit } from 'react-icons/bi';
import EditPassword from './EditPassword';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeAheadClear from '../TypeAheadClear';
import SnackbarAlerts from '../SnackbarAlerts';
import DataContext from '../../context/DataContext';
import {ErrorMsgs} from '../../Data/Data'
import { enqueueSnackbar } from 'notistack';

  /* const POSTALCODE_REGEX = /^\d{3,6}$/; */
  const NAME_REGEX = /^.{1,40}$/;
  const TELEPHONE_REGEX = /^\d{10,15}$/
  const MOBILE_REGEX = /^\d{10,15}$/
  const FAX_REGEX = /^\d{10,15}$/
  const USER_DETAILS = process.env.REACT_APP_API_USER_GET_USER_DETAILS;
  const COUNTRY_URL = process.env.REACT_APP_API_COUNTRY_FIND_ALL;  
  const USER_TYPE_URL =process.env.REACT_APP_API_USER_TYPE_FIND_ALL;
  const UPDATE_USER = process.env.REACT_APP_API_USER_UPDATE;

const Profile = () => {

  function useValidation(value, regex) {
    const [isValid, setIsValid] = useState(false);
  
    const validate = () => {
      setIsValid(regex.test(value));
    };
  
    return [isValid, validate];
  }
  
  const countryRef = useRef(null);
  const {isEmpty} = useContext(DataContext);
  let variant = 'success'

  const [user, setUser] = useState([])
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [openEditPw, setOpenEditPw] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userId, setUserId] = useState(0);

  const [company, setCompany] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [fullName, setFullName] = useState('')
  //const [gender, setGender] = useState('')
  const [address, setAddress] = useState('');
  const [no, setNo] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [province, setProvince] = useState('')
  const [tpNo, setTpNo] = useState('')
  const [mobNo, setMobNo] = useState('')
  const [faxNo, setFaxNo] = useState('')
  const [email, setEmail] = useState('')
  const [dob, setDob] = useState('')
  const [vatNo, setVatNo] = useState('')
  const [userType, setUserType] = useState('')
  const [country, setCountry] = useState('')

  const [countryOptions, setCountryOptions] = useState([])
  const [validCountry, setValidCountry] = useState(false);
  const [countryArr, setCountryArr] = useState([])
  const [userTypeOptions, setUserTypeOptions] = useState([])
  const [userTypeArr, setUserTypeArr] = useState([])

  const [validFname, setValidFname] = useState(false)
  const [validTelephone, setValidTelephone] = useState(false)
  const [validMobile, setValidMobile] = useState(false)/* 
  const [validPostalCode, setValidPostalCode] = useState(false) */
  const [validFax, setValidFax] = useState(false)
  
  const [companyFocus, setCompanyFocus] = useState(false)
  const [fNameFocus, setFNameFocus] = useState(false)
  const [teleFocus, setTeleFocus] = useState(false)
  const [mobFocus, setMobFocus] = useState(false)
  const [cityFocus, setCityFocus] = useState(false)
  const [postalCodeFocus, setPostalCodeFocus] = useState(false)

  const fetchUser = async () => {            
    try {
        const response = await axios.get(USER_DETAILS);
        const status = response.data.status
        const result = response.data.result
          if(status){
            if(result){
              if(result.address){
                const noComa = (result.address).replace(/,/g, '')
                setAddress(noComa.trim());
                const AddressResult = result.address;
                const addressArray = AddressResult.split(","); 
                if(addressArray[0]){setNo((addressArray[0]).trim()); }     
                if(addressArray[1]){setStreet((addressArray[1]).trim());} 
                if(addressArray[2]){setCity((addressArray[2]).trim());}
                if(addressArray[3]){setPostalCode((addressArray[3]).trim());} 
                if(addressArray[4]){setProvince((addressArray[4]).trim());}
              }
              setUserId(result.id)
              if(result.companyName) {setCompany(result.companyName)}
              if(result.fname) {setFName(result.fname)}
              if(result.lname){
                setLName(result.lname)
                setFullName(result.fname + '  ' + result.lname) 
              }else{
                setFullName(result.fname) 
              }  
              if(result.tpNo) {setTpNo(result.tpNo)}          
              if(result.mobileNo) {setMobNo(result.mobileNo)}
              if(result.email) {setEmail(result.email)}
              if(result.dob){setDob(result.dob)}
              if(result.vatNo){setVatNo(result.vatNo)}
              if(result.userType) {setUserType(result.userType)}
              if(result.country) {setCountry(result.country)}
            }else{
              setUser([])
            }
          }else{               
            setUser([])
          }
    } catch (err) {    
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }            
    }
  }

  const fetchUserTypes = async () => {
    try {
      const response = await axios.get(USER_TYPE_URL + `/1`);
      const status= response.data.status;
      const result = response.data.result;
      if(status){
        setUserTypeOptions(result);
      }else{
        setUserTypeOptions([]);
      }
    }catch (err) {   
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      } 
    }
  }

  const fetchCountries = async () => {
    try {
      const response = await axios.get(COUNTRY_URL+`1`);
      const status= response.data.status;
      const result = response.data.result;
      if(status){
        setCountryOptions(result);
      }else{
        setCountryOptions([]);
      }
    }catch (err) {   
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      } 
    }
  }

  useEffect(() => {
    document.title = 'Profile'
    fetchUser()
    fetchCountries()
    fetchUserTypes()
  },[]) 

  //set default userType option to update`
  useEffect(() => {
    if((userTypeOptions).find(option => option.type === userType)){
      setUserTypeArr([(userTypeOptions).find(option => option.type === userType)]);
    }
  },[userTypeOptions, userType]);

  //set default country option to update
  useEffect(() => {
    if(countryOptions){
      if((countryOptions).find(option => option.name === country)){
        setCountryArr([(countryOptions).find(option => option.name === country)]);
      }
    }
  },[countryOptions, country]);

  //validate country
  useEffect(() => {
    if(countryArr.length === 0){
      setValidCountry(false);
    }else{
      setValidCountry(true);
    }
  }, [countryArr])

   //check whether country is false for validation
   const validateCountry = () => {
    if (validCountry){
      return true
    }else{
      return false
    }
  }

  //validate fName
  useEffect(() => {
    setValidFname(NAME_REGEX.test(fName));
  }, [fName])

  //validate postal code
  /* useEffect(() => {
    setValidPostalCode(POSTALCODE_REGEX.test(postalCode));
  }, [postalCode]) */

  //validate telephone
  useEffect(() => {
    setValidTelephone(TELEPHONE_REGEX.test(tpNo));
  }, [tpNo])

  //validate mobile
  useEffect(() => {
    setValidMobile(MOBILE_REGEX.test(mobNo));
  }, [mobNo])

  //validate fax
  useEffect(() => {
    setValidFax(FAX_REGEX.test(faxNo));
  }, [faxNo])

  const handleUpdate = async (e) => {
    e.preventDefault();

    if(!validTelephone && !isEmpty(tpNo) ) {
      setTeleFocus(true)
      return
    }
    
    let countryId = countryArr[0].id;
    let userTypeId = userTypeArr[0].id;
    let newAddress = no + " " + street + " " + city + " " + postalCode + " " + province ;

    const updateUser = {id:userId, fname:fName, lname:lName, address: newAddress, tpNo:tpNo, mobileNo:mobNo, 
      faxNo:faxNo, email, dob, vatNo: vatNo, status:true, idCountry:countryId, idUserType:userTypeId}

    try {
      const response = await axios.put(UPDATE_USER, updateUser);
      const status= response.data.status;
      if(status){
        variant = 'success'
        enqueueSnackbar('Profile Update Successful!', {variant}) 
        setIsEdit(false);
      }else{
        variant = 'error'
        enqueueSnackbar('Profile Update Failed!', {variant}) 
      }
    } catch (err) {   
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar('Profile Update Failed!', {variant}) 
      } 
    }
  }

  return (
    <MDBContainer fluid className='mdb-main-con min-height prof-main-con'>
    <EditPassword open={openEditPw} setOpen={setOpenEditPw}/>
          <SnackbarAlerts 
            open={openAlert} 
            setOpen={setOpenAlert} 
            msg={alertMsg} 
            severity={severity}
          /> 
      <MDBCard className='mdb-card-card p-3' style={{borderRadius: '15px'}}>
        <MDBCardBody className='p-0 cards-body'>   
          <MDBRow className='py-3 p-5 '>
            <MDBCol md='12' className='main-details pb-3'>
            <h4 className='name mb-0 pb-0'>{fullName}</h4> 
            <p className='role'>{userType}</p> 
            <p  className='mb-0 pb-0'>Email: {email}</p>  
            <p>
                Password 
                <BiEdit className='icon' onClick={() => setOpenEditPw(true)}/>
            </p>
            </MDBCol>
          </MDBRow>  
          <div className='bg-white pt-0 p-5'>
            <fieldset>
              <legend className='prof-legend'>
                About
                <span className='mx-1'>
                  <BiEdit className='icon' onClick={() => setIsEdit(!isEdit)}/>
                </span>
              </legend> 
              <fieldset disabled={!isEdit} className='fieldset-invisible'>
              <Form>
              <MDBRow>
                <MDBRow className= 'm-0'>
                  <MDBCol md='12'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Company Name</Form.Label> 
                      <span className={isEdit ? 'mandatory' : 'hide'}> *</span>
                      <Form.Control
                        type='text'
                        id='fnameId'
                        onChange={(e) => setCompany(e.target.value)}
                        required
                        value={company} 
                        onFocus={() => setCompanyFocus(true)}
                      />                  
                      <p  className={companyFocus && isEmpty(company) ? "validate" : "offscreen"}>
                        Company name can't be empty.<br />
                      </p>                            
                    </Form.Group>
                  </MDBCol> 
                </MDBRow>
                <MDBRow className={isEdit ? 'm-0' : 'hide'}>
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>First Name</Form.Label> 
                      <span className={isEdit ? 'mandatory' : 'hide'}> *</span>
                      <Form.Control
                          type='text'
                          id='fnameId'
                          onChange={(e) => setFName(e.target.value)}
                          required
                          value={fName} 
                          onFocus={() => setFNameFocus(true)}
                      />  
                      <p id="fnameidnote" className={fNameFocus && !validFname ? "validate" : "offscreen"}>
                          First name can't be empty. <br />
                      </p>                      
                    </Form.Group>
                  </MDBCol>                
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Last Name</Form.Label> 
                      <Form.Control
                          type='text'
                          id='lNameId'
                          onChange={(e) => setLName(e.target.value)}
                          required
                          value={lName} 
                      />                       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='m-0'>
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>VAT/GST No.</Form.Label> 
                      <Form.Control
                          type='text'
                          id='vatId'
                          onChange={(e) => setVatNo(e.target.value)}
                          required
                          value={vatNo} 
                      />                       
                    </Form.Group>
                  </MDBCol>                
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Date of Birth</Form.Label> 
                      <Form.Control
                          type='date'
                          id='dobId'
                          onChange={(e) => setDob(e.target.value)}
                          required
                          disabled={!isEdit}
                          value={dob} 
                      />                       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='m-0'>
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Telephone No.</Form.Label> 
                      <Form.Control
                          type='text'
                          id='newtextId'
                          onChange={(e) => setTpNo(e.target.value)}
                          required
                          value={tpNo} 
                          onFocus={() => setTeleFocus(true)}
                          onBlur={() => setTeleFocus(false)}
                      />    
                      <p id="teleidnote" className={teleFocus && !validTelephone && !isEmpty(tpNo) ? "validate" : "offscreen"}>
                        Enter a valid telephone number. <br />
                      </p>                    
                    </Form.Group>
                  </MDBCol>                
                  <MDBCol md='6'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Mobile No.</Form.Label>
                      <span className={isEdit ? 'mandatory' : 'hide'}> *</span> 
                      <Form.Control
                          type='text'
                          id='newtextId'
                          onChange={(e) => setMobNo(e.target.value)}
                          required
                          value={mobNo} 
                          onFocus={() => setMobFocus(true)}
                      /> 
                      <p id="mobileidnote" className={mobFocus && !validMobile && !isEmpty(mobNo) ? "validate" : "offscreen"}>
                        Enter a valid mobile number.<br />
                      </p>                       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='m-0'>              
                  <MDBCol md='6'>
                    <Form.Group className={isEdit ?  'hide' : 'mb-3'}  >
                      <Form.Label size='sm'>Country</Form.Label>
                      <Form.Control
                          type='text'
                          id='industryId'
                          onChange={(e) => setCountry(e.target.value)}
                          required
                          value={country} 
                      />                       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className={isEdit ? 'm-0' : 'hide'}>
                  <MDBCol md='4'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Address 1</Form.Label> 
                      <Form.Control
                          type='text'
                          id='streetId'
                          onChange={(e) => setNo(e.target.value)}
                          required
                          value={no} 
                      />                       
                    </Form.Group>
                  </MDBCol>                
                  <MDBCol md='4'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Address 2</Form.Label> 
                      <Form.Control
                          type='text'
                          id='streetId'
                          onChange={(e) => setStreet(e.target.value)}
                          required
                          value={street} 
                      />                       
                    </Form.Group>
                  </MDBCol>
                  <MDBCol md='4'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>City</Form.Label> 
                      <span className={isEdit ? 'mandatory' : 'hide'}> *</span>
                      <Form.Control
                          type='text'
                          id='streetId'
                          onChange={(e) => setCity(e.target.value)}
                          required
                          value={city} 
                          onFocus={() => setCityFocus(true)}
                      />   
                      <p id="cityIdNote" className={cityFocus && isEmpty(city) ? "validate" : "offscreen"}>
                          City can't be empty. <br />
                      </p>                      
                    </Form.Group>
                  </MDBCol> 
                </MDBRow>
                <MDBRow className={isEdit ? 'm-0' : 'hide'}>               
                  <MDBCol md='4'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Postal Code</Form.Label> 
                      <Form.Control
                          type='text'
                          id='streetId'
                          onChange={(e) => setPostalCode(e.target.value)}
                          required
                          value={postalCode} 
                          onFocus={() => setPostalCodeFocus(true)}
                          onBlur={() => setPostalCodeFocus(false)}
                      />                   
                    </Form.Group>
                  </MDBCol>
                  <MDBCol md='4'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Province</Form.Label> 
                      <Form.Control
                          type='text'
                          id='streetId'
                          onChange={(e) => setProvince(e.target.value)}
                          required
                          value={province} 
                      />                       
                    </Form.Group>
                  </MDBCol>                
                  <MDBCol md='4'>                    
                    <Form.Group className='mb-3'>
                      <Form.Label size='sm'>Country</Form.Label> 
                      <span className={isEdit ? 'mandatory' : 'hide'}> *</span>
                      <Typeahead
                      id="countryid"
                      ref={countryRef}
                      selected={countryArr}
                      labelKey="name"
                      onChange={(selected) => setCountryArr(selected)}
                      options={countryOptions}         
                      placeholder="Country..."
                      onBlur={validateCountry}
                      style={{display: 'flex'}}
                      ><TypeAheadClear select={countryArr} setSelect={setCountryArr}/></Typeahead>                
                      <p  className={!validCountry ? "validate" : "offscreen"}>
                          SELECT<br />
                      </p>       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className={isEdit ? 'hide' : 'm-0'}>
                  <MDBCol md='12'>
                    <Form.Group className='mb-3' >
                      <Form.Label size='sm'>Address</Form.Label> 
                      <Form.Control
                          type='text'
                          id='newtextId'
                          onChange={(e) => setAddress(e.target.value)}
                          required
                          value={address} 
                      />                       
                    </Form.Group>
                  </MDBCol>
                </MDBRow>
                <MDBRow className={isEdit ?  'm-0' : 'hide'}>
                  <MDBCol md='12'>
                    <Button 
                      onClick={handleUpdate}
                      disabled={!validCountry || !validFname 
                      || !validMobile || isEmpty(company) || isEmpty(city)}
                    >
                      Edit
                    </Button>
                  </MDBCol>
                </MDBRow>
              </MDBRow>
              </Form>          
            </fieldset>
            </fieldset>

          </div> 
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  )
}

export default Profile;
