import './AdminPanel.css';
import React, {useState, useEffect} from 'react'
import { ProSidebarProvider } from "react-pro-sidebar";
import SideBar from './SideBar'
import Country from './Country';
import UserTypes from './UserTypes';
import Industry from './Industry';
import UserGroups from './UserGroups';
import UserAccess from './UserAccess';
import UserGroupAccess from './UserGroupAccess'
import Access from './Access';
import FileUpload from './FileUpload';
import UserTypeHasGroup from './UserTypeHasGroup';


const AdminPanel = () => {
  const [selected, setSelected] = useState('country');

  useEffect(() => {
    document.title = 'Admin Panel';
  },[])
  
  return (
    <ProSidebarProvider>  
      <div className='MainDiv' style={({ display: "flex" })}>
        <SideBar setSelected={setSelected} selected={selected}/>
        <div className='main-div'>    
          <div className='inner-div'>
            <main>   
              {selected === 'country' && <Country />}
              {selected === 'userTypes' && <UserTypes />}
              {selected === 'industry' && <Industry />}
              {selected === 'userGroups' && <UserGroups />}
              {selected === 'access' && <Access />}
              {selected === 'userGroupAccess' && <UserGroupAccess />}
              {selected === 'userAccess' && <UserAccess />}
              {selected === 'userTypeHasGroup' && <UserTypeHasGroup />}
              {selected === 'fileUpload' && <FileUpload />}
            </main>
          </div> 
        </div>
      </div>
    </ProSidebarProvider>
  )
}

export default AdminPanel
