/* import React,{useState, useEffect, useContext} from 'react';
import { MDBRow, MDBCol}from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import { GrClose } from 'react-icons/gr';
import KeyGenData from '../../context/KeyGenData';
import Form from 'react-bootstrap/Form';
import { Variables } from '../../Data/Data';

const UnlimitedPopUp = (props) => {

  const maxUserCount = Variables.maxUserCount

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  }
  
  return (
    <div className={props.open ? 'glass-effect' : ''}> 
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        >   
          <div className='table-div'> 
            <div className='end-div p-0'>
              <GrClose className='close-button' onClick={() => props.setOpen(false)} />
            </div>   
              <div className='d-flex justify-content-center'>              
                <MDBCol md='12' className='bg-white p-4 popup-style m-1'>
                    <div className='mb-3 d-flex justify-content-center'>
                      User count will be considered as unlimited from {maxUserCount} on
                      wards. {maxUserCount} number of users will be consumed from the selection.
                      But users can be created as many as needed.
                    </div>
                    <div className='d-flex justify-content-center'>
                      <Form.Group controlId="formBasicCheckbox">                        
                        <Form.Check 
                          type="switch" 
                          id='freeCheckId'
                          label="Enable Unlimited" 
                          checked={props.unlimited}
                          onChange={() => props.setUnlimited(!props.unlimited)}/>
                      </Form.Group>                         
                    </div>             
                </MDBCol>  
              </div> 
          </div> 
      </Popover>
    </div>
  );
}

export default UnlimitedPopUp   */
/* 
import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import { GrClose } from 'react-icons/gr';
import KeyGenData from '../../context/KeyGenData';
import Form from 'react-bootstrap/Form';
import { Variables } from '../../Data/Data';
import Button from 'react-bootstrap/Button';

const UnlimitedPopUp = (props) => {
  const maxUserCount = Variables.maxUserCount;

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  };

  const handleOK = () => {
    props.setUnlimited(!props.unlimited);
    props.setOpen(false);
    // Perform any additional actions needed
  };

  return (
    <div className={props.open ? 'glass-effect' : ''}>
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className='table-div'>
          <div className='end-div p-0'>
            <GrClose className='close-button' onClick={() => props.setOpen(false)} />
          </div>
          <div className='d-flex justify-content-center'>
            <MDBCol md='12' className='bg-white p-4 popup-style m-1'>
              <div className='mb-3 d-flex justify-content-center'>
                User count will be considered as unlimited from {maxUserCount} onwards. {maxUserCount} number of users will be consumed from the selection. But users can be created as many as needed.
              </div>
              <div className='d-flex justify-content-center'>
                <Button variant='primary' onClick={handleOK}>OK</Button>
              </div>
            </MDBCol>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default UnlimitedPopUp;   */
 import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import { GrClose } from 'react-icons/gr';
import KeyGenData from '../../context/KeyGenData';
import Form from 'react-bootstrap/Form';
import { Variables } from '../../Data/Data';
import Button from 'react-bootstrap/Button';

const UnlimitedPopUp = (props) => {
  const { uCountId, setUCountId } = useContext(KeyGenData);
  const maxUserCount = Variables.maxUserCount;

  const handleClose = (reason) => {
    if (reason === 'ok') {
      return;
    }
    props.setOpen(false);
  };

  const handleOK = (event) => {
    //props.setUnlimited(false); // Turn on the radio button
     props.setOpen(false); // Close the popup window
    //alert('called');
    // Perform any additional actions needed
   // props.setUnlimited(!props.unlimited);
   
  };
const handleCancel = () => {
    props.setOpen(false); // Close the popup 
  }; 



  return (
    <div className={props.open ? 'glass-effect' : ''}>
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className='table-div'>
          <div className='d-flex justify-content-center'>
            <MDBCol md='12' className='bg-white p-3 popup-style m-1'>
              <div className='mb-3 d-flex justify-content-center'>
                User count will be considered as unlimited from {maxUserCount} onwards. {maxUserCount} number of users will be consumed from the selection. But users can be created as many as needed.
              </div>
              <div style={{ margin: '10px' }}></div>
              <div className='d-flex justify-content-end'>
                <Button variant='primary' onClick={handleOK}>OK</Button>{' '}
                {/* <div style={{ width: '10px' }}></div> {/* Horizontal space */}
                {/* <Button variant='secondary' onClick={handleCancel}>Cancel</Button>  */}
              </div>
            </MDBCol>
          </div>
        </div>
      </Popover>
      {props.open && (
        <div
          className="backdrop"
          onClick={() => props.setOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default UnlimitedPopUp;
  