// KeyHistoryPopup.js
import axios from "../../api/axios";
import IconButton from '@mui/material/IconButton';
import { GrClose } from 'react-icons/gr';
import PrintIcon from '@mui/icons-material/Print';
import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component'; // Import DataTable component
import { Modal, Button } from 'react-bootstrap'; // You can use any UI library
import KeyGenData from '../../context/KeyGenData';
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthProvider";
import './KeyHistoryPopup.css';

const KEY_HISTORY = process.env.REACT_APP_API_KEY_HISTORY;


const KeyHistoryPopup = ({ open, setOpen }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overflowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  };

  const [keyHistory, setKeyHistory] = useState([]);
  const { keyGenId } = useContext(KeyGenData)
  const { fullName } = useContext(DataContext)
  const { printAccess } = useContext(AuthContext)

  useEffect(() => {
    if (open) {
      // Fetch key history data when the popup is opened
      fetchKeyHistory();
    }
  }, [open]);

  const fetchKeyHistory = async () => {
    try {
      const response = await axios.get(KEY_HISTORY + `${keyGenId}`);
      const { status, msg, httpStatus, result } = response.data;
      if (status) {
        setKeyHistory(result);
      } else {
        console.error("Error fetching key history:", msg);
        // Handle error cases where status is false
      }
    } catch (error) {
      console.error("Error fetching key history:", error);
      // Handle errors if any
    }
  };

 /*  const extractUpdatedUUID = (description) => {
    const updatedUUIDIndex = description.indexOf('Update UUID :');
    if (updatedUUIDIndex !== -1) {
      const startIndex = updatedUUIDIndex + 13; // Length of 'Update UUID :'
      const endIndex = description.indexOf('\n', startIndex); // Find the end of the line
      if (endIndex !== -1) {
        return description.substring(startIndex, endIndex).trim();
      } else {
        // If there's no newline character, take the substring from startIndex till the end
        return description.substring(startIndex).trim();
      }
    }
    return 'N/A';
  }; */

  const handleClose = (event) => {

    setOpen(false);

  };

  const handlePrint = () => {
    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();

    // Determine the status based on the last item's keyGen.status field
    const lastKeyStatus = keyHistory.length > 0 ? keyHistory[keyHistory.length - 1].keyGen?.status : null;
    const keyStatusText = lastKeyStatus !== null 
        ? (lastKeyStatus ? '<span style="color:green">Activated</span>' : '<span style="color:red">Deactivated</span>')
        : 'N/A';

    // Construct the content for printing
    const content = `
    <html>
      <head>
        <style>
          body { font-family: 'Calibri Light', sans-serif; }
          .print { margin-top: none; }
          .top-header { font-size: 16px; font-weight: bold; color: #595757; }
          .table { width: 100%; border-collapse: collapse; border: solid #000; }
          .table th, .table td { border: 1px solid #000; padding: 8px; text-align: left; }
          .table th { background-color: #f2f2f2; color: #595757; }
          .footer { position: fixed; bottom: -10.5px; width: 100%; text-align: right; padding: 10px 0; border-top: 1px solid #ddd; }
        </style>
      </head>
      <body>
        <div class="print">
          <div class="top-header">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <p style="margin: 0; font-size: 28px;">KEY HISTORY OF "${keyHistory.length > 0 ? keyHistory[0].keyGen?.uuid || 'N/A' : 'N/A'}" ${keyStatusText}</p>
              <div style="text-align: right;">
                <p style="color: #888888; margin: 0;">Date: ${printDateTime}</p>
                <p style="color: #888888; margin: 0;">User Name: ${fullName.result ? (fullName.result.fname + (fullName.result.lname ? ' ' + fullName.result.lname : '')) : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
              <th>User</th>
              <th>Days</th>
              <th>Users</th>
              <th>Key Type</th>
              <th>From Date</th>
              <th>To Date</th>
            </tr>
          </thead>
          <tbody>
            ${keyHistory.map(row => `
              <tr>
                <td>${row.createdDate || 'N/A'}</td>
                <td>${row.description || 'N/A'}</td>
                <td>${row.createdBy || 'N/A'}</td>
                <td>${row.days || 'N/A'}</td>
                <td>${row.users || 'N/A'}</td>
                <td>${row.keyGen.type || 'N/A'}</td>
                <td>${row.fromDate || 'N/A'}</td>
                <td>${row.toDate || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
        <script>
          window.print();
          window.close();
        </script>
      </body>
    </html>
    `;

    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();
    } else {
      console.error('Failed to open print window');
    }
};



  const columns = React.useMemo(
    () => [


      {
        name: 'Created Date',
        selector: (row) => row.createdDate || 'N/A',
        wrap: true,
        width: '110px',
      },
      {
        name: 'Action',
        selector: (row) => row.description || 'N/A',
        wrap: true,

      },
      {
        name: 'User',
        selector: (row) => row.createdBy || 'N/A',
        wrap: true,
      },
      {
        name: 'Days',
        selector: (row) => row.days || 'N/A',
        wrap: true,
        width: '60px',
      },
      {
        name: 'User Count',
        selector: (row) => row.users || 'N/A',
        wrap: true,
        width: '80px',
      },
      {
        name: 'Valid From',
        selector: (row) => row.fromDate || 'N/A',
        wrap: true,
        width: '110px',
      },
      {
        name: 'Valid To',
        selector: (row) => row.toDate || 'N/A',
        wrap: true,
        width: '110px',
      },
      /* {
        name: 'Updated UUID', // New column header
        selector: (row) => {
          // Extract updated UUID from description
          const description = row.description;
          const updatedUUIDIndex = description.indexOf('Update UUID :');
          if (updatedUUIDIndex !== -1) {
            const updatedUUID = description.substring(updatedUUIDIndex + 13); // Extract UUID after 'Update UUID :' (13 characters)
            return updatedUUID;
          }
          return 'N/A'; // Return 'N/A' if not found
        },
        wrap: true,
      }, */
    ],
    []
  );

  return (


    <Modal show={open} onHide={handleClose} size="lg" >

      <Modal.Body style={{ borderTop: '20px solid #40c4ff', borderBottom: '20px solid #40c4ff', borderLeft: '20px solid #40c4ff', borderRight: '20px solid #40c4ff' }}>
        {printAccess && (
          <Button onClick={handlePrint} className="close-button" style={{ position: 'absolute', top: 0, padding: '2px', fontWeight: 'normal' }}>
            <IconButton size="small">
              <PrintIcon style={{ fontSize: '18px', color: 'white' }} />
            </IconButton>
            <span style={{ fontSize: '18px', paddingRight: '8px' }}>Print </span>

          </Button>
        )}



        <GrClose onClick={handleClose} className="close-button" style={{ borderColor: '#40c4ff', top: 0, left: '20px', marginLeft: printAccess ? '0' : '898px' }} />

        <div style={{ padding: '20px', borderBottom: '1px solid #40c4ff', display: 'flex', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold' }}>
          {/* {keyHistory.length > 0 ? keyHistory[0].keyGen?.uuid || 'N/A' : 'N/A'} */}
          {keyHistory.length > 0 ? (
            <>
              {keyHistory[0].keyGen?.uuid || 'N/A'} - {' '}
              <span style={{ color: keyHistory[keyHistory.length - 1].description === 'DEACTIVATED' ? 'red' : 'rgb(0, 128, 0)' }}>
                {keyHistory[keyHistory.length - 1].description === 'DEACTIVATED' ? ' Deactivated' : ' Activated'}
              </span>
            </>
          ) : (
            'N/A'
          )}
        </div>
        <div style={{ overflowX: 'auto', maxHeight: '500px' }}>
          {keyHistory.length > 0 ? (
            <DataTable
              columns={columns}
              data={keyHistory.reverse()}
              customStyles={customStyles}
              pagination
              responsive
              //noHeader
              highlightOnHover
              paginationComponentOptions={{ noRowsPerPage: true }}
            />
          ) : (
            <p style={{ textAlign: 'center', fontSize: '20px' }}>No key history available</p>
          )}
        </div>
      </Modal.Body>
    </Modal>

  );
};

export default KeyHistoryPopup;

