import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

const NotFount = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(()=>{
            navigate("/dashboard")
        })
    },[navigate])
  return (
    <div>
        <h1>Page not Found</h1>
    </div>
  )
}

export default NotFount