import React, { useEffect, useState, useRef, useContext } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from '../../api/axios';
import './TextCard.css';
import Button from 'react-bootstrap/Button';
import TypeAheadClear from '../TypeAheadClear';
import SnackbarAlerts from '../SnackbarAlerts';
import { useNavigate, useLocation } from 'react-router-dom';
import { UilTimes, UilCheck } from "@iconscout/react-unicons";
import KeyGenData from '../../context/KeyGenData';
import AuthProvider from '../../context/AuthProvider';
import DataContext from '../../context/DataContext';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, Variables, DaysOptions, YearsOptions } from '../../Data/Data'
import UnlimitedPopUp from './UnlimitedPopUp';
import KeyExtendView from './KeyExtendView';

//const UUID_REGEX = /^[A-Z0-9]{5,15}-[A-Z0-9]{5,15}-[A-Z0-9]{5,15}-[A-Z0-9]{5,15}$/;
const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;
//const CUSTOMER_BY_SELLER = process.env.REACT_APP_API_CUSTOMER_FIND_BY_SELLER_ID;
const KEY_EXTEND = process.env.REACT_APP_API_KEY_EXTEND
const KEY_WALLET_URL = process.env.REACT_APP_API_GET_KEY_WALLET;
const UUID_SEARCH = process.env.REACT_APP_API_CHECK_UUID;
//const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const CHECK_KEY_EXTEND = process.env.REACT_APP_API_CHECK_KEY_EXTEND;
const FIND_SELLERS = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const FIND_SELLER_ID = process.env.REACT_APP_API_GET_KEY_WALLET;

const Extend = () => {

  let variant
  let maximumDays = 0;
  let maximumYears = 0;


  const currentDate = new Date();
  const currentDateOnly = currentDate.toDateString();
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];

  function useValidation(value, regex) {
    const [isValid, setIsValid] = useState(false);
    const validate = () => {
      setIsValid(regex.test(value));
    };
    return [isValid, validate];
  }


  const { uuidEdit, setUuidEdit, setGenerateUuid, } = useContext(KeyGenData)
  
  const { subscription, setSubscription } = useContext(DataContext)
  // Calculate years based on days

  const customerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state;
  const [edit, setEdit] = useState(false)
  const [uuidInDatabase, setUuidInDatabase] = useState(false)
  const [searchCliked, setSearchCliked] = useState(false)
  const [keyGenId, setKeyGenId] = useState('');
  const [totalDaysCount, setTotalDaysCount] = useState(0);
  const [totalYearsCount, setTotalYearsCount] = useState(0);
  const [totalYearsPerpetualCount, setTotalYearsPerpetualCount] = useState(0);

  const [customerId, setCustomerId] = useState(0);
  const [customerName, setCustomerName] = useState('')
  const [noCustomer, setNoCustomer] = useState(false);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [openKeyViewPopup, setOpenKeyViewPopup] = useState(false);
  const [pressedView, setPressedView] = useState(false);
  //const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dateUpdated, setDateUpdated] = useState(false);
  const [uuidDisabled, setUuidDisabled] = useState(false);


  const [keyType, setKeyType] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setServerity] = useState('success');
  const [message, setMessage] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [validFromLabel, setValidFromLabel] = useState('Valid From');
const [validToLabel, setValidToLabel] = useState('Valid To');


  /* const [customDays, setCustomDays] = useState(''); */
  const [isChecked, setIsChecked] = useState(false);

  const minMonth = Variables.minMonth;
  const [months, setMonths] = useState('');
  const [checkMinMonths, setCheckMinMonths] = useState(false);
  const [nullYears, setNullYears] = useState(true)
  //const [monthsDisable, setMonthsDisable] = useState(false)
  const [submitSuccess, setsubmitSuccess] = useState(false)
  const [assign, setAssign] = useState(false)

  const [days, setDays] = useState('');
  const [nullDays, setNullDays] = useState(true)
  const [userCount, setUserCount] = useState('');
  const [uuid, setUuid] = useState('');
  const [description, setDescription] = useState('');
 // const [licenseKey, setLicenseKey] = useState('');
  const [userId, setUserId] = useState(0);
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const dateTo = new Date(validTo);
  const differenceInTime = dateTo.getTime() - currentDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));


  const [keyWallet, setKeyWallet] = useState([]);
  const [available, setAvailable] = useState('');
  const [perpetualAvailable, setPerpetualAvailable] = useState('');
  const [freeAvailable, setFreeAvailable] = useState('');
  const [keys, setKeys] = useState(0);
  const [keyValidate, setKeyValidate] = useState(false);
 // const [fetchExtendSuccess, setFetchExtendSuccess] = useState(true);

  const [status, setStatus] = useState(0)
  const [extend, setExtend] = useState(true)
  const [usedUuid, setUsedUuid] = useState(true);
  const [validUuid, setValidUuid] = useState(false);
  const [isValidUuid, validateUuid] = useValidation(uuid, UUID_REGEX);
  const [uuidFocus, setUuidFocus] = useState(false);


  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState('');
  const [msg, setMsg] = useState('');
  const [generated, setGenerated] = useState(0);
  const [uuidStatus, setUuidStatus] = useState(true);
  const [uuidChecked, setUuidChecked] = useState(false)
  const [selectedYears, setSelectedYears] = useState('');
  const [selectedDays, setSelectedDays] = useState('');

  const [equivalentDays, setEquivalentDays] = useState('');
  const [yearsNew, setYearsNew] = useState('')


  const [daysNew, setDaysNew] = useState('')
  const [uCountNew, setUCountNew] = useState('')
  const originalUCountNewRef = useRef(uCountNew);
  const [usersNewInt, setUsersNewInt] = useState(0)
  const [newValidTo, setNewValidTo] = useState('')
  const [monthAll, setMonthAll] = useState(0)
  const [totalDays, setTotalDays] = useState(0)
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [extendClicked, setExtendClicked] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false)
  const [sentRequest, setSendRequest] = useState(false)
  const { isEmpty } = useContext(DataContext);
  const [totDaysMax, setTotDaysMax] = useState(false);
  //const [totDaysNotUserMax, setTotDaysNotUserMax] = useState(false);
  const [totYearsMax, setTotYearsMax] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const val = 0;

  const [openUnlimited, setOpenUnlimited] = useState(false)
  const [unlimited, setUnlimited] = useState(false)
  const [prevUnlimited, setPrevUnlimited] = useState(false)
  const [years, setYears] = useState('')
  const maxUserCount = Variables.maxUserCount
  const aMonth = Variables.aMonth
  const considerAMonth = Variables.considerAMonth
  const [extendMsg, setExtendMsg] = useState('')
  const [exErr, setExErr] = useState(false)

  const [isMaxTime, setIsMaxTime] = useState(false);
  const [isMaxNotUserTime, setIsMaxNotUserTime] = useState(false);
  const [isLessDate, setIsLessDate] = useState(false);
  const [isExpiredDate, setIsExpiredDate] = useState(false);
  const [isExpiredDateMax, setIsExpiredDateMax] = useState(false);
  const [isExpiredMax, setIsExpiredMax] = useState(false);
  const [maxMsg, setMaxMsg] = useState('');
  const [exMaxMsg, setExMaxMsg] = useState('');
  const [validMsg, setValidMsg] = useState('');
  //const [validExtendMsg, setValidExtendMsg] = useState('');
  const [validExMsg, setValidExMsg] = useState('');

  const maxYrs = 5;//5
  const maxDays = 45; //45
  const aYear = aMonth * 12;
  const totYearCount = Math.round(totalYearsCount / 372);

  const [errors, setErrors] = useState('')
  const [isErr, setIsErr] = useState('')
  //const [selectedUuid, setSelectedUuid] = useState(null);

  const totalYears = Math.round(selectedDays / 372) + (parseInt(yearsNew) > 0 ? parseInt(yearsNew) : 0);
  const prevYears = Math.round(selectedDays / 372);
  const perpetualKey = keyType === 'PERPETUAL' && validFrom === validTo;

  const keyWalletFetch = async () => {
    try {
      const response = await axios.get(KEY_WALLET_URL);
      const status = response.data.status
      const result = response.data.result
      //console.log("data Extend ", response.data.result)
      if (status) {
        if (result) {
          setKeyWallet(result);
        } else {
          setKeyWallet({ available: 0, freeAvailable: 0 })
        }
      } else {
        setKeyWallet({ available: 0, freeAvailable: 0 })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  useEffect(() => {
    document.title = 'Key Extend';
    keyWalletFetch();

  }, [])


  useEffect(() => {
    if (keyType === 'Perpetual') {
      setValidFromLabel('Subscription Valid From');
      setValidToLabel('Subscription Valid To');
    } else {
      setValidFromLabel('Valid From');
      setValidToLabel('Valid To');
    }
  }, [keyType]);
  

  //validate uuid
  useEffect(() => {
    setValidUuid(UUID_REGEX.test(uuid));
    if (uuid.includes(' ')) {
      setUuid(uuid.trim())
    }
    if (from) {
      setEdit(from[0])
      setCustomerId(from[1]);
    }
  }, [uuid, from])

  useEffect(() => {
    if (keyType === 'Permanent' && totalYears > maxYrs   ) {
      setMaxMsg('Maximum Years are 5')
      setIsMaxTime(true)
      setNewValidTo(validTo)
    } else if (keyType === 'Perpetual' && totalYears > maxYrs   ) {
      setMaxMsg('Maximum Years are 5')
      setIsMaxTime(true)
      setNewValidTo(validTo)
    }  else if (keyType === 'Temporary' && totalDays > maxDays ) {

      setMaxMsg('Maximum days are 45')
      setIsMaxTime(true)
      setNewValidTo(validTo)

    } else {
      setMaxMsg('')
      setIsMaxTime(false)
      setNewValidTo(newValidTo)
    }
  }, [totalYears, totalDays, keyType, newValidTo, totYearsMax, totDaysMax, validTo])


  useEffect(() => {

    const dateNewTo = new Date(newValidTo);
    const expireDays = Math.ceil((dateNewTo - currentDate) / (1000 * 3600 * 24));

    if (keyType === 'Permanent' ) {
      if (expireDays < aMonth) {

        setIsLessDate(true);

      } else {
        setValidMsg('');
        setIsLessDate(false);
      }
    }

    if (keyType === 'Perpetual' ) {
      if (expireDays < aMonth) {

        setIsLessDate(true);

      } else {
        setValidMsg('');
        setIsLessDate(false);
      }
    }

    if (keyType === 'Temporary') {
      if (expireDays < 5) {

        setIsLessDate(true);

      } else {
        setValidMsg('');
        setIsLessDate(false);
      }
    }
  }, [keyType, currentDate, aMonth, isLessDate]);

  /* useEffect(() => {

    if(yearsNewInt !== ''){

    }
   
  
}, [keyType, validTo, formattedCurrentDate, isExpiredDate]);  */

  // Log before updating validTo


  // ... (your existing code for extending the key)

  // Log after updating validTo

  useEffect(() => {
    if (keyGenId !== '') {
      fetchExtendedStatus();
    }
  }, [keyGenId]);


  //console.log("Years  :", totYearCount);

  useEffect(() => {

    let yearsNewInt = 0
    let daysNewInt = 0
    let newAddDays = 0


    

    if (parseInt(yearsNew) === 0) {
      setSelectedDays(0);

    }
    if (parseInt(daysNew) === 0) {
      setSelectedDays(0);
    }




    //get total months to display
    if (yearsNew !== '' && !isLessDate) {
      yearsNewInt = parseInt(yearsNew)
      setSelectedDays(selectedDays + yearsNewInt)
    } else {
      setNewValidTo(validTo)
      setSelectedDays(selectedDays)//remove months
    }

    if (daysNew !== '' && !isLessDate) {
      daysNewInt = parseInt(daysNew)
      setSelectedDays(selectedDays + daysNew)
    } else {
      setNewValidTo(validTo)
      setSelectedDays(selectedDays)//remove months
    }

    if (validTo > currentDateOnly && validTo !== '') {
      setUuidChecked(false);
      setSaveDisable(true)
      setIsErr(true);
      setErrors('This is an old key. This key cannot be extended. Please disable this key and generate a new one.');
    } else {
      setUuidChecked(true);
      setSaveDisable(false)
      setIsErr(false);
      setErrors('');
    }// check expired keys

    /* 
        if (keyType === 'Permanent') {
          
          if (formattedCurrentDate > validTo ) {
            setIsExpiredDate(true);
            //console.log("Expired Date", isExpiredDate);
            setValidMsg('Key is expired, You need to add more years before adding users');
    
    
            setExErr(true);
            if(totYearCount > maxYrs){
              setIsExpiredMax(true);
            setValidMsg('Key is expired, You cannot add more dates');
            setExErr(true);
            }else{
              setIsExpiredMax(false);
            setValidMsg('');
            }
          } else {
            setIsExpiredDate(false);
            setValidMsg('');
    
          }
    
        } else if (keyType === 'Temporary') {
          if (formattedCurrentDate > validTo ) {
            setIsExpiredDate(true);
            setValidMsg('Key is expired, You need to add more days before adding users');
            setNewValidTo(validTo);
    
            //console.log("Expired Valid Date", newValidTo);
            setExErr(true);
            if(totalDaysCount > 6){
              setIsExpiredDateMax(true);
            setValidMsg('Key is expired, You cannot add more dates');
            setExErr(true);
            }
            else{
              setIsExpiredDateMax(false);
            setValidMsg('');
            }
          } else {
            setIsExpiredDate(false);
            setValidMsg('');
    
          }
        }
        console.log("Expired ", isExpiredDateMax); */
        maximumDays = parseInt(totalDaysCount) + parseInt(daysNew);
        //console.log("Maximum Days ", maximumDays);
        maximumYears = parseInt(totYearCount) + parseInt(yearsNew);
        //console.log("Maximum Years ", maximumYears);

    if (keyType === 'Permanent' ) {
      if (formattedCurrentDate > validTo) {
        setIsExpiredDate(true);
        setValidMsg('Key is expired, You need to add more years before adding users');
        setExErr(true);

        if (maximumYears > maxYrs) {
          setIsExpiredMax(true);
          setTotYearsMax(true)
          setValidExMsg('Key is expired, You cannot add more dates');
          setValidMsg('')
        } else {
          setIsExpiredMax(false);
          setTotYearsMax(false)
          setValidExMsg('');
          setValidMsg('Key is expired, You need to add more days before adding users');
        }
      } else {
        setIsExpiredDate(false);
        setValidMsg('');
      }
    } 

    if (keyType === 'Perpetual' ) {
      if(validFrom !== validTo){
      if (formattedCurrentDate > validTo) {
        setIsExpiredDate(true);
        setValidMsg('Subscription is expired, You need to add more years');
        setExErr(true);

        if(validFrom !== validTo ){
          if (maximumYears > maxYrs) {
            setIsExpiredMax(true);
            setTotYearsMax(true)
            setValidExMsg('Subscription is expired, You cannot add more years');
            setValidMsg('')
          } else {
            setIsExpiredMax(false);
            setTotYearsMax(false)
            setValidExMsg('');
            setValidMsg('Subscription is expired, You need to add more years');
          }
        }
       
      } else {
        setIsExpiredDate(false);
        setValidMsg('');
      }
    } 
  }
    
    else if (keyType === 'Temporary') {
      if (formattedCurrentDate > validTo) {
        setIsExpiredDate(true);
        setValidMsg('Key is expired, You need to add more days before adding users');
        setNewValidTo(validTo);
        setExErr(true);


        if (maximumDays > maxDays) {

          setIsExpiredDateMax(true);
          setTotDaysMax(true)
          setValidExMsg('Key is expired, You cannot add more dates');
          setValidMsg('')
        } else {
          setIsExpiredDateMax(false);
          setTotDaysMax(false)
          setValidExMsg('');
          setValidMsg('Key is expired, You need to add more days before adding users')
        }
      } else {
        setIsExpiredDate(false);
        setValidMsg('');
      }
    }

   

   
   /*  if (keyType === 'Temporary') {
      if (maximumDays > maxDays && userCount === maxUserCount) {
        setTotDaysMax(true);
        setIsMaxTime(true)
        setSaveDisable(true)
        setExMaxMsg('Maximum days are 45')
      } else {
        setTotDaysMax(false);
        setIsMaxTime(false);
        setSaveDisable(false);
        setExMaxMsg(' ')
      }
    }

    else if (keyType === 'Permanent') {
      if (maximumYears > maxYrs && userCount === maxUserCount) {
        setTotYearsMax(true);
        setIsMaxTime(true)
        setSaveDisable(true)
        setExMaxMsg('Maximum years are 5')
      } else {
        setTotYearsMax(false);
        setIsMaxTime(false);
        setSaveDisable(false);
        setExMaxMsg(' ')
      }
    } */

    // Check if userCount has increased and expiration date was reduced
    const fullMonths = Math.floor(differenceInDays / aMonth)
    const remainDays = (differenceInDays % aMonth)

    let newDays = 0
    // y=year m=month d=day
    let y = 0
    let remainY = 0
    let m = 0
    let d = 0

    if (keyType === 'Permanent' ) {

      if (uCountNew !== '' && totalUserCount !== 0) {

        newDays = Math.round(differenceInDays * userCount / totalUserCount)
        y = Math.floor(newDays / aYear)
        remainY = newDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt
        if (yearsNew === '' || yearsNew === '0') {
          if (newDays >= aMonth) {

            currentDate.setDate(currentDate.getDate() + newDays);
            //console.log("New Days " + newDays + " \n current " + currentDate + "\n" + y + ' year/s, ' + m + ' month/s and ' + d)
            const newDateStr = currentDate.toISOString().substring(0, 10);

            setNewValidTo(newDateStr);
            setKeys(0)
            if (!isExpiredDate ) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              if (saveDisable && validMsg) {
                setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
                setExErr(false)
              } else {
                setExtendMsg('');

              }
            }
          } else {
            if(keyType !== 'Perpetual'){
            setExtendMsg('Add one or more years because license will expire in less than a month (in ' + d + ' days)')
            setExErr(true)
            setKeys(0)
            }
            
          }
        } else {
          let yearDays = yearsNew * aYear
          currentDate.setDate(currentDate.getDate() + newDays + yearDays);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);
          setKeys(totalUserCount * yearsNew)
          if (!isExpiredDate ) {
            setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');

            setExErr(false)
          } else {
            if ((saveDisable && validMsg) || perpetualKey) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              setExtendMsg('');
              setExErr(false)
            }
          }

        }

      } else {
        y = Math.floor(differenceInDays / aYear)
        remainY = differenceInDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt
        if (yearsNew !== '') {
          let yearDays = yearsNew * aYear
          currentDate.setDate(currentDate.getDate() + differenceInDays + yearDays);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);
          setKeys(totalUserCount * yearsNew)
          if (!isMaxTime && !isExpiredDate) {
            setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');

            setExErr(false)
          } else {
            if (saveDisable && validMsg) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              setExtendMsg('');
              setExErr(false)
            }
          }
        } else {
          setExtendMsg('')
        }
      }

    }/*  else if (keyType === 'Perpetual') {

      // Use current date as the base if validFrom is equal to validTo
          const baseDate = (validFrom === validTo) ? new Date() : new Date(validTo);

      if (uCountNew !== '' && totalUserCount !== 0) {

        newDays = Math.round(differenceInDays * userCount / totalUserCount)
        y = Math.floor(newDays / aYear)
        remainY = newDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt
        if (yearsNew === '' || yearsNew === '0') {
          if (newDays >= aMonth) {

            currentDate.setDate(currentDate.getDate() + newDays);
            //console.log("New Days " + newDays + " \n current " + currentDate + "\n" + y + ' year/s, ' + m + ' month/s and ' + d)
            const newDateStr = currentDate.toISOString().substring(0, 10);

            setNewValidTo(newDateStr);
            setKeys(0)
            if (!isExpiredDate ) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              if (saveDisable && validMsg) {
                setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
                setExErr(false)
              } else {
                setExtendMsg('');

              }
            }
          } else {
            if(keyType !== 'Perpetual'){
            setExtendMsg('Add one or more years because license will expire in less than a month (in ' + d + ' days)')
            setExErr(true)
            setKeys(0)
            }
            
          }
        } else {
          let yearDays = yearsNew * aYear
          //currentDate.setDate(currentDate.getDate() + newDays + yearDays);
          baseDate.setDate(baseDate.getDate() + newDays + yearDays);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);
          setKeys(totalUserCount * yearsNew)
          console.log("New Days " + newDays + " \n current " + currentDate + "\n" + y + ' year/s, ' + m + ' month/s and ' + d)
          if (!isExpiredDate  ) {
            setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');

            setExErr(false)
          } else {
            if (saveDisable && validMsg ) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              setExtendMsg('');
              setExErr(false)
            }
          }

        }

      } else {
        y = Math.floor(differenceInDays / aYear)
        remainY = differenceInDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt
        if (yearsNew !== '') {
          let yearDays = yearsNew * aYear
          currentDate.setDate(currentDate.getDate() + differenceInDays + yearDays);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);
          setKeys(totalUserCount * yearsNew)
          if (!isMaxTime && !isExpiredDate  ) {
            setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');

            setExErr(false)
          } else {
            if (saveDisable && validMsg) {
              setExtendMsg('Key will expire in ' + Math.abs(y) + ' year/s, ' + Math.abs(m) + ' month/s and ' + Math.abs(d) + ' day/s.');
              setExErr(false)
            } else {
              setExtendMsg('');
              setExErr(false)
            }
          }
        } else {
          setExtendMsg('')
        }
      }

    } 
     */

    else if (keyType === 'Perpetual') {
      // Check for valid user count and total user count
      if (uCountNew !== '' && totalUserCount !== 0) {
          // Convert inputs to valid numbers
          const validUCountNew = parseInt(uCountNew, 10) || 0;
          const validTotalUserCount = parseInt(totalUserCount, 10) || 1; // Avoid division by zero
  
          // Calculate newDays
          newDays = Math.round(differenceInDays * validUCountNew / validTotalUserCount);
          
          // Convert days into years, months, and days
          y = Math.floor(newDays / aYear);
          remainY = newDays % aYear;
          m = Math.floor(remainY / aMonth);
          d = remainY % aMonth;
          y += yearsNewInt; // Include additional years
  
          // Use current date as the base if validFrom is equal to validTo
          const baseDate = (validFrom === validTo) ? new Date() : new Date(validTo);
  
          // Handle the case where 'yearsNew' is empty or zero
          if (!yearsNew || yearsNew === '0') {
              if (newDays >= aMonth) {
                  // Valid extension period
                  baseDate.setDate(baseDate.getDate() + newDays);
                  const newDateStr = baseDate.toISOString().substring(0, 10);
                  setNewValidTo(newDateStr);
                  setKeys(0);
  
                  // Calculate difference between today and newDateStr
                  const diffDays = Math.floor((baseDate - new Date()) / (1000 * 60 * 60 * 24));
                  y = Math.floor(diffDays / aYear);
                  remainY = diffDays % aYear;
                  m = Math.floor(remainY / aMonth);
                  d = remainY % aMonth;
  
                  setExtendMsg(
                      'Key will expire in ' + y + ' year/s, ' + m + ' month/s and ' + d + ' day/s.'
                  );
                  setExErr(false);
              } 
          } else {
              const yearDays = parseInt(yearsNew, 10) * aYear; // Ensure yearsNew is a number
              baseDate.setDate(baseDate.getDate() + newDays + yearDays);
              const newDateStr = baseDate.toISOString().substring(0, 10);
              setNewValidTo(newDateStr);
              setKeys(totalUserCount * parseInt(yearsNew, 10)); // Ensure yearsNew is a number
  
              // Calculate difference between today and newDateStr
              const diffDays = Math.floor((baseDate - new Date()) / (1000 * 60 * 60 * 24));
              y = Math.floor(diffDays / aYear);
              remainY = diffDays % aYear;
              m = Math.floor(remainY / aMonth);
              d = remainY % aMonth;
  
              // Set expiration message with recalculated values
              setExtendMsg(
                  'Key will expire in ' + y + ' year/s, ' + m + ' month/s and ' + d + ' day/s.'
              );
              setExErr(false);
          }
      } else {
          // Handle when user count or total user count is invalid
          y = Math.floor(differenceInDays / aYear);
          remainY = differenceInDays % aYear;
          m = Math.floor(remainY / aMonth);
          d = remainY % aMonth;
          y += yearsNewInt;
  
          if (yearsNew) {
              const yearDays = parseInt(yearsNew, 10) * aYear; // Ensure yearsNew is a number
              const baseDate = new Date(); // Use current date as base for extension
              baseDate.setDate(baseDate.getDate() + differenceInDays + yearDays);
              const newDateStr = baseDate.toISOString().substring(0, 10);
              setNewValidTo(newDateStr);
              setKeys(totalUserCount * parseInt(yearsNew, 10)); // Ensure yearsNew is a number
  
              // Calculate difference between today and newDateStr
              const diffDays = Math.floor((baseDate - new Date()) / (1000 * 60 * 60 * 24));
              y = Math.floor(diffDays / aYear);
              remainY = diffDays % aYear;
              m = Math.floor(remainY / aMonth);
              d = remainY % aMonth;
  
              // Set expiration message with recalculated values
              setExtendMsg(
                  'Key will expire in ' + y + ' year/s, ' + m + ' month/s and ' + d + ' day/s.'
              );
              setExErr(false);
          } else {
              setExtendMsg('');
          }
      }
  }
  
    else if (keyType === 'Temporary') {

      if (uCountNew !== '') {
        setExErr(false)
        newDays = Math.round(differenceInDays * userCount / totalUserCount)
        y = Math.floor(newDays / aYear)
        remainY = newDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt

        if (daysNew === '' || daysNewInt === 0 ) {
          if (newDays >= 5) {

            currentDate.setDate(currentDate.getDate() + newDays);
            const newDateStr = currentDate.toISOString().substring(0, 10);
            setNewValidTo(newDateStr);
            if (!isExpiredDate) {
              setExtendMsg('Key will expire in ' + d + ' days.')
              setKeys(0)
            } else {
              if (saveDisable && validMsg) {
                setExtendMsg('Key will expire in ' + Math.abs(d) + ' days.');
                setExErr(false)
              } else {

                setExtendMsg('')
                setExErr(false)
              }

            }
          } else {
            setExtendMsg('You cannot add more users. Please extend by 5 days or more as the license will expire in less than 5 days (in ' + d + ' days)')
            setExErr(true)
            setKeys(0)
          }

          
          //setExtendMsg('Key wil expire in ' + differenceInDays + ' days.')
        } else {

          let expires = parseInt(daysNew) + parseInt(newDays);

          const newDaysInt = parseInt(daysNew);
          currentDate.setDate(currentDate.getDate() + newDays + newDaysInt);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);
          setKeys(totalUserCount * daysNew)
          if (!isExpiredDate) {
            setExtendMsg('Key will expire in ' + expires + ' days.')
            setExErr(false)
          } else {
            if (saveDisable && validMsg && isExpiredDate) {
              setExtendMsg('Key will expire in ' + Math.abs(d) + ' days.');
              setExErr(false)
            } else {

              setExtendMsg('')
              setExErr(false)
            }
          }

        }

      } else {
        y = Math.floor(differenceInDays / aYear)
        remainY = differenceInDays % aYear
        m = Math.floor(remainY / aMonth)
        d = remainY % aMonth
        y = y + yearsNewInt
        if (daysNew !== '' && daysNew !== '0') {
          const newDaysInt = parseInt(daysNew);
          currentDate.setDate(currentDate.getDate() + newDaysInt + differenceInDays);
          const newDateStr = currentDate.toISOString().substring(0, 10);
          setNewValidTo(newDateStr);


          setKeys(totalUserCount * daysNew)
          let expires = parseInt(daysNew) + parseInt(differenceInDays);
          if (!isExpiredDate) {
            setExtendMsg('Key will expire in ' + expires + ' days.')
            setExErr(false)
          } else {
            if (saveDisable && validMsg && isExpiredDate) {
              setExtendMsg('Key will expire in ' + Math.abs(d) + ' days.');
              setExErr(false)
            } else {

              setExtendMsg('')
              setExErr(false)
            }
          }
        } else {
          setExtendMsg('')
          setKeys(0)
        }


      }
      if (validTo !== '' && daysNew !== '') {
        //get new days by adding new days to valid to date
        const dateObj = new Date(validTo);
        const newDaysInt = parseInt(daysNew);
        dateObj.setDate(dateObj.getDate() + newDaysInt);

      }

    }
    else {
      setKeys(0)
      setDaysNew('')
      setYearsNew('')



    }

    //console.log("NewDays ", newDays);
  }, [validTo, validFrom, yearsNew, daysNew, uCountNew, months, days, isExpiredDate, totalDaysCount, totalYearsCount, totYearCount, totDaysMax, totYearsMax, maximumDays, maximumYears, perpetualKey,
    userCount, keyType, monthAll, aMonth, considerAMonth, totalUserCount, currentDateOnly, formattedCurrentDate, isExpiredMax, isExpiredDateMax, isMaxNotUserTime])
  //remove daysNew
  //console.log("valid Date ", newValidTo);

  // console.log("ValidFrom ", validFrom);  



  // Function to format date as YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };




  useEffect(() => {
    // Check if key is expired

    if (keyType === 'Temporary' && isExpiredDate) {


      const currentDate = new Date();
      const addedDays = parseInt(daysNew, 10);

      if (!isNaN(addedDays)) {
        const extendedDate = new Date(currentDate);
        extendedDate.setDate(currentDate.getDate() + addedDays);
        const formattedExtendedDateDays = formatDate(extendedDate);
        setNewValidTo(formattedExtendedDateDays);
        setValidFrom(formatDate(currentDate));
        // console.log("Current Date: ", formatDate(currentDate));
        // console.log("Formatted Current Date: ", formattedCurrentDate);
        //console.log("Valid Date: ", formattedExtendedDateDays);
        //console.log("Valid From: ", formattedCurrentDate);
        // console.log("User Count: ", userCount);
        // console.log("Added Days: ", addedDays);

        //setValidFrom(validFrom);



        //setNewValidTo(formattedExtendedDateDays);
        let expiresDay = new Date(formattedExtendedDateDays);
        expiresDay.setDate(expiresDay.getDate() + addedDays);

        // If extend is clicked and save is not disabled, update validFrom




        setUCountNew('');
        setDateUpdated(true);


        setExtendMsg('Key will expire in ' + addedDays + ' days.');

      }

    }

    if (keyType === 'Permanent' && isExpiredDate) {


      const aDay = 24 * 60 * 60 * 1000;

      function calculateRemainingTime(startDate, endDate) {
        const differenceInDays = Math.floor((endDate - startDate) / aDay);
        let y = Math.floor(differenceInDays / 365);
        let remainY = differenceInDays % 365;
        let m = Math.floor(remainY / 30);
        let d = remainY % 30;


        return { years: y, months: m, days: d };
      }
      // Temporary key is expired logic
      const currentDate = new Date();



      // Calculate "Valid To" by extending from today based on selected days
      let yearDays = yearsNew * aYear;
      const extendedDate = new Date(currentDate);
      extendedDate.setDate(currentDate.getDate() + parseInt(yearDays));  // Assuming daysNew is the selected number of days
      const formattedExtendedDate = formatDate(extendedDate);  // Use the formatDate function

      // Log current dates
      //console.log("Current Date: ", formatDate(currentDate));
      //console.log("Formatted Current Date: ", formattedCurrentDate);

      // Update state with the calculated values
      setValidFrom(validFrom);  // Set "Valid From" to the current date
      if (extendClicked) {

        setNewValidTo(formattedExtendedDate);
        setValidFrom(formatDate(currentDate));
      }
      setUCountNew('');


      const remainingTime = calculateRemainingTime(currentDate, extendedDate);

      setExtendMsg(`Key will expire in ${remainingTime.years} years, ${remainingTime.months} months, and ${remainingTime.days} days.`);
      // Log results
      //console.log("Valid Date: ", formattedExtendedDate);
      // console.log("Valid From: ", formattedCurrentDate);
      // console.log("User Count: ", userCount);
      // console.log("Added Days: ", extendedDate.getDate() - currentDate.getDate());

      setDateUpdated(true);
    }

    if (keyType === 'Perpetual' && isExpiredDate) {


      const aDay = 24 * 60 * 60 * 1000;

      function calculateRemainingTime(startDate, endDate) {
        const differenceInDays = Math.floor((endDate - startDate) / aDay);
        let y = Math.floor(differenceInDays / 365);
        let remainY = differenceInDays % 365;
        let m = Math.floor(remainY / 30);
        let d = remainY % 30;


        return { years: y, months: m, days: d };
      }
      // Temporary key is expired logic
      const currentDate = new Date();



      // Calculate "Valid To" by extending from today based on selected days
      let yearDays = yearsNew * aYear;
      const extendedDate = new Date(currentDate);
      extendedDate.setDate(currentDate.getDate() + parseInt(yearDays));  // Assuming daysNew is the selected number of days
      const formattedExtendedDate = formatDate(extendedDate);  // Use the formatDate function

      // Log current dates
      //console.log("Current Date: ", formatDate(currentDate));
      //console.log("Formatted Current Date: ", formattedCurrentDate);

      // Update state with the calculated values
      setValidFrom(validFrom);  // Set "Valid From" to the current date
      if (extendClicked) {

        setNewValidTo(formattedExtendedDate);
        setValidFrom(formatDate(currentDate));
      }
      setUCountNew('');


      const remainingTime = calculateRemainingTime(currentDate, extendedDate);
if(yearsNew !== '' && yearsNew !== '0'){
 setExtendMsg(`Key will expire in ${remainingTime.years} years, ${remainingTime.months} months, and ${remainingTime.days} days.`);
    setExErr(false)
     }
      // Log results
      //console.log("Valid Date: ", formattedExtendedDate);
      // console.log("Valid From: ", formattedCurrentDate);
      // console.log("User Count: ", userCount);
      // console.log("Added Days: ", extendedDate.getDate() - currentDate.getDate());

      setDateUpdated(true);
    }

  }, [keyType, validTo,  isExpiredDate, saveDisable,  validFrom, formattedCurrentDate, daysNew, yearsNew, userCount]);


  useEffect(() => {
    // Action to be performed after newValidTo is updated
    // You can use newValidTo here
  }, [newValidTo, validFrom, totalDays, totalYears]);



  useEffect(() => {

    setUuidInDatabase('');
    setSearchCliked('');

  }, [sentRequest, uuidInDatabase, validUuid]);



  //get total days to display
  useEffect(() => {
    if (daysNew !== '') {
      let daysNewInt = parseInt(daysNew)
      setTotalDays(days + daysNewInt)
    } else {
      setTotalDays(days)
    }
  }, [days, daysNew])

  //handle disabling save button
  useEffect(() => {
    if (keyType === 'Permanent' || keyType === 'Perpetual') {
      if (yearsNew === '' && uCountNew === '' && !isLessDate) {
        setSaveDisable(true);
      } else if (totalUserCount === userCount && totalYears === prevYears) {
        setSaveDisable(true);
      } else if (userCount === maxUserCount && yearsNew === '') {
        setSaveDisable(true);
      } else {
        setSaveDisable(false);
      }

    } else {
      if (daysNew === '' && uCountNew === '' && !isLessDate) {
        setSaveDisable(true);
      } else if (totalUserCount === userCount && totalDays === days) {
        setSaveDisable(true);
      } else if (userCount === maxUserCount && daysNew === '') {
        setSaveDisable(true);
      } else {
        setSaveDisable(false);
      }
    }
  }, [yearsNew, uCountNew, keyType, daysNew, totalUserCount, userCount, totalYearsCount, totalDaysCount, totalDays, days, keyValidate, maxUserCount]);

  //get Total user count to display
  useEffect(() => {
    if (uCountNew !== '') {
      let uCountInt = parseInt(uCountNew)
      setTotalUserCount(userCount + uCountInt)
    } else {
      setTotalUserCount(userCount)
    }
  }, [userCount, uCountNew])

  //set available and freeUsed from key wallet
  // freeUsed = temporary generated
  useEffect(() => {
    setAvailable(keyWallet.available);
    setFreeAvailable(keyWallet.freeAvailable);
    setPerpetualAvailable(keyWallet.perpetualAvailable);
  }, [keyWallet])

  //check whether months or days null to enable button
  useEffect(() => {
    if (keyType === 'Permanent' || keyType === 'Perpetual') {
      if (selectedYears === '') {
        setNullYears(true)
        setNullDays(false)
      } else {
        setNullYears(false)
        setNullDays(false)
      }
    } else if (keyType === 'Temporary') {
      if (days === '') {
        setNullDays(true)
        setNullYears(false)
      } else {
        setNullYears(false)
        setNullDays(false)
      }
    }
  }, [keyType, selectedYears, days])//remove months

  //validate essential keys on keys change
  useEffect(() => {
    if (keyType === 'Permanent' ) {
      if (keys > available) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    } else if ( keyType === 'Perpetual') {
      if (keys > perpetualAvailable) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    }
    else if (keyType === 'Temporary') {
      if (keys > freeAvailable) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    }
  }, [keys, available, freeAvailable, perpetualAvailable, keyType, keyValidate])

  useEffect(() => {
    //when there are no available free key gives an alert
    if (available === 0 && freeAvailable === 0 && status === 0) {
      setOpenDialog(true);
      setTitle('No keys to generate!');/* 
      setMsg('You have no keys or free keys available. Do you want to buy more keys?'); */
      setMsg('You have no keys nor free keys available.');
    } else if (available === 0 && freeAvailable > 0) {
      setIsChecked(true)
    } else if (available > 0 && freeAvailable === 0) {
      setIsChecked(false)
    }
  }, [available, freeAvailable, status])

  const onYes = () => {
    setOpenDialog(false);
    navigate('/dashboard')
  }

  //check whether customer is empty for validation
  const validatecustomer = () => {
    if (noCustomer) {
      return true
    } else {
      return false
    }
  }


  useEffect(() => {
    //clear inputs when selected customer change  
    if (customerId === 0) {
      setKeyType('');
      setDays('')
      setUserCount('')
      setSelectedYears('')
      setSelectedDays('')
      setDescription('')
    }
  }, [customerId])

  useEffect(() => {
    if (uCountNew !== '') {
      setUsersNewInt(parseInt(uCountNew))
    } else {
      setUnlimited(false)
    }
  }, [uCountNew])

  useEffect(() => {
    //open unlimited popup if user count is 50 or more  
    const totUserCount = uCountNew + userCount;
    if (totUserCount === maxUserCount) {
      if (usersNewInt !== 0) { //

        setOpenUnlimited(true);
        setUCountNew(maxUserCount - userCount);
        setUnlimited(true)
      }
    } else if (totalUserCount >= maxUserCount) {
      setOpenUnlimited(false);
      setUCountNew(maxUserCount - userCount);
      setUnlimited(true)
    } else if (usersNewInt < (maxUserCount - 1)) {
      setUnlimited(false)
    }
    // prevent open pop up if userCount is already unlimited

  }, [userCount, maxUserCount, totalUserCount, usersNewInt])

  useEffect(() => {
    //if unlimited popup is opened and turn off unlimited clear user count field 
    if (openUnlimited && !unlimited) {
      setUCountNew('5')
    } else if (unlimited) {
      setUCountNew(maxUserCount - userCount);
    }
  }, [unlimited, openUnlimited, userCount, maxUserCount])

  useEffect(() => {
    if (prevUnlimited) {
      setUnlimited(true)
    }
  }, [prevUnlimited])

  useEffect(() => {
    if (validUuid) {
      setValidUuid(UUID_REGEX.test(uuid));
    }
  }, [validUuid])

 /*  const uuidSearch = async () => {
    try {

      setSearchCliked(true)

      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      const data = response.data
      const result = data.result
      setKeyGenId(result.keyGenId)


      //console.log("Response ", result.keyGenId)
      if (result && result.isGenerated === false) {
        setExtendClicked(true)
        setAssign(true)

      }
      setUuidStatus(data.status);
      setUuidChecked(true);
      setUuidDisabled(true);
      if (result !== null) { //data.status
        //console.log('UUID found in the database');
        setUsedUuid(true)
        setUuidInDatabase(response.data.status);
        setCustomerName(result.customerName)
        setCustomerId(result.cuId)
        if (result.keyType === 'PERMANENT') {
          setKeyType('Permanent')
          setSelectedDays(result.days)
          setValidFromLabel('Valid From');
          setValidToLabel('Valid To');
        } else if (result.keyType === 'PERPETUAL') {
          setKeyType('Perpetual')
          setSelectedDays(result.days)
          setValidFromLabel('Subscription Valid From');
          setValidToLabel('Subscription Valid To');
        }
        else {
          setKeyType('Temporary')
          setDays(result.days)
          setValidFromLabel('Valid From');
          setValidToLabel('Valid To');
        }
        setUserCount(result.userCount)
        if (result.userCount >= maxUserCount) {
          setPrevUnlimited(true)
        } else {
          setPrevUnlimited(false)
        }
        if (result.description) {
          setDescription(result.description)
        }
        setValidFrom(result.validFrom)
        setValidTo(result.validTo)
        setUuidDisabled(true);
      } else {
        //console.log('UUID not found in the database');
        setUsedUuid(false)

        setUuidInDatabase(false);
        //setUuidFocus()
        setUuidDisabled(false);

      }
 */
     /*  const uuidSearch = async () => {
        try {
          setSearchCliked(true);
      
          // Make the API request
          const response = await axios.get(`${UUID_SEARCH}${uuid}`);
          const data = response.data;
          const result = data.result;
          console.log('Result', result);
      
          // Handle the case where `isGenerated` is false
          if (result && result.isGenerated === false) {
            enqueueSnackbar('Invalid UUID!', { variant: 'error' });
            setSearchCliked(false);
            return; // Exit early if UUID is invalid
          }
      
          // Continue processing if `isGenerated` is true
          setKeyGenId(result.keyGenId);
          setUuidStatus(data.status);
          setUuidChecked(true);
          setUuidDisabled(true);
      
          if (result !== null) {
            setUsedUuid(true);
            setUuidInDatabase(response.data.status);
            setCustomerName(result.customerName);
            setCustomerId(result.cuId);
      
            if (result.keyType === 'PERMANENT') {
              setKeyType('Permanent');
              setSelectedDays(result.days);
              setValidFromLabel('Valid From');
              setValidToLabel('Valid To');
            } else if (result.keyType === 'PERPETUAL') {
              setKeyType('Perpetual');
              setSelectedDays(result.days);
              setValidFromLabel('Subscription Valid From');
              setValidToLabel('Subscription Valid To');
            } else {
              setKeyType('Temporary');
              setDays(result.days);
              setValidFromLabel('Valid From');
              setValidToLabel('Valid To');
            }
      
            setUserCount(result.userCount);
            setPrevUnlimited(result.userCount >= maxUserCount);
            if (result.description) {
              setDescription(result.description);
            }
            setValidFrom(result.validFrom);
            setValidTo(result.validTo);
          } else {
            setUsedUuid(false);
            setUuidInDatabase(false);
            setUuidDisabled(false);
          }

      //enqueueSnackbar('Valid UUID!', { variant: 'success'})

    } catch (err) {
      setSearchCliked(false)
      variant = 'error'
     /*  if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else ---
      
      
      if (err.response !== 403) {
        enqueueSnackbar('Invalid UUID!', { variant })
      } else {

        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })

      }
    }
  } */

  const uuidSearch = async () => {
    try {
      setSearchCliked(true);
  
      // Make the API request to get UUID details
      const response = await axios.get(`${UUID_SEARCH}${uuid}`);
      const data = response.data;
      const result = data.result;
  
      // Handle the case where `isGenerated` is false
      if (result && result.isGenerated === false) {
        enqueueSnackbar('Invalid UUID!', { variant: 'error' });
        setSearchCliked(false);
        return; // Exit early if UUID is invalid
      }
  
      // Fetch the current user's seller ID
      const currentUserSellerResponse = await axios.get(`${FIND_SELLER_ID}`);
      const currentUserSellerId = currentUserSellerResponse.data.result.seller.id;
  
      // Check if the UUID's seller matches the current user's seller
      if (result.sellerId === currentUserSellerId) {
        // UUID belongs to the current user's seller, proceed with loading data
        loadDataToForm(result);
        return;
      }
  
      // Fetch the list of sellers under the current user
      const sellerResponse = await axios.get(`${FIND_SELLERS}/2`);
      const sellers = sellerResponse.data.result;
  
      // Check if the sellerId from the UUID response is in the list of under-members
      const isUnderMember = sellers.some(seller => seller.id === result.sellerId);
      if (!isUnderMember) {
        enqueueSnackbar('This key belongs to a seller outside your network!', { variant: 'error' });
        setSearchCliked(false);
        return; // Exit early if the seller is not an under-member
      }
  
      // Continue processing if `isGenerated` is true and the seller is an under-member
      loadDataToForm(result);
  
    } catch (err) {
      setSearchCliked(false);
      let variant = 'error';
  
      if (err.response) {
        if (err.response.status === 403) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
        } else {
          enqueueSnackbar(err.response.data.message || 'Invalid UUID!', { variant });
        }
      } else {
        enqueueSnackbar('Data not found.', { variant });
      }
    }
  };
  
  // Helper function to load data into the form
  const loadDataToForm = (result) => {
    setKeyGenId(result.keyGenId);
    setUuidStatus(result.status);
    setUuidChecked(true);
    setUuidDisabled(true);
  
    if (result) {
      setUsedUuid(true);
      setUuidInDatabase(true);
      setCustomerName(result.customerName);
      setCustomerId(result.cuId);
  
      if (result.keyType === 'PERMANENT') {
        setKeyType('Permanent');
        setSelectedDays(result.days);
        setValidFromLabel('Valid From');
        setValidToLabel('Valid To');
      } else if (result.keyType === 'PERPETUAL') {
        setKeyType('Perpetual');
        setSelectedDays(result.days);
        setValidFromLabel('Subscription Valid From');
        setValidToLabel('Subscription Valid To');
      } else {
        setKeyType('Temporary');
        setDays(result.days);
        setValidFromLabel('Valid From');
        setValidToLabel('Valid To');
      }
  
      setUserCount(result.userCount);
      setPrevUnlimited(result.userCount >= maxUserCount);
      if (result.description) {
        setDescription(result.description);
      }
      setValidFrom(result.validFrom);
      setValidTo(result.validTo);
      setIsDataLoaded(true);
    } else {
      setUsedUuid(false);
      setUuidInDatabase(false);
      setUuidDisabled(false);
      setIsDataLoaded(false);
    }
  };
  
  



  const fetchExtendedStatus = async () => {
    try {
      if (!keyGenId) {
        console.error('keyGenId is undefined');
        return;
      }

      const response = await axios.get(CHECK_KEY_EXTEND + `${keyGenId}/1`);
      const { result } = response.data;

      // Your logic to handle the response
      if (result && result.historyList !== undefined) {
        let totalTemporaryDays = 0;
        let totalPermanentDays = 0;
        let totalPerpetualDays = 0;
        let daysArray = [];

        // Loop through each item in historyList
        result.historyList.forEach(historyItem => {
          // Calculate the difference between fromDate and toDate
          const fromDate = new Date(historyItem.fromDate);
          const toDate = new Date(historyItem.toDate);
          const differenceInTime = toDate.getTime() - fromDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          //console.log(`Difference for history item ${historyItem.id}: ${differenceInDays} days`);

          // Add the difference to the appropriate total based on keyGen type
          if (historyItem.keyGen.type === 'TEMPORARY') {
            totalTemporaryDays += differenceInDays;
          } else if (historyItem.keyGen.type === 'PERMANENT') {
            totalPermanentDays += differenceInDays;
          }else if (historyItem.keyGen.type === 'PERPETUAL') {
            totalPerpetualDays += differenceInDays;
          }
          daysArray.push(differenceInDays); // Push difference to the array
        });

        // Update state or perform any other action with the totals
        //console.log("Total temporary days:", totalTemporaryDays);
        //console.log("Total permanent days:", totalPermanentDays);

        //console.log("Days of each record:", daysArray);

        // Set the temporary day count
        setTotalDaysCount(totalTemporaryDays);
        // Set the permanent day count
        setTotalYearsCount(totalPermanentDays);
        setTotalYearsPerpetualCount(totalPerpetualDays);
      } else {
        console.warn('Invalid response format:', response.data);
      }
    } catch (error) {
      console.error('Error occurred while fetching extended status:', error);
      // Handle error (e.g., display a message to the user)
    }
  };




  useEffect(() => {
    //console.log("totalDaysCount:", totalDaysCount);
    //console.log("maxDays:", maxDays);

    //console.log("totalDaysCount > maxDays:", totalDaysCount > maxDays);
  }, [totalDaysCount]);




  useEffect(() => {
    if (uuidEdit !== '') {
      setUuid(uuidEdit);
      if (uuid !== '') {
        uuidSearch()

        setUuidEdit('')


      }
    }
  }, [uuidEdit, uuid])




  //console.log("Key ", assign)
  //handle submit method
  const handleSubmit = async (uuid, customerName) => {
    //e.preventDefault();
    setSendRequest(true)
    //setsubmitSuccess(true)
    setUuidEdit(uuid)

    //console.log("Valid To Date :", newValidTo)
    //check whether uuid is used
    try {
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      const data = response.data


      //if uuid is already generated Extend
      if (data.status) {
        setUsedUuid(true)
        let yearsInt
        let daysInt
        let userCountInt
        if (yearsNew !== '') {
          yearsInt = parseInt(yearsNew)
        } else {
          yearsInt = 0
        }
        if (daysNew !== '') {
          daysInt = parseInt(daysNew)
        } else {
          daysInt = 0
        }
        if (uCountNew !== '') {
          userCountInt = parseInt(uCountNew)
        } else {
          userCountInt = 0
        }
        let keyTypeUpper = keyType.toUpperCase()

        const getNewKey = {
          cuId: customerId,
          uuid,
          description,
          keyType: keyTypeUpper,
          userCount: userCountInt,
          userId,
          isFree: isChecked,

        };

        if (keyType === 'Permanent' ) {
          getNewKey.days = equivalentDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        } else if (keyType === 'Perpetual' ) {
          getNewKey.days = equivalentDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        }else if (keyType === 'Temporary') {
          getNewKey.days = daysInt; // Use daysInt for temporary keys
          //getNewKey.months = 0; // Set months to 0 for temporary keys
        }
        try {
          const response = await axios.post(KEY_EXTEND, getNewKey);
          const data = response.data
          if (data.status) {
            variant = 'success'
            enqueueSnackbar('Key Extend successful!', { variant })
            setsubmitSuccess(true);

            setUuidEdit(uuid)
            //console.log(uuid)
            setCustomerName(customerName);


          } else {
            variant = 'success'
            enqueueSnackbar('Key Extend Fail!', { variant })
          }
          setTotalUserCount(0)
          setCustomerName('')
          setCustomerId(0)
          setCustomerFocus(false)
          setKeyType('')
          setDays('')
          setUserCount('')
          setSelectedYears('')
          setSelectedDays('')
          setUuid('')
          //setUuidEdit('')
          setGenerateUuid('')
          setDescription('')
          setKeys(0)
          setUuidFocus(false);
          keyWalletFetch();
          setUCountNew('')
          setYearsNew('')
          setDaysNew('')
          setUnlimited(false)
          setPrevUnlimited(false)
          setExtendMsg('')
          setValidFrom('')
          setValidTo('')
          setNewValidTo('')
          setUsersNewInt(0)
          setUuidChecked(false)
          //setSaveDisable(false)

        } catch (err) {
          variant = 'error'
          if (!err.response) {
            enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
          } else if (err.response !== 403) {
            enqueueSnackbar('Key Extend Fail!', { variant })
            setsubmitSuccess(false)
          }
        }
        //if there is no key for uuid return
      } else {
        setUsedUuid(false)
        setTotalUserCount(0)
        setCustomerName('')
        setCustomerId(0)
        setCustomerFocus(false)
        setKeyType('')
        setDays('')
        setUserCount('')
        setSelectedYears('')
        setSelectedDays('')
        setGenerateUuid('')
        setDescription('')
        setKeys(0)
        setUuidFocus(false);
        keyWalletFetch();
        setUCountNew('')
        setYearsNew('')
        setDaysNew('')
        setUnlimited(false)
        setPrevUnlimited(false)
        setExtendMsg('')
        setValidFrom('')
        setValidTo('')
        setNewValidTo('')
        setUsersNewInt(0)
        setUuidChecked(false)
        setUuidEdit('')
        return;
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar('UUID Check Failed!', { variant })
        setsubmitSuccess(false)
      }
    }
  }





  const handleKeyDown = (event) => {
    // check if the key pressed is not a number or a control key
    if (!((event.keyCode >= 48 && event.keyCode <= 57)
      || (event.keyCode >= 96 && event.keyCode <= 105)
      || event.keyCode === 8 || event.keyCode === 9
      || event.keyCode === 37 || event.keyCode === 39
      || (event.ctrlKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Ctrl+C and Ctrl+V
      || (event.metaKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Cmd+C and Cmd+V on macOS
    )) {
      event.preventDefault();
    }
  };



  useEffect(() => {
    if (yearsNew !== '0' || yearsNew !== '') {
      // Calculate equivalent days based on selected years (1 year = 372 days)
      const days = parseInt(yearsNew) * 372;
      setEquivalentDays(days);
    }
  }, [yearsNew]);

  const handleViewLicense = (uuid) => {
    //console.log("submitSuccess: ", submitSuccess); // Log the value
    if (submitSuccess) {
      setUuidEdit(uuid);

      setCustomerName(customerName);
      setOpenKeyViewPopup(true);
      //console.log("uuid ", uuid);
      setPressedView(true);

    }
  };

  const uuidClear = () => {
    //clear uuid  
    setTotalUserCount(0)
    setCustomerName('')
    setCustomerId(0)
    setCustomerFocus(false)
    setKeyType('')
    setDays('')
    setUserCount('')
    setSelectedYears('')
    setSelectedDays('')
    setUuid('')
    setUuidEdit('')
    setGenerateUuid('')
    setDescription('')
    setKeys(0)
    setUuidFocus(false);
    keyWalletFetch();
    setUCountNew('')
    setYearsNew('')
    setDaysNew('');
    setUnlimited(false)
    setPrevUnlimited(false)
    setExtendMsg('')
    setValidFrom('')
    setValidTo('')
    setNewValidTo('')
    setUsersNewInt(0)
    setUuidChecked(false)
    //setUuidFocus(true)
    //setUsedUuid(false);
    //setValidUuid(false);
    setsubmitSuccess(false)
    setKeyValidate(false)
    setValidMsg('')
    setExtendClicked(false)
    setSendRequest(false)
    setSaveDisable(false)
    setUuidChecked(false)
    setUuidDisabled(false)
    setUuidFocus(false); // Reset UUID focus
    setValidUuid(true); // Reset UUID validity status
    setUsedUuid(true); // Reset UUID usage status
    setUuidInDatabase(true);
    setExtendClicked(false);
    setIsDataLoaded(false);
  }

  const handleEnable = () => {
    //disable months and userCount
    setExtendClicked(true);
  }
  //console.log("extend ", extendClicked)
  const handleUnlimited = (unlimitedVal) => {
    if (unlimitedVal) { //if not unlimited 
      setOpenUnlimited(false);
      setUnlimited(false);
      setIsChecked(false);
      setUCountNew('')

      //setUserCount(0); // Set uCountId to 5 when unlimited is unchecked
    } else {// in unlimited
      setOpenUnlimited(true);
      setUnlimited(true);
      setIsChecked(true);
    }
  };

  return (
    <MDBContainer fluid className='container-style small-width'>
      <MDBRow className='d-flex justify-content-center align-items-center h-auto'>
        <MDBCol xs={12}>
          <MDBCol col='12'>

            <SnackbarAlerts
              open={open}
              setOpen={setOpen}
              msg={message}
              severity={severity}
            />
            <UnlimitedPopUp
              open={openUnlimited}
              setOpen={setOpenUnlimited}
              unlimited={unlimited}
              setUnlimited={setUnlimited}
              userCount={userCount}
              setUserCount={setUserCount}
              setYears={setYears}
              years={years}
              keyType={keyType}
            />
            <MDBCard className='card-offline mt-3'>
              <MDBCardBody className='p-0'>

                <MDBRow>
                  <MDBCol md='6' className='bg-blue p-3 p-md-5'>
                    <p className='user-instructions p-1'> To begin the key extending process enter the
                      UUID and check the status of UUID.</p>
                    <Form>
                      <MDBRow>
                        <>
                          <MDBCol md='12' className='mb-1 mu-1'>
                            <Form.Group className='mb-3 me-0 pe-0' >
                              <Form.Label className='white-lable me-0 pe-0' >UUID</Form.Label>
                              <span className="mandatory"> *</span>
                              <div style={{ display: 'flex' }}>
                                <Form.Control
                                  type='text'
                                  id='vatCodeid'
                                  onChange={(e) => setUuid(e.target.value)}
                                  required
                                  value={uuid}
                                  onFocus={() => setUuidFocus(true)}
                                  onBlur={validateUuid}
                                  //disabled={validUuid}
                                  disabled={uuidDisabled}

                                />

                                <Button
                                  className='uuid-search ms-2'
                                  variant='light'
                                  onClick={uuidSearch}
                                  disabled={extendClicked || isEmpty(uuid)} >
                                  check
                                </Button>

                              </div>
                              {/* <span className={!usedUuid && !isEmpty(uuid) ? 'validate' : 'hide'}>There is no key for this UUID.</span> --have to do modifications */}
                              {/*   <p className={uuidFocus && !validUuid && !isEmpty(uuid) ? 'validate' : 'hide'}>Invalid UUID</p>   */}
                              {/*  { !validUuid && !isEmpty(uuid) && (
        <p className="validate">Invalid UUID</p>
    )}
    {  !uuidInDatabase && !usedUuid && (
        <p className="validate">UUID not found in the database</p>
    )} */}

                              {!validUuid && !isEmpty(uuid) && !uuidInDatabase && !usedUuid && (
                                <p className="validate">Invalid UUID</p>
                              )}
                              {!uuidInDatabase && !usedUuid && validUuid && (
                                <p className="validate">UUID not found in the database</p>
                              )}

                              <p className={!isEmpty(uuid) && !validUuid && !extendClicked ? 'validate' : 'hide'}>This UUID is an Invalid UUID!</p>
                            </Form.Group>
                          </MDBCol>
                        </>
                      </MDBRow>
                      <fieldset
                        disabled={extend}
                        className={'fieldset-invisible'} >
                        <MDBRow>
                          <MDBCol md='6' className='cust-column'>
                            <>
                              <Form.Group className='mb-3' >
                                <Form.Label className='white-lable' size='sm'>Customers</Form.Label>
                                <span className="mandatory"> *</span>
                                <Form.Control
                                  id='customerID'
                                  value={customerName}
                                  onChange={(e) => setCustomerName(e.target.value)}
                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                          <MDBCol md='6' className='custom-column'>
                            <>
                              <Form.Group className='mb-3' >
                                <Form.Label className='white-lable' size='sm'>Key Type</Form.Label>
                                <span className="mandatory"> *</span>
                                <Form.Select
                                  id='keyTypeId'
                                  onChange={(e) => setKeyType(e.target.value)}
                                  value={keyType} >
                                  <option disabled value=''>Key type...</option>
                                  <option value="Temporary">Temporary</option>
                                  <option value="Permanent">Permanent</option>
                                  <option value="Perpetual">Perpetual</option>
                                </Form.Select>
                              </Form.Group>
                            </>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md='6' className='cust-column'>
                            <>
                              <Form.Group className={keyType === '' ? 'hide-field' : 'mb-3'} >
                                <Form.Label className='white-lable me-0 pe-0' >User Count</Form.Label>
                                <span className="mandatory"> *</span>
                                <Form.Check
                                  inline
                                  label={<span className="white-label">Unlimited</span>}
                                  type="checkbox"
                                  id="unlimitedCheckbox"
                                  checked={prevUnlimited}
                                  onChange={() => handleUnlimited(unlimited)}
                                  className="mr-3" // Add margin to the right of the checkbox
                                />
                                <Form.Control
                                  type='number'
                                  onChange={(e) => setUserCount(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  min={1}
                                  max={maxUserCount}
                                  id='vatCodeid'
                                  required
                                  value={userCount}

                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                          <MDBCol md='6' className='custom-column'>
                            <>
                              <Form.Group className={keyType === 'Temporary' ? 'mb-3' : 'hide-field'}  >
                                <Form.Label className='white-lable' size='sm'>Days</Form.Label>
                                <span className="mandatory"> *</span>
                                <Form.Control
                                  id='daysId'
                                  onChange={(e) => setDays(e.target.value)}
                                  value={days} >
                                </Form.Control>
                              </Form.Group>
                            </>
                            <>
                              <Form.Group className={keyType === 'Permanent' || keyType === 'Perpetual' ? 'mb-3' : 'hide-field'} >
                                <Form.Label className='white-lable' size='sm'>Years</Form.Label>
                                <span className="mandatory"> *</span>
                                <Form.Control
                                  id='daysId1'
                                  onChange={(e) => setSelectedDays(e.target.value)}
                                  value={prevYears}
                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md='12' className='cust-column'>
                            <>
                              <Form.Group className='white-lable mb-3 me-0 pe-0'>
                                <Form.Label className='me-0 pe-0'>Description</Form.Label>
                                <Form.Control
                                  type='text'
                                  id='descriptionId'
                                  onChange={(e) => setDescription(e.target.value)}
                                  value={description}
                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow >
                          <MDBCol md='6' className='cust-column'>
                            <>
                              <Form.Group className='mb-3 me-0 pe-0'>
                              <Form.Label className='white-label'>{validFromLabel}</Form.Label>
                                <Form.Control
                                  type='date'
                                  id='newFromId'
                                  onChange={(e) => validFrom(e.target.value)}
                                  value={validFrom}
                                  disabled={extend}
                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                          <MDBCol md='6' className='custom-column'>
                            <>
                              <Form.Group className='mb-3 me-0 pe-0'>
                              <Form.Label className='white-label'>{validToLabel}</Form.Label>
                                <Form.Control
                                  type='date'
                                  id='newToId'
                                  onChange={(e) => setValidTo(e.target.value)}
                                  value={validTo}
                                  disabled={extend}
                                />
                              </Form.Group>
                            </>
                          </MDBCol>
                        </MDBRow>
                      </fieldset>
                      <MDBRow>
                        <MDBCol md='12' className='d-flex justify-content-end mb-3' >
                          <Button
                            onClick={uuidClear}
                            color='primary'
                            className='me-3' >
                            Cancel
                          </Button>

                          <Button
                            //disable the button when uuid is not used
                            disabled={extendClicked || !uuidChecked || isExpiredDateMax || isExpiredMax || !usedUuid || !validUuid || !isDataLoaded}//!uuidChecked || || isExpiredDateMax || isExpiredMax
                            color='primary'
                            onClick={handleEnable} >
                            Extend Key
                          </Button>



                        </MDBCol>

                      </MDBRow>
                      {/*  <p className={ !isEmpty(uuid) && !validUuid && !extendClicked ? 'validate' : 'hide'}>This UUID is an Invalid UUID!</p> */}
                    </Form>
                  </MDBCol>
                  <MDBCol md='6' className='bg-white p-3 p-md-5'>
                    <MDBRow >
                      <MDBCol md='12' className='mb-1 mt-3'>
                        <h6 className='card-text mb-3'>Available Permanent  &nbsp;: &nbsp;&nbsp; {available}</h6>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow >
                      <MDBCol md='12' className='mb-1'>
                        <h6 className='card-text mb-3'>Available Perpetual &nbsp;&nbsp; &nbsp;&nbsp;: &nbsp;&nbsp; {perpetualAvailable}</h6>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md='12' className='mb-1'>
                        <h6 className='card-text mb-3' style={{ alignItems: 'left' }}>Available Temporary &nbsp;: &nbsp;&nbsp; {freeAvailable}</h6>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md='12' className='mb-1'>
                        <h6 className={keyValidate && !isMaxTime ? 'validate-h6 mb-0' : 'card-text mb-3'}>Needed Keys &nbsp;&nbsp;: &nbsp;&nbsp; {!maxMsg && !isExpired ? keys : 0}</h6>
                        <span className={keyValidate && !isMaxTime ? 'validate' : 'hide'}>Needed keys exceeded available keys</span>
                      </MDBCol>
                    </MDBRow>



                    <fieldset className='fieldset-invisible m-0 p-0' disabled={!extendClicked}>
                      <MDBRow>
                        <MDBCol md='6'>
                          <Form.Group>
                            <Form.Label>User Count</Form.Label>
                            <Form.Check
                              inline
                              label={<span className="black-label">Unlimited</span>}
                              type="checkbox"
                              id="unlimitedCheckboxEdit"
                              checked={unlimited}
                              onChange={() => handleUnlimited(unlimited)}
                              disabled={userCount === 50 || submitSuccess || isExpiredDate || assign} // Disable when totalUserCount is 50

                            />
                            <Form.Control
                              type='number'
                              id='uCountId'
                              onChange={(e) => setUCountNew(e.target.value)}
                              value={isExpiredDate ? '' : uCountNew} //isExpiredDate ? '' :
                              min={0}
                              max={50}
                              onKeyDown={handleKeyDown}
                              //disabled={ !validUuid}
                              disabled={!validUuid || unlimited || submitSuccess || isExpiredDate || assign} // Disable when totalUserCount is 50 and unlimited is true
                              onPaste={(e) => e.preventDefault()}
                            />
                            {submitSuccess && (
                              <p className='mt-2'>Final User Count: {totalUserCount}</p>
                            )}
                          </Form.Group>
                        </MDBCol>
                        <MDBCol md='6' className='mb-1'>
                          <Form.Group className={keyType === 'Temporary' ? 'mb-3 me-0 pe-0' : 'hide-field'}  >
                            <Form.Label size='sm'>Days</Form.Label>
                            <Form.Select
                              id='daysId'
                              onChange={(e) => setDaysNew(e.target.value)}
                              value={daysNew}
                              disabled={submitSuccess || assign }>
                              <option disabled value=''>Days..</option>
                              {DaysOptions.map((options, index) => (
                                <option key={index} value={options.value}>{options.select}</option>
                              ))}
                            </Form.Select>
                            {submitSuccess && (
                              <p className='mt-2'>Final Days: {totalDays}</p>
                            )}
                          </Form.Group>
                          <Form.Group className={keyType === 'Permanent' || keyType === 'Perpetual' ? 'mb-3' : 'hide-field'}>
                            <Form.Label className='black-lable' size='sm'>Years</Form.Label>
                            <span className="mandatory"> *</span>
                            <Form.Select
                              id='daysId'
                              onChange={(e) => setYearsNew(e.target.value)}
                              value={yearsNew}
                              disabled={submitSuccess || assign}>

                              <option disabled value=''>Years..</option>
                              {YearsOptions.map((options, index) => (
                                <option key={index} value={options.value}>{options.select}</option>
                              ))}
                            </Form.Select>
                            {submitSuccess && (
                              <p className='mt-2'>
                                Final Years : {totalYears}
                              </p>
                            )}
                          </Form.Group>
                          <p className={isMaxTime && !isMaxNotUserTime ? 'validate' : 'hide'}>{maxMsg}</p>
                          <p className={totDaysMax || totYearsMax  ? 'validate' : 'hide'}>{exMaxMsg}</p>
                          <p className={isMaxNotUserTime  ? 'validate' : 'hide'}>{exMaxMsg}</p>
                        </MDBCol>
                      </MDBRow>
                    </fieldset>
                    <MDBRow >
                      <MDBCol md='6'>
                        <Form.Group className='black-lable mb-3 me-0 pe-0'>
                        <Form.Label className='me-0 pe-0'>{validFromLabel}</Form.Label>
                          <Form.Control
                            type='date'
                            id='fromId'
                            onChange={(e) => setValidFrom(e.target.value)}
                            value={validFrom}
                            disabled={extend || submitSuccess}
                          />
                        </Form.Group>
                      </MDBCol>
                      <MDBCol md='6'>
                        <Form.Group className='black-lable mb-3 me-0 pe-0'>
                          <Form.Label className='me-0 pe-0'>{validToLabel}</Form.Label>
                          <Form.Control
                            type='date'
                            id='toId'
                            onChange={(e) => setNewValidTo(e.target.value)}
                            value={isLessDate ? validTo : newValidTo}
                            disabled={extend || submitSuccess}
                            readOnly={true}
                          />
                        </Form.Group>
                        <p className={isLessDate ? 'msgErr' : 'hide'}>{validMsg}</p>
                        <p className={isExpiredDateMax || isExpiredMax ? 'msgErr' : 'hide'}>{validExMsg}</p>
                        
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <p className={exErr ? 'msgErr' : 'msg'}>{extendMsg}</p>
                      <p className={isErr ? 'msgErr' : 'hide'}>{errors}</p>
                      
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md='12' className='d-flex justify-content-end mb-3' >
                        <Button
                          //disable the button when customer, keyType, userCount, months, days, uuid is empty
                          disabled={!extendClicked || isMaxNotUserTime || keyValidate || isLessDate || exErr || sentRequest || isMaxTime || saveDisable || isExpiredMax || isExpiredDateMax || totDaysMax || totYearsMax }//totalUserCount === maxUserCount && monthsNew === '' || daysNew === '0' || totalUserCount === userCount && monthsNew === '0' || uCountNew === ''
                          color='primary'
                          type='submit'
                          className='me-3'
                          onClick={() => handleSubmit(uuid, customerName)} >
                          Save
                        </Button>
                        <Button
                          //disable the button when customer, keyType, userCount, months, days, uuid is empty
                          color='primary'
                          type='submit'
                          disabled={!submitSuccess}
                          onClick={() => handleViewLicense(uuid, customerName)} >
                          View Key
                        </Button>
                      </MDBCol>
                    </MDBRow>
                    <KeyExtendView
                      open={openKeyViewPopup}
                      setOpen={setOpenKeyViewPopup}
                      name={customerName}
                      uuid={setUuidEdit}
                      onClose={() => {
                        setPressedView(true) // Reset the state here
                      }}
                      onCopy={() => {
                        enqueueSnackbar('Key is copied to the clipboard successful!', { variant: 'success' });
                      }}

                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Extend