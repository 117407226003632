/*  import './Key.css';
import OfflineActivation from './OfflineActivation';
import Assign from './Assign';
import Extend from './Extend';
import View from './View';
import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  //const [value, setValue] = React.useState(0);
  const {value, setValue} = useContext(KeyGenData)
  const {actionView} =useContext(DataContext)

  useEffect(() => {
    if(actionView === 3){
      setValue(actionView)
    }
  },[actionView, setValue]) 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MDBContainer fluid className='container-style key-main-row min-height'>
        <MDBRow className='d-flex justify-content-center align-items-center h-auto'>
            <MDBCol col='12' className='m-2'>
                <Box sx={{ width: '100%' }}>  
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Generate" {...a11yProps(0)} />
                      <Tab label="Assign" {...a11yProps(1)}  />
                      <Tab label="Extend" {...a11yProps(2)} />
                      <Tab label="View" {...a11yProps(3)}  />
                      </Tabs>
                  </Box>
                  <Box>
                  <TabPanel value={value} index={0}>
                      <OfflineActivation />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className='full-width'>
                      <Assign />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      <Extend />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                      <View />
                  </TabPanel>
                  </Box>
                </Box>
            </MDBCol>
        </MDBRow>
    </MDBContainer>

  );
} 
 */
/* import './Key.css';
import OfflineActivation from './OfflineActivation';
import Assign from './Assign';
import Extend from './Extend';
import View from './View';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
//import SidebarMenu from 'react-bootstrap-sidebar-menu';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { value, setValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);

  useEffect(() => {
    if (actionView === 3) {
      setValue(actionView);
    }
  }, [actionView, setValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className='menu-col'>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              aria-label="basic tabs example"
              indicatorColor="primary" 
              textColor="primary" 
            >
              <Tab label="Generate" {...a11yProps(0)} />
              <Tab label="Assign" {...a11yProps(1)} />
              <Tab label="Extend" {...a11yProps(2)} />
              <Tab label="View" {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Col>
        <Col xs={10} className='form-col'>
          <TabPanel value={value} index={0}>
            <OfflineActivation />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='full-width'>
              <Assign />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Extend />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <View />
          </TabPanel>
        </Col>
      </Row>
    </Container>
  );
}

 

 */
/* 
import './Key.css';
import OfflineActivation from './OfflineActivation';
import Assign from './Assign';
import Extend from './Extend';
import View from './View';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { value, setValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);

  useEffect(() => {
    if (actionView === 3) {
      setValue(actionView);
    }
  }, [actionView, setValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className='menu-col'sx={{ paddingLeft: '30px' }}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'left'
                },
                '& .MuiTouchRipple-root': {
                  width: 'calc(100% + 24px)', // Adjust the width value as needed
                  marginLeft: '-12px',
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                
              }}
            >
              
              
              <Tab component={Col} label="Generate" {...a11yProps(0)} sx={{
                  '&.MuiTab-root': {
                    width: 'calc(100% - 50px)', // Adjust the width as needed
                    paddingTop: '25px',
                  },
                }}/>
              <Tab component={Col} label="Assign" {...a11yProps(1)} sx={{
                  '&.MuiTab-root': {
                    width: 'calc(100% - 70px)', // Adjust the width as needed
                  },
                }}/>
              <Tab component={Col} label="Extend" {...a11yProps(2)} sx={{
                  '&.MuiTab-root': {
                    width: 'calc(100% - 70px)', // Adjust the width as needed
                  },
                }}/>
              <Tab component={Col} label="View" {...a11yProps(3)} sx={{
                  '&.MuiTab-root': {
                    width: 'calc(100% - 90px)', // Adjust the width as needed
                  },
                }}  />
            </Tabs>
          </Box>
        </Col>
        <Col xs={10} className='form-col'>
          <TabPanel value={value} index={0}>
            <OfflineActivation />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='full-width'>
              <Assign />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <div className='full-width'>
            <Extend />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
          <div className='full-width'>
            <View />
            </div>
          </TabPanel>
        </Col>
      </Row>
    </Container>
  );
}
 */
/* 
import './Key.css';
import OfflineActivation from './OfflineActivation';
import Assign from './Assign';
import Extend from './Extend';
import View from './View';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { value, setValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);

  useEffect(() => {
    if (actionView === 3) {
      setValue(actionView);
    }
  }, [actionView, setValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={1} className='menu-col'>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              sx={{

                '& .MuiTabs-indicator': {
                  display: 'none', // Remove the vertical line
                },
                
                '& .MuiTabs-flexContainer': {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'left'
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                  width: 'calc(100% + 24px)', // Adjust the width value as needed
                  marginLeft: '-12px',
                },
                '&.Mui-selected': {
                  backgroundColor: '#ccdae6;',
                  position: 'fixed',
                },
                '@media (max-width: 766px)': { // Apply styles for screens smaller than 768px
                  position: 'fixed',
                  zIndex: 1,
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  '& .MuiTabs-flexContainer': {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  },
                  '& .MuiTab-root': {
                    width: '100%',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                  },
                },
              }}
            >
              <Tab component={Col} label="Generate" {...a11yProps(0)} sx={{
                  '&.MuiTab-root': {
                    width: '100%', // Adjust the width as needed
                    paddingTop: '25px',
                    marginLeft: '-5px',
                  },
                }}/>
              <Tab component={Col} label="Assign" {...a11yProps(1)} className="left-aligned-tab" sx={{
                  '&.MuiTab-root': {
                    width: '100%', // Adjust the width as needed
                    paddingTop: '25px',
                    marginLeft: '-17px',
                  },
                }}/>
              <Tab component={Col} label="Extend" {...a11yProps(2)} sx={{
                  '&.MuiTab-root': {
                    width: '100%', // Adjust the width as needed
                    paddingTop: '25px',
                    marginLeft: '-16px',
                  },
                }}/>
              <Tab component={Col} label="View" {...a11yProps(3)} sx={{
                  '&.MuiTab-root': {
                    width: '100%', // Adjust the width as needed
                    paddingTop: '25px',
                    marginLeft: '-27px',
                   
                    
                  },
                }}  />
            </Tabs>
          </Box>
        </Col>
        <Col xs={12} md={10} className='form-col'>
          <TabPanel value={value} index={0}>
            <OfflineActivation />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='full-width'>
              <Assign />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <div className='full-width'>
            <Extend />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
          <div className='full-width'>
            <View />
            </div>
          </TabPanel>
        </Col>
      </Row>
    </Container>
  );
}
 */
import './Key.css';
import OfflineActivation from './OfflineActivation';
import Assign from './Assign';
import Extend from './Extend';
import View from './View';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
import './KeyGenerate.css';
import AuthProvider from '../../context/AuthProvider';
import OldKeyGenerate from './OldKeyGenerate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { value, setValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);
  const { extendKeyAccess, manageKey, oldGeneratedKey} = useContext(AuthProvider);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  

  useEffect(() => {
    if (actionView === 3) {
      setValue(extendKeyAccess ? 3 : 2); // Set value based on extendKeyAccess condition
    }
  }, [actionView, extendKeyAccess, setValue]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial load

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleChange = (event, newValue) => {
    localStorage.setItem('lastActiveTab', newValue.toString());
    setValue(newValue);
  };

  useEffect(() => {
    // Set the initial tab value based on stored value in localStorage
    setValue(parseInt(localStorage.getItem('lastActiveTab'), 10) || 0);
  }, [setValue]);

   useEffect(() => {
    const storedValue = parseInt(localStorage.getItem('lastActiveTab'), 10) || 0;
    const validValue = Math.min(3, storedValue); // Ensure it's within the valid range
  
    setValue(validValue);
    //setActionView(validValue)
  }, [setValue]); 
  


  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={isSmallScreen ? 2 : 1} className={isSmallScreen ? 'menu-col-small' : 'menu-col'}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none', // Remove the vertical line
                },
                '& .MuiTabs-flexContainer': {
                  flexDirection: isSmallScreen ? 'row' : 'column',
                  alignItems: isSmallScreen ? 'center' : 'flex-start',
                  justifyContent: isSmallScreen ? 'space-between' : 'left',
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
                display: isSmallScreen ? 'flex' : 'block', // Show horizontally on small screens
              }}
            >
              <Tab
                component={Col}
                label="Generate"
                {...a11yProps(0)}
                sx={{
                  '&.MuiTab-root': {
                    width: '100%',
                    paddingTop: '25px',
                    backgroundColor: value === 0 ? 'lightblue' : 'inherit',
                  },
                }}
              />
              <Tab
                component={Col}
                label="Assign"
                {...a11yProps(1)}
                className="left-aligned-tab"
                sx={{
                  '&.MuiTab-root': {
                    width: '100%',
                    paddingTop: '25px',
                    backgroundColor: value === 1 ? 'lightblue' : 'inherit',
                  },
                }}
              />
              {extendKeyAccess ? (
                <Tab
                  component={Col}
                  label="Extend"
                  {...a11yProps(2)}
                  sx={{
                    '&.MuiTab-root': {
                      width: '100%',
                      paddingTop: '25px',
                      backgroundColor: value === 2 ? 'lightblue' : 'inherit',
                    },
                  }}
                />
              )  : null}
                <Tab
                  component={Col}
                  label="Manage"
                  {...a11yProps(3)}
                  sx={{
                    '&.MuiTab-root': {
                      width: '100%',
                      paddingTop: '25px',
                      backgroundColor: extendKeyAccess ? (value === 3 ? 'lightblue' : 'inherit') : (value === 2 ? 'lightblue' : 'inherit'),
                    },
                  }}
                />
                 {oldGeneratedKey ? (
                <Tab
                  component={Col}
                  label="Custom Key Generate"
                  {...a11yProps(4)}
                  sx={{
                    '&.MuiTab-root': {
                      width: '100%',
                      paddingTop: '25px',
                      backgroundColor: value === 4 ? 'lightblue' : 'inherit',
                    },
                  }}
                />
              ) : null}
            </Tabs>

          </Box>
        </Col>
        <Col xs={12} md={isSmallScreen ? 12 : 10} className={isSmallScreen ? 'form-col-small' : 'form-col'}>
          <TabPanel value={value} index={0}>
            <OfflineActivation />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='full-width'>
              <Assign />
            </div>
          </TabPanel>
          {extendKeyAccess ? (
            <TabPanel value={value} index={2}>
              <div className='full-width'>
                <Extend />
              </div>
            </TabPanel>
          ) : (
            <TabPanel value={value} index={2}>
              <div className='full-width'>
                <View />
              </div>
            </TabPanel>
          )}
          <TabPanel value={value} index={3}>
            <div className='full-width'>
              <View />
            </div>
          </TabPanel>
          {oldGeneratedKey ? (
            <TabPanel value={value} index={4}>
              <div className='full-width'>
                <OldKeyGenerate />
              </div>
            </TabPanel>
          ) : null}

         

        </Col>
      </Row>
    </Container>
  );
}

