import React, {useState, useEffect, useContext} from 'react'
import { KeyGenProvider } from '../../context/KeyGenData';
import KeyGenerate from './KeyGenerate';

const LicenseKey = () => {
  return (
    <KeyGenProvider>
        <KeyGenerate/>
    </KeyGenProvider>
  )
}

export default LicenseKey