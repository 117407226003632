

import React, { useEffect, useState, useContext } from "react";
import KeyGenData from '../../context/KeyGenData';
import axios from "axios";
import KeyDetailsTable from "./KeyDetailsTable";
import KeyDataPopup from "./KeyDataPopup.css"; // Import the KeyDetailsTable component

const CHECK_UUIDS = process.env.REACT_APP_API_CHECK_UUID;

const KeyDetailsPopup = ({ uuid, isOpen, onClose, fetch }) => {

  const {  uuidEdited, setUuidEdited } = useContext(KeyGenData)
  const [keyDetails, setKeyDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen ) {
      // Fetch active key details that used the UUID
      
      axios
        .get(CHECK_UUIDS + `${uuid}`)
        .then((response) => {
          const responseData = response.data;

          if (!responseData) {
            setMessage("Invalid response data: No data received.");
            setSeverity("error");
            setOpen(true);
          } else if (responseData.status === false) {
            setMessage(responseData.msg);
            setSeverity("info");
            setOpen(true);
            
          } else if (responseData.result && uuid) {
            setMessage("This key already has an active key, to activate that key you must first deactivate this active key..!");
            setKeyDetails([responseData.result]);
            //console.log("edited ", uuidEdited);
            //console.log("uuid ", uuid);
            
          } else {
            setMessage("Invalid response data format: responseData.result is missing or falsy.");
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch((error) => {

          if (uuidEdited || !uuid){
          
          setMessage("There is no more active keys. Please try again later.");
          setSeverity("error");
          setOpen(true);
          //console.log("edited new ", uuidEdited);
          //console.log("new uuid ", uuid);
          }
          // Handle other error cases as needed
        });
    }

    
  }, [uuid, isOpen, uuidEdited]);

  const handleClose = () => {
    onClose();
    fetch();
  };
  
  //console.log('Details', keyDetails)

  return (
    isOpen && (
      <div className="key-details-popup">
        <div className="popup-content">
         
       
          {keyDetails.length > 0 ? (
            <>
             
            <KeyDetailsTable data={keyDetails} 
            />
            </>
            
          ) : null}
          {message && <p>{message}</p>}
          <button className="close-button" onClick={handleClose}>
            Close
          </button>
        </div>
        
      </div>
      
    )
  );
};

export default KeyDetailsPopup;

 
