import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import axios from '../../api/axios';
import { GrClose } from 'react-icons/gr';
import KeyGenData from '../../context/KeyGenData';
import Button from 'react-bootstrap/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import ExtendedKeyTable from './ExtendedKeyTable';
import { ErrorMsgs } from '../../Data/Data';

const GET_KEY = process.env.REACT_APP_API_GET_KEY;
const CHECK_KEY_EXTEND = process.env.REACT_APP_API_CHECK_KEY_EXTEND;

const KeyExtendView = (props) => {

  
  let variant;

  const { keyGenId, setUuidEdit ,uuidEdit } = useContext(KeyGenData);

  const [licenseKey, setLicenseKey] = useState('');
  const [copyState, setCopyState] = useState(false);

  //const [openAlert, setOpenAlert] = useState(false);
  //const [alertMsg, setAlertMsg] = useState('');
  //const [severity, setServerity] = useState('success');
  //const [selectedUuid, setSelectedUuid] = useState(null);
  const [extendedKeyDetails, setExtendedKeyDetails] = useState(null);
  //const [loadingDetails, setLoadingDetails] = useState(false);
  
  

  
  useEffect(() => {
    const fetchKey = async () => {
      const postData = { uuid: uuidEdit };
      try {
        const response = await axios.post(GET_KEY, postData);
        const data = response.data;
        const status = data.status;
        const result = data.result;
        const msg = data.msg;
        if (status) {
          setLicenseKey(result.fullKey);
          //setUuidEdit('');
        } else {
          variant = 'error';
          enqueueSnackbar(msg, { variant });
        }
      } catch (err) {
        variant = 'error';
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
        }
      }
    };

    if (uuidEdit !== '' && props.open) {
      fetchKey(uuidEdit);
      //console.log("UUID:", uuidEdit);
      setUuidEdit('');
    }
  }, [uuidEdit, props.open]);

  const downloadTxtFile = () => {
    const fileData = licenseKey;
    const element = document.createElement('a');
    const file = new Blob([fileData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = props.name + '_license.lic'; // Change the file name here
    document.body.appendChild(element);
    element.click();
  };

  const handleClose = () => {
    props.setOpen(false);
    setUuidEdit('');
  };

  const handleCopy = () => {
    setCopyState(true);
    if (props.onCopy) {
      props.onCopy();
    }
    setTimeout(() => {
      setCopyState(false);
    }, 2000);
  };

  return (
    <div className={props.open ? 'glass-effect' : ''}>
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="table-div">
          <div className="end-div p-0">
            <GrClose className="close-button" onClick={() => props.setOpen(false)} />
          </div>

          <MDBRow className="m-3">
            <MDBCol md="12" className="bg-white p-3" style={{ width: '400px'  }}>
              <MDBRow className="mb-3">
               
              </MDBRow>
              <MDBRow className="mb-3">
                <div className="d-flex align-items-center justify-content-center" style={{ width: extendedKeyDetails  ? '400px' : '800px' }}>
                  <Button color="primary" className="m-1" disabled={licenseKey === ''} onClick={downloadTxtFile}>
                    Save as file
                  </Button>
                  <CopyToClipboard text={licenseKey} onCopy={handleCopy}>
                    <Button color="primary" className="m-1" disabled={licenseKey === ''}>
                      {copyState ? 'Copied' : 'Copy'}
                    </Button>
                  </CopyToClipboard>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      </Popover>
    </div>
  );
};

export default KeyExtendView;
