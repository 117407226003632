import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = (props) => {

    const navigate = useNavigate();  

    //logout function
    const logout = () => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh-Token');
      sessionStorage.removeItem('err');
        navigate('/');
      };

  return (
    <div>
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Session expired!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please logout and login again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={logout} color="primary" autoFocus>
                Logout
              </Button>
            </DialogActions>
          </Dialog>
        </div>
  )
}

export default Logout