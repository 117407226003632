import React, { useEffect, useMemo, useState, useContext } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit';
import Table from './Table';
import SnackbarAlerts from '../SnackbarAlerts';
import './view.css';
import axios from "../../api/axios";


const View = () => {
  const [open, setOpen] = useState(false);
  const [severity, setServerity] = useState('success'); 
  const [message, setMessage] = useState('');
  
  useEffect(() => {
    document.title = 'Manage Key';
  }, []);


  return (
    <MDBContainer fluid className=''>
      <MDBRow className='d-flex justify-content-center align-items-center h-auto'>
        <MDBCol col='12'>
          <MDBCard className='card-table'>
            <MDBCardBody className='p-1'>
              <MDBRow className='bg-white pt-3 p-2 h-80'>
            <MDBCol md='12' className='custom-class'>
                  <SnackbarAlerts open={open} setOpen={setOpen} msg={message} severity={severity} />
                  <Table />
                </MDBCol> 
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default View; 
 

