import React, { useState, useEffect, useContext } from 'react';
import { MDBContainer, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DataContext from '../../context/DataContext';
import { Link  } from 'react-router-dom';
import { axiosNoAuth } from '../../api/axios'; // Import axiosNoAuth


const EMAIL_VERIFY = process.env.REACT_APP_APP_AUTH_VERIFY;
const CHANGE_PASS = process.env.REACT_APP_API_AUTH_PASS_RESET;

function ForgetPassword() {
  
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { email, setEmail } = useContext(DataContext)
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axiosNoAuth.post(EMAIL_VERIFY, { email: email });
  
      
      if (response.data.status) {
        const userId = response.data.result;
       // setMessage(`User ID: ${userId}`);
        setDisabled(true);
        // Call the reset password endpoint with the retrieved userId
        const resetResponse = await axiosNoAuth.post(CHANGE_PASS, { userIds: [userId] });
  
        if (resetResponse.data.status) {
          setMessage('Reset Password send successfully.');
          setTimeout(() => {
            window.location.href = `/license/reset-password?email=${email}`;
          }, 2000);
        } else {
          setError('Failed to reset password.');
        }
      } else {
        setError('User not found with the provided email');
      }
    } catch (error) {
      setError('An error occurred while retrieving the user ID.');
    }
  };
  
 
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  


  return (
    <MDBContainer fluid className=' d-flex justify-content-center align-items-center' style={{ marginTop: '10%' }}>
      <MDBCard className='d-flex justify-content-center align-items-center p-3  login-card' style={{ borderRadius: '15px' }}>
        <MDBCardBody className='p-0 login-cardBody'>
          <Form onSubmit={handleSubmit} className='text-center bg-warning; p-3'>
            <h1 className='mb-3 fs-3 fw-normal'>Forgot Password</h1>
            <Form.Group className='mb-3'>
              <Form.Control
                type='email'
                placeholder='Enter your username / email'
                onChange={handleEmailChange}
                value={email}
                required
              />
            </Form.Group>
            <div className='d-grid mb-3'>
              <Button type='submit' variant='primary' disabled={disabled}>
                Submit
              </Button>
            </div>
            {error && <p className='mb-0' style={{ color: 'red' }}>{error}</p>}
            {message && <p className='mb-0'>{message}</p>}
            <div style={{ textAlign: 'left' }}>
              <Link to="/">Go back to Login</Link>
            </div>
          </Form>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default ForgetPassword;
