import './Navgationbar.css'
import React, { useEffect, useState, useContext } from 'react';
import {
  MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBNavbarNav,
  MDBNavbarItem, MDBCollapse, MDBInputGroup
} from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { MdFileDownload } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Logout from './Logout';
import axios from '../../api/axios';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data'
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import useUserType from '../../hooks/useUserType';

const USER_DETAILS = process.env.REACT_APP_API_USER_GET_USER_DETAILS;
//const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const DOWNLOAD_LIST = process.env.REACT_APP_API_DOWNLOAD_LIST;
const DOWNLOAD_FILE = process.env.REACT_APP_API_DOWNLOAD_FILE;
//const USER_ACCESS_BY_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;

export default function Navigationbar() {

  const [showBasic, setShowBasic] = useState(false);
  const [open, setOpen] = useState(false);
  const [fName, setFName] = useState('');
  //const [] = useState('')
  const [openLogout, setOpenLogout] = useState(false)
  const { isEmptyArray, userType, setUserType, fullName, setFullName, } = useContext(DataContext)
  const { adminAccess, fetchUserAccessControls, setPartnerAccess, reSellerReport, distributorReport, sellerReports, partnerAccess, setupAccess, hasCreatePartnerPermission, DistributorFullports, ActivationReports } = useContext(AuthProvider)
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [fileVersions, setFileVersions] = useState([]);
  const [fetchAccessSuccess, setFetchAccessSuccess] = useState(true); // Default to true
  const [showDownloadMessage, setShowDownloadMessage] = useState(false);
  const [pressDownload, setPressDownload] = useState(false);



  const allowedTypes = useUserType();
  const [regAllowed, setRegAllowed] = useState(true)
  let variant
  let logoutS = sessionStorage.getItem('err')

  const shouldRender = reSellerReport || distributorReport || sellerReports || ActivationReports || DistributorFullports;

  useEffect(() => {
    //check whether there is an error 
    if (logoutS) {
      setOpenLogout(true)
    }
  }, [logoutS])

  useEffect(() => {
    //fetchAccess();
    fetchUserAccessControls();
    fetchUser();
    fetchExeFiles();
  }, [])

  const fetchExeFiles = async () => {
    try {
      const response = await axios.get(DOWNLOAD_LIST);
      //console.log('Response:', response);
      if (response.status === 200) {
        const data = response.data;
        if (data.status && data.result) {
          setFileVersions(data.result);
        }
      } else {
        console.error('Error fetching .exe files:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching .exe files:', error);
    }
  };
  //console.log('adminAccess:', adminAccess);

  const fetchUser = async () => {
    try {
      const response = await axios.get(USER_DETAILS);
      const data = response.data;
      const status = data.status;
      const result = data.result;
      const msg = data.msg
      setFullName(response.data);
      //console.log("Data", data)
      if (status) {
        setFName(result.fname)

        setUserType(result.userType)
      } else {
        setFName('')
        variant = 'error'
        // enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      setFetchAccessSuccess(false);
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response.status !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }

  }
  //console.log("Data user", userType)

  const handleDownload = () => {
    setOpenDownloadDialog(true);
    fetchExeFiles();

  };

  const handleConfirmDownload = async (selectedFile) => {
    if (!selectedFile) {
      console.warn('Invalid selected file');
      return;
    }
    try {
      const response = await axios.get(`${DOWNLOAD_FILE}${selectedFile}`, {
        responseType: 'arraybuffer', // Specify response type as arraybuffer
      });
      //console.log(response);
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const downloadUrl = URL.createObjectURL(blob);//URL forr blob
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.download = selectedFile; //select the file
      link.click();


      URL.revokeObjectURL(downloadUrl);

    } catch (error) {
      console.error('Error fetching and downloading file:', error);
      setPressDownload(false);
    }
  };

  //console.log("Download", pressDownload );

  const handleDownloadDependency = async (dependencyName) => {
    if (!dependencyName) {
      console.warn('Invalid dependency name');
      return;
    }
    try {
      const response = await axios.get(`${DOWNLOAD_FILE}${dependencyName}`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = dependencyName;
      link.click();
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error fetching and downloading file:', error);
    }
  };

  // Call selected dependency name
  const dependencies = [
    'aspnetcore-runtime-6.0.11-win-x64',
    'ndp472-kb4054531-web.exe',
    'windowsdesktop-runtime-6.0.11-win-x64.exe',
  ];

  useEffect(() => {
    //fetchAccess();
    fetchUserAccessControls();
    fetchUser();
    fetchExeFiles();

  }, [userType])

  useEffect(() => {

    if (isEmptyArray(allowedTypes)) {
      setRegAllowed(false)
      setPartnerAccess(false)
    } else {
      setRegAllowed(true)
      setPartnerAccess(true)
    }
  }, [allowedTypes, isEmptyArray])

  //console.log(allowedTypes)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh-Token');
    sessionStorage.removeItem('err');
  };

  return (
    <MDBNavbar fixed='top' expand='lg' light bgColor='light' className='navHeight'>
      <MDBContainer fluid>
        <MDBNavbarBrand></MDBNavbarBrand>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)} >
          <span className='navbar-toggler-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">

            </svg>
          </span>
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem className='MDBNavbarItem'>
              <NavLink className='navLink' to="/dashboard">Dashboard </NavLink >
            </MDBNavbarItem>
            <MDBNavbarItem className='MDBNavbarItem'>
              <NavLink className='navLink' to="/license-keys">License Keys </NavLink>
            </MDBNavbarItem>
            {(hasCreatePartnerPermission || regAllowed) && (
              <MDBNavbarItem >
                <NavLink className='navLink' to="/partners">Partners </NavLink>
              </MDBNavbarItem>
            )}
            <MDBNavbarItem className='MDBNavbarItem'>
              <NavLink className='navLink' to="/customers">Customers </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem className='MDBNavbarItem'>
              <NavLink
                className='navLink'
                to="/tally"
                onClick={() => window.open('http://62.112.10.99', '_blank')}
              >
                Tally
              </NavLink>
            </MDBNavbarItem>

            {adminAccess && (
              <MDBNavbarItem>
                <NavLink className='navLink' to="/admin-panel">
                  Admin Panel
                </NavLink>
              </MDBNavbarItem>
            )}

            {shouldRender && (
              <MDBNavbarItem className='MDBNavbarItem'>
                <NavLink className='navLink' to="/reports">Reports </NavLink>
              </MDBNavbarItem>
            )}
          </MDBNavbarNav>

          {setupAccess && (
            <MDBInputGroup className='d-flex  userWidth me-1'>
              <Button onClick={handleDownload}>
                Download Setup
                <MdFileDownload size={'20px'} className='ms-1' />
              </Button>
            </MDBInputGroup>
          )}
          <Dialog
            open={openDownloadDialog}
            onClose={() => {
              setOpenDownloadDialog(false);
              setSelectedVersion('');
              setFileVersions([]);

            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ backdropFilter: 'blur(5px)' }}
            PaperProps={{
              style: {
                borderRadius: 0,
                padding: '20px',
                maxWidth: 600,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                border: '20px solid #4fc3f7',
              },
            }} >
            <DialogTitle id='alert-dialog-title'>Version Selection
              <button
                onClick={() => {
                  setOpenDownloadDialog(false);
                  setSelectedVersion('');
                  setFileVersions([]);
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 8,
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#000',
                }}
              >
                X
              </button>
            </DialogTitle>
            <DialogContent style={{ minWidth: '300px' }}>
              <DialogContentText id='alert-dialog-description'>
                Please select the application version you want to download
              </DialogContentText>

              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <Select
                    value={selectedVersion}
                    className='custom-select'
                    onChange={(e) => setSelectedVersion(e.target.value)}
                    style={{ width: '100%', minHeight: '32px', cursor: 'pointer' }}
                    MenuProps={{ PaperProps: { style: { maxHeight: '200px' } } }}
                  >
                    <MenuItem value='' disabled>
                      {selectedVersion ? selectedVersion : 'Select version'}
                    </MenuItem>
                    {fileVersions.map((version) => (
                      <MenuItem key={version} value={version}>
                        {version}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedVersion === '' && (
                    <span style={{ position: 'absolute', top: '10px', left: '10px', color: '#aaa' }}>
                      Select version
                    </span>
                  )}
                </div>
                <Button
                  onClick={() => {
                    if (selectedVersion) { // Check if a version is selected
                      handleConfirmDownload(selectedVersion);
                      setOpenDownloadDialog(false);
                      setSelectedVersion('');
                      setShowDownloadMessage(true);
                    } else {
                      setShowDownloadMessage(false);
                    }
                  }}
                  color='primary'
                  disabled={!selectedVersion}
                  style={{ marginLeft: '10px', backgroundColor: '#2196f3', color: 'white' }} >
                  Download
                </Button>
              </div>
              {selectedVersion === '' && (
                <span style={{ fontSize: 12, color: 'red', marginTop: '4px', marginLeft: '4px' }}>
                  Please select a valid version.
                </span>
              )}


            </DialogContent>
            <DialogActions style={{ padding: 29 }}>
              <Button
                onClick={() => {
                  handleDownloadDependency('windowsdesktop-runtime-6.0.11-win-x64.exe')
                  setOpenDownloadDialog(false);
                  setSelectedVersion('');
                  setShowDownloadMessage(true);
                }}
                color='primary'
                className='download-button' //size='sm' // 'sm' for small size, 'lg' for large size
              >
                Microsoft WDR - 6.0.11 (x64)
              </Button>
              <Button
                onClick={() => {
                  handleDownloadDependency('ndp472-kb4054531-web.exe')
                  setOpenDownloadDialog(false);
                  setSelectedVersion('');
                  setShowDownloadMessage(true);
                }}
                color='primary'
                className='download-button' //size='sm' // 'sm' for small size, 'lg' for large size
              >
                .NET Framework 4.7.2
              </Button>
              <Button
                onClick={() => {
                  handleDownloadDependency('aspnetcore-runtime-6.0.11-win-x64.exe')
                  setOpenDownloadDialog(false);
                  setSelectedVersion('');
                  setShowDownloadMessage(true);
                }}
                color='primary'
                className='download-button' //size='sm' // 'sm' for small size, 'lg' for large size
              >
                ASP.NET Core 6.0.11 (x64)
              </Button>
            </DialogActions>
          </Dialog>
          {handleConfirmDownload() && handleDownloadDependency() && (
            <Dialog
              open={showDownloadMessage}
              onClose={() => setShowDownloadMessage(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Download Setup</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {/*The setup will be downloaded shortly.*/}
                  Your file starts to download now!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowDownloadMessage(false)} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <MDBInputGroup tag="form" className='d-flex  userWidth me-1'>
            {fName} ({userType})
          </MDBInputGroup>
          <MDBInputGroup tag="form" className='d-flex  w-auto me-3'>
            <MDBNavbarNav>
              <MDBNavbarItem className='MDBNavbarItem'>
                <NavLink className='navLink' to="/profile">
                  <CgProfile size={'30px'} className='navBar-icon' />
                </NavLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBInputGroup>
          <MDBInputGroup tag="form" className='d-flex  w-auto me-3-1'>
            <FiLogOut size={'30px'} onClick={handleClickOpen} />
          </MDBInputGroup>
          <Logout
            open={openLogout}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to log out?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <NavLink href="/">
                <Button onClick={logout} color="primary" autoFocus>
                  Logout
                </Button>
              </NavLink>
            </DialogActions>
          </Dialog>
        </MDBCollapse>

      </MDBContainer>

    </MDBNavbar>
  );
}