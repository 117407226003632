import React, {useState, useEffect, useContext} from "react";
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import { useLocation } from 'react-router-dom';
import SnackbarAlerts from '../../../SnackbarAlerts';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import "./Table.css";
import axios from "../../../../api/axios";
import DataContext from '../../../../context/DataContext';
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../../../Data/Data'
import KeyGenData from '../../../../context/KeyGenData';

const EXPIRINGKEYS_URL = process.env.REACT_APP_API_GET_EXPIRING_KEYS_NEXT_MONTH;

const customStyles = {
  rows: {
      style: {
          minHeight: '60px', // override the row height
      },
  },
  headCells: {
      style: {
        borderStyle: 'solid',
          borderColor: '#40c4ff',
          borderWidth: '1px' ,
          borderCollapse: 'collapse',
          fontWeight: 'bold',
          padding: '5px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#b2ebf2',
      },
  },
  cells: {
      style: {
          overFlowWrap: 'break-word',
          borderStyle: 'solid',
          borderColor: '#40c4ff',
          borderWidth: '1px' ,
          borderCollapse: 'collapse',
          padding: '5px',
          alignItems: 'center',
          justifyContent: 'center'
          
      },
  },
};

export default function BasicTable() {

  let variant

    const [expiringKeys, setExpiringKeys] = useState([{ index : 0,expDate: '', customerName: '', moblieNo: '',tpNo:''}])
    
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setSeverity] = useState('error');
  
  const navigate = useNavigate()
  const {setActionView, extendKeyAccess, setUuidSearch, setUserSearch} = useContext(DataContext)
  const { setValue , value} = useContext(KeyGenData)

  const columns =[
    {
      name: 'Expire Date',
      selector: row => row.expDate,
      wrap: true,/* 
      width: '100px', */
    },
    {
      name: 'Customer Name',
      selector: row => row.customerName,
      wrap: true,/* 
      width: '100px', */
    },
    {
      name: 'Mobile',
      selector: row => row.moblieNo  ,
      wrap: true,
    },
    {
      name: 'Telephone',
      selector: row =>  row.tpNo ,
      wrap: true,
    },
    {
      name: "Actions",
      button: true,   /*  
      width: '230px', */
      cell: (row) => (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button 
            className="success-button"
            variant="success"
            onClick={() => actionClick(row.uuid)}
            >
              View 
          </Button>
        </div>
          
      ),
  },
  ];

  //View Key in key view page
  const actionClick = (uuid) => {
    navigate('/license-keys')
    setActionView(3)
    setUuidSearch(uuid)
    localStorage.setItem('lastActiveTab', '3');
    setValue(extendKeyAccess ? 3 : 2);
  } 

    useEffect(() => {

        const fetchExpiringkeys = async () => {
            try {
                const response = await axios.get(EXPIRINGKEYS_URL);
                  const status = response.data.status
                  const result = response.data.result
                  const msg = response.data.msg
                  if (status) {
                      setExpiringKeys(result);
                  } else {// handle the empty data
                      setExpiringKeys([]);
                      variant = 'error'
                      enqueueSnackbar(msg, {variant})
                  }
            } catch (err) {
              variant = 'error'
              if(!err.response){
                enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
              }else if(err.response !== 403){
                enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
              }
            }
        }
        fetchExpiringkeys();
    }, []);

    return (
        <div className="Table">
            <h3 className="tab-text">Upcoming 31 Days Renewals</h3>
                <MDBRow className='d-flex justify-content-center align-items-center h-auto'>
                    <MDBCol col='12'>          
                        <MDBCard className='card-table'>
                            <MDBCardBody className='p-0'>
                            <MDBRow className='custom-table-style bg-white p-2 pt-3 h-80'>
                                <MDBCol md='12'>
                                <SnackbarAlerts open={open} setOpen={setOpen} msg={alertMsg} severity={severity}/>                                
                                    <DataTable
                                    columns={columns}
                                    data={expiringKeys}
                                    pagination
                                    persistTableHead
                                    responsive
                                    customStyles={customStyles}
                                    />
                                </MDBCol>
                            </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
        </div>
    );
}
