import React, { useState, useEffect, useContext } from 'react'
//import TableBase from './TableBase'; --never used
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from '../../api/axios';
import SnackbarAlerts from "../SnackbarAlerts";
import DataContext from '../../context/DataContext';
import { Status, Priority } from '../../Data/Data'
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data'
import DataTable from 'react-data-table-component';
import ConfirmDialog from '../ConfirmDialog';


const USER_TYPE_URL = process.env.REACT_APP_API_USER_TYPE_FIND_ALL;
const USER_TYPE_BY_ID = process.env.REACT_APP_API_USER_TYPE_FIND_BY_ID;
const USER_TYPE_SAVE = process.env.REACT_APP_API_USER_TYPE_SAVE;
const USER_TYPE_UPDATE = process.env.REACT_APP_API_USER_TYPE_UPDATE;
//const USER_TYPE_DELETE = process.env.REACT_APP_API_USER_TYPE_DELETE; --never used
const USER_GROUP_SAVE = process.env.REACT_APP_API_USER_GROUP_SAVE;
const USER_GROUP_URL = process.env.REACT_APP_API_USER_GROUP_FIND_ALL;
const USER_GROUP_UPDATE = process.env.REACT_APP_API_USER_GROUP_UPDATE;
const USERTYPE_HAS_USERS = process.env.REACT_APP_API_USER_GET_USER_BY_USER_TYPE;


const UserTypes = () => {

  let variant


  const { isEmpty } = useContext(DataContext) //uuidSearch, setUuidSearch, nameSearch, setNameSearch, actionView, setActionView --never used
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Msg, setMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [type, setType] = useState('');
  const [priority, setPriority] = useState(0);
  const [group, setGroup] = useState('');
  const [status, setStatus] = useState('');


  const [dataId, setDataId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this field?"
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('')
  const [editObj, setEditObj] = useState({})
  const [isPriorityDisabled, setIsPriorityDisabled] = useState(false);
  const [isChange, setIsChange] = useState(false); 
  

  const columns = [
    {
      name: 'User Type',
      selector: row => row.type,
      wrap: true,
    },
    {
      name: 'Status',
      selector: row => (row.status === true) ? 'Active' : 'De-active',
      wrap: true,
    },
    {
      name: 'Actions',
      button: true,
      width: '300px',
      selector: row => row.vatNo,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            className="edit-button"
            variant="warning"
            onClick={() => actionClick(row.id, 'edit')} >
            Edit
          </Button>
          <Button
            className={row.status === true ? "delete-button" : 'hide'}
            variant="danger"
            onClick={() => enableDisable(row.id, 'disable', row.type)} >
            Disable
          </Button>
          <Button
            className={row.status === false ? "success-button" : 'hide'}
            variant="success"
            onClick={() => enableDisable(row.id, 'enable', row.type)} >
            Enable
          </Button>
        </div>

      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px',
        // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'

      },
    },
  };


  const actionClick = (id, action) => {
    setDataId(id);
    if (action === 'edit') {
      setIsPriorityDisabled(true);
      setOpenEdit(true);

    }
  }

  const enableDisable = async (id, action, type, priority) => {
    if (action === 'disable') {
      try {
        // Check if the user type has associated users
        const response = await axios.get(`${USERTYPE_HAS_USERS}${id}/1`); 
        const hasUsers = response.data.result;
  //console.log("type", hasUsers);
        if (hasUsers.length > 0) {
          // Show an error message and stop further execution if users are associated
          enqueueSnackbar('Cannot disable this user type because it has associated users.', { variant: 'error' });
          return;
        }
  
        // Proceed with the disabling action if no users are associated
        setOpenDelete(true);
        setConfirmMsg("Are you sure you want to disable this user type?");
        setEditObj({ id, status: false, type, priority });
  
      } catch (err) {
        // Show an error message if the API call fails
        enqueueSnackbar('Failed to check if the user type has associated users. Please try again.', { variant: 'error' });
      }
    } else if (action === 'enable') {
      // Proceed with enabling action
      setOpenDelete(true);
      setConfirmMsg("Are you sure you want to enable this user type?");
      setEditObj({ id, status: true, type, priority });
    }
  };

  const handleEnableDisable = async () => {
    setOpenDelete(false)
    try {
      const response = await axios.put(USER_TYPE_UPDATE, editObj);
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        variant = 'success'
        //fetchUserType();
        enqueueSnackbar('Updated Successfully!', { variant })
        setDataId(0)
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
      setDataId(0)
    }
  }

  

  const fetchUserType = async () => {
    try {
      const response = await axios.get(USER_TYPE_URL + `/2`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if (status) {
        setData(result);
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  };

 


  useEffect(() => {
    fetchUserType();
  }, [type]);


  const fetchUserGroup = async () => {
    try {
      const response = await axios.get(USER_GROUP_URL + `/2`);
      const status = response.data.status;
      const result = response.data.result;
      const priority = response.data.result.priority;
      const msg = response.data.msg;
      if (status) {
        setData(result);
        setPriority(priority);
      } else {
        setData([]);
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

 

  // Handle submit method for UserType
  const handleSubmit = async (e) => {
    e.preventDefault();
    const intPriority = parseInt(priority, 10);
    if (status === "true") {
      setStatus(true);
    } else if (status === "false") {
      setStatus(false);
    }
    const newData = { type, status };

    const isDuplicate = data.some((item) => item.type.toLowerCase() === type.toLowerCase());
  if (isDuplicate) {
    variant = 'error';
    enqueueSnackbar('User Type already exists!', { variant });
    setGroup('');
    setType('');
    setStatus('');
    setPriority(0);
    return;
  }

    try {
      // Step 1: Create UserType
      const responseUserType = await axios.post(USER_TYPE_SAVE, newData);
      const isSuccessUserType = responseUserType.data.status;
      const msgUserType = responseUserType.data.msg;
      if (isSuccessUserType) {
        variant = 'success';
        enqueueSnackbar('Adding New User Type Successful!', { variant });
        setGroup('');
          setType('');
          setStatus('');
          setPriority(0);
        // Step 2: Automatically create UserGroup with the same name
       /*  const responseUserGroup = await axios.post(USER_GROUP_SAVE, { group: type, status, priority: intPriority });
        if (responseUserGroup.data.status) {
          // UserGroup creation successful
          enqueueSnackbar('Adding New User Group Successful!', { variant : 'success'});
          setGroup('');
          setType('');
          setStatus('');
          setPriority(0);
          //fetchUserType();
        } else {
          // UserGroup creation failed
          enqueueSnackbar('Failed to create associated User Group', { variant : 'error' });
        } */
      } else {
        variant = 'error';
        enqueueSnackbar(msgUserType, { variant });
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    //setIsPriorityDisabled(true);
    const intPriority = parseInt(priority, 10);
    if (status === "true") {
      setStatus(true);
    } else if (status === "false") {
      setStatus(false);
    }
    const updateUserData = { id: dataId, type, status};
    try {
      // Step 1: Update UserType
      const userTypeResponse = await axios.put(USER_TYPE_UPDATE, updateUserData);
      const userTypeStatus = userTypeResponse.data.status;
      const userTypeMsg = userTypeResponse.data.msg;

      if (userTypeStatus) {
        variant = 'success';
        enqueueSnackbar('UserType Updated Successfully!', { variant });
        // Step 2: Fetch the associated userGroup information
        const userGroupResponse = await axios.get(USER_GROUP_URL + `/2`);
        const userGroup = userGroupResponse.data.result.find(group => group.group === type);
        //console.log(userGroupResponse)
        // Step 3: Update or Create associated UserGroup with the same name
       // if (userGroup) {

          /* if (status === "true") {
            setStatus(true);
          } else if (status === "false") {
            setStatus(false);
          }
          const updateGroupData = { id: userGroup.id, group: type, status, priority: intPriority  };
          const userGroupUpdateResponse = await axios.put(USER_GROUP_UPDATE, updateGroupData);
          if (userGroupUpdateResponse.data.status) {
            enqueueSnackbar('Associated UserGroup Updated Successfully', { variant });
          } else {
            enqueueSnackbar('Failed to update associated UserGroup', { variant: 'error' });
          } */
        } /* else {
          // Create a new UserGroup with the same name
         /*  const createUserGroupResponse = await axios.post(USER_GROUP_SAVE, { group: type, status, priority: intPriority  });
          if (createUserGroupResponse.data.status) {
            enqueueSnackbar('New UserGroup Created Successfully!', { variant });
          } else {
            enqueueSnackbar('Failed to create associated UserGroup', { variant: 'error' });
          } 
        } */

      /*   setDataId(0);
        setType('');
        setStatus('');
        setPriority(0);
        //fetchUserType();
        // Assuming you have a function to fetch userGroups
      } else {
        variant = 'error';
        enqueueSnackbar(userTypeMsg, { variant });
      } */
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      setDataId(0);
      
    }finally {
      // Re-enable the priority field
      setIsPriorityDisabled(false);
  }
  };
  //Get customer by ID to update
//console.log('Edit', isPriorityDisabled)

/* useEffect(() => {
  if (!isPriorityDisabled && priority !== null) {
    setPriority(Number(priority));
  }
}, [priority, isPriorityDisabled]);

const validatePriority = (value) => {
  const intValue = parseInt(value, 10);
  if (isNaN(intValue) || intValue <= 0) {
    return false;
  }
  return true;
}; */

/* const handlePriorityChange = (e) => {
  const { value } = e.target;
  if (validatePriority(value)) {
    setPriority(value);
    setIsChange(true);
  } else {
    setPriority('');
  }
}; */

const canSubmit = type && status !== '' ;


  const getUserTypeById = async () => {
    setOpenEdit(false);
    try {
      const response = await axios.get(USER_TYPE_BY_ID + `${dataId}`);
      const status = response.data.status;
      const result = response.data.result;
      //const priority = response.data.result.priority;
      const msg = response.data.msg;
      if (status) {
        setType(result.type);
        setStatus(result.status);
        //setPriority(priority);
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }

    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  return (
    <>
      <ConfirmDialog
        open={openEdit}
        msg={editConfirm}
        setOpen={setOpenEdit}
        onYes={() => getUserTypeById()}
      />
      <ConfirmDialog
        open={openDelete}
        msg={confirmMsg}
        setOpen={setOpenDelete}
        onYes={() => handleEnableDisable()}
      />
      <SnackbarAlerts
        open={open}
        setOpen={setOpen}
        msg={Msg}
        severity={severity}
      />
      <MDBCard className='card-table'>
        <MDBCardBody className='p-0'>
          <MDBRow className='bg-blue p-3 mb-2'>
            <Form onSubmit={handleSubmit}>
              <MDBRow>
                <MDBCol md='3' className='mb-1 mu-1'>
                  <Form.Group className='mb-3' >
                    <Form.Label className='white-lable' size='sm'>User Type</Form.Label>
                    <span className="mandatory"> *</span>
                    <Form.Control
                      type='text'
                      id='typeId'
                      onChange={(e) => {
                        setType(e.target.value)
                        setIsChange(true);}}
                      value={type}
                    />
                  </Form.Group>
                </MDBCol>
                <MDBCol md='3' className='mb-1 mu-1'>
                  <Form.Group className='mb-3' >
                    <Form.Label className='white-lable' size='sm'>Status</Form.Label>
                    <span className="mandatory"> *</span>
                    <Form.Select
                      id='statusId'
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setIsChange(true);
                      }}
                      value={status}
                    >
                      <option disabled value=''>Status...</option>
                      {Status.map((options, index) => (
                        <option key={index} value={options.value}>{options.select}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </MDBCol>
                <MDBCol md='3' className='mb-1 mu-1'>
               {/*  {dataId === 0 && !isPriorityDisabled && (
                  <Form.Group className='mb-3'>
                <Form.Label className='white-lable' size='sm'>Priority</Form.Label>
                    <span className="mandatory"> *</span>
                      <Form.Control
                        type="number"
                        value={priority}
                        onChange={handlePriorityChange}
                        required
                        min="0"
                        placeholder="Priority for user group"
                        disabled={isPriorityDisabled}
                      />
  
  

                      
                    
                  </Form.Group>
                   )} */}
                </MDBCol>
                <MDBCol md='3' className='mb-3'>
                
                  <Button
                    className={dataId === 0 ? '' : 'hide-row'}
                    style={{ marginTop: '30px', width: '100%' }}
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                  >
                    Add New
                  </Button>
                  
                  <Button
                    className={dataId === 0 ? 'hide-row' : ''}
                    style={{ marginTop: '30px', width: '100%' }}
                    onClick={handleEdit}
                    disabled={isEmpty(type) || isEmpty(status) ||  !isChange }
                  >
                    Edit
                  </Button>
                </MDBCol>
              </MDBRow>
            </Form>
          </MDBRow>
          <MDBRow className='bg-white p-3 h-80'>
            <MDBCol md='12'>
              <MDBRow>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  persistTableHead
                  responsive
                  customStyles={customStyles}
                />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </>
  )
}

export default UserTypes