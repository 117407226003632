// KeyHistoryPopup.js
import axios from "../../api/axios";
import IconButton from '@mui/material/IconButton';
import { GrClose } from 'react-icons/gr';
import PrintIcon from '@mui/icons-material/Print';
import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component'; // Import DataTable component
import { Modal, Button } from 'react-bootstrap'; // You can use any UI library
import KeyGenData from '../../context/KeyGenData';
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthProvider";
import  './KeyHistoryPopup.css';

const ASSIGN_KEY_HISTORY = process.env.REACT_APP_API_KEY_ASSIGN_HISTORY;


const KeyAssignHistoryPopup = ({ open, setOpen }) => {
    const customStyles = {
        rows: {
          style: {
            minHeight: '60px',
          },
        },
        headCells: {
          style: {
            borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px',
            borderCollapse: 'collapse',
            fontWeight: 'bold',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#b2ebf2',
          },
        },
        cells: {
          style: {
            overflowWrap: 'break-word',
            borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px',
            borderCollapse: 'collapse',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      };
    
  const [keyHistory, setKeyHistory] = useState([]);
  const {  keyGenId } = useContext(KeyGenData)
  const { fullName } = useContext(DataContext)
  const { printAccess } = useContext(AuthContext)

  useEffect(() => {
    if (open) {
      // Fetch key history data when the popup is opened
      fetchAssignKeyHistory();
    }
  }, [open]);

  const fetchAssignKeyHistory = async () => {
    try {
      const response = await axios.get(ASSIGN_KEY_HISTORY + `${keyGenId}`);
      const { status, msg, httpStatus, result } = response.data;
      if (status) {
        setKeyHistory(result);
      } else {
        console.error("Error fetching key history:", msg);
        // Handle error cases where status is false
      }
    } catch (error) {
      console.error("Error fetching key history:", error);
      // Handle errors if any
    }
  };
  
  

  const handleClose = (event) => {
   
      setOpen(false);
    
  };

  const handlePrint = () => {

    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();
    // Construct the content for printing
    const content = `
    
    <html>
      <head>
        <!-- Include any necessary styles here -->
        <style>
          /* Define print-specific styles here */
          body {
            font-family: 'Calibri Light', sans-serif;
          }
          .print {
            margin-top: none;
          }
          .header {
            font-size: 16px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .top-header {
            font-size: 16px;
            font-weight: bold;
            color: #595757; /* Light gray color */
          }
          .sub-header {
            font-size: 13px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .content {
            font-size: 13px;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table tbody { 
            padding-bottom: 30px;  
          }
          .table th {
            border: 1px solid #dddddd; /* Light gray border */
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table td {
            border: 1px solid #a6a6f5; /* Dark blue border */
            background-color: #fefefe;
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
          }
          .table th {
            background-color: #f2f2f2; /* Light gray background */
            color: #595757; /* Light gray color */
          }
          .footer {
            position: fixed;
            bottom: -10.5px;
            width: 100%;
            text-align: right;
            padding: 10px 0;
            border-top: 1px solid #ddd; /* Light gray border */
            margin-top: 50px;
          }
        </style>
      </head>
      <body>
      <div class="print">
      <div class="top-header">
        <div style="display: flex; justify-content: space-between; align-items: center;">
        <p style="margin: 0; font-size: 28px; ">ASSIGN KEY HISTORY OF "${keyHistory.length > 0 ? keyHistory[0].keyGen?.uuid || 'N/A' : 'N/A'}" </p>
      
        <div style="text-align: right;">
            &nbsp; 
            <p style="color: #888888; margin: 0;">Date: ${printDateTime}</p>
            <p style="color: #888888; margin: 0;">User Name: ${fullName.result ? (fullName.result.fname + (fullName.result.lname ? ' ' + fullName.result.lname : '')) : 'N/A'}</p>
          </div>
        </div>
      </div>
    </div>
    
        <br>
    
    
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
              <th>User</th>
              <th>Days</th>
              
              <th>Users</th>
              <th>Key Type</th>
              <th>Customer</th>
            
              
            </tr>
          </thead>
          <tbody>
            ${keyHistory.map(row => `
              <tr>
                <td>${row.createdDate || 'N/A'}</td>
                <td>${row.description || 'N/A'}</td>
                <td>${row.createdBy || 'N/A'}</td>
                <td>${row.days || 'N/A'}</td>
                <td>${row.keyGen.users || 'N/A'}</td>
                
                
                <td>${row.keyGen.keyType || 'N/A'}</td>
               
                <td>${row.keyGen.customer.fname || 'N/A'}</td>
                
              </tr>
            `).join('')}
          </tbody>
        </table>
    
        <script>
          
    
            // Trigger the printing process
            window.print();
            window.close(); // Close the window after printing (optional)
          
    
          
        </script>
    
      </body>
    </html>
    
    
    `;

    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();

    } else {
      console.error('Failed to open print window');
    }
  };

  

   const columns = React.useMemo(
    () => [

      
      {
        name: 'Created Date',
        selector: (row) => row.createdDate || 'N/A',
        wrap: true,
        width: '110px',
      },
      {
        name: 'Action',
        selector: (row) => row.description || 'N/A',
        wrap: true,
        
      },
      {
        name: 'User',
        selector: (row) => row.createdBy || 'N/A',
        wrap: true,
      },
      {
        name: 'Days',
        selector: (row) => row.keyGen?.days || 'N/A',
        wrap: true,
        width: '60px',
      },
      {
        name: 'User Count',
        selector: (row) => row.keyGen?.users || 'N/A',
        wrap: true,
        width: '80px',
      },
     
      /* {
        name: 'Updated UUID', // New column header
        selector: (row) => {
          // Extract updated UUID from description
          const description = row.description;
          const updatedUUIDIndex = description.indexOf('Update UUID :');
          if (updatedUUIDIndex !== -1) {
            const updatedUUID = description.substring(updatedUUIDIndex + 13); // Extract UUID after 'Update UUID :' (13 characters)
            return updatedUUID;
          }
          return 'N/A'; // Return 'N/A' if not found
        },
        wrap: true,
      }, */
    ],
    []
  );

  return (

    
    <Modal show={open} onHide={handleClose} size="lg" >
      
      <Modal.Body  style={{ borderTop: '20px solid #40c4ff', borderBottom: '20px solid #40c4ff',  borderLeft: '20px solid #40c4ff', borderRight: '20px solid #40c4ff' }}>
      {printAccess && (
      <Button onClick={handlePrint} className="close-button" style={{ position: 'absolute', top: 0, padding: '2px' }}>
        <IconButton size="small">
          <PrintIcon style={{ fontSize: '18px', color: 'white' }} />
          </IconButton>
          <span style={{ fontSize: '18px', paddingRight: '8px', fontWeight: 'normal' }}>Print</span>
         
        </Button>
      )}

    
        <GrClose onClick={handleClose} className="close-button" style={{ borderColor: '#40c4ff', top: 0, left: '20px' , marginLeft: printAccess ? '0' : '898px'}}/>
          
       

       
       
      <div style={{ padding: '20px', borderBottom: '1px solid #40c4ff', display: 'flex', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold' }}>
          {/* {keyHistory.length > 0 ? keyHistory[0].keyGen?.uuid || 'N/A' : 'N/A'} */}
          {keyHistory.length > 0 ? (
          <>
            {keyHistory[0].keyGen?.uuid || 'N/A'} - {' '}
            <span style={{ color: keyHistory[keyHistory.length - 1].description === 'DEACTIVATED' ? 'red' : 'rgb(0, 128, 0)' }}>
              {keyHistory[keyHistory.length - 1].description === 'DEACTIVATED' ? ' Deactivated' : ' Activated'}
            </span>
          </>
        ) : (
          'N/A'
        )}
        </div>
        <div style={{ overflowX: 'auto', maxHeight: '500px' }}>
        {keyHistory.length > 0 ? (
          <DataTable
            columns={columns}
            data={keyHistory.reverse()}
            customStyles={customStyles}
            pagination
            responsive
            noHeader
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
          />
        ) : (
          <p style={{ textAlign: 'center', fontSize: '20px' }}>No key history available</p>
        )}
        </div>
      </Modal.Body>
    </Modal>
   
  );
};

export default KeyAssignHistoryPopup;

 