import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { RiSettings3Line } from 'react-icons/ri';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SnackbarAlerts from "../SnackbarAlerts";
import ConfirmDialog from "../ConfirmDialog";
import { TablesFilterCU, FilterByUsers } from "../../Data/Data"
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TransferPopup from "./TransferPopup";
import { First } from "react-bootstrap/esm/PageItem";
import { Hidden } from "@mui/material";
import DataContext from '../../context/DataContext';
import AuthContext from '../../context/AuthProvider';
import './custom-styles.css';
import KeyGenData from "../../context/KeyGenData";
//import Table from "../KeyGenerate/Table";

/* import AddKeyPopup from "./AddKeyPopup"; */

const CUSTOMER_BY_SELLER = process.env.REACT_APP_API_CUSTOMER_FIND_BY_SELLER_ID;
const CUSTOMER_DELETE = process.env.REACT_APP_API_CUSTOMER_DELETE;

const CustomerTable = () => {
  let variant

  let activeCustomers = [];

  const navigate = useNavigate()
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(0)
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this user?"
  const [openDelete, setOpenDelete] = useState(false);
  const deleteConfirm = "Are you sure you want to delete this user?"

  const [searchBy, setSearchBy] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [sentRequest, setSendRequest] = useState(false)
  const [type, setType] = useState(0)
  const [disableCustomer, setDisableCustomer] = useState('');

  const [sellerName, setSellerName] = useState('')
  const [sellerId, setSellerId] = useState(0);
  const [customerName, setCustomerName] = useState('')
  const [desc, setDesc] = useState('')
  

  const [openTransfer, setOpenTransfer] = useState(false)
  const { setActionView, setSellerSearch,disabledKeyGenIdCustomer, transferCustomerId, setTransferCustomerId, setDisabledKeyGenIdCustomer, activeKeyGenIdCustomer, setActiveKeyGenIdCustomer, userName, setCustomerSearch, setUserName } = useContext(DataContext)
  const { createCustAccess, extendKeyAccess, transferCustAccess } = useContext(AuthContext)
  const { setValue , value} = useContext(KeyGenData)

  const columns = [
    {
      name: 'First Name',
      selector: row => row.fname,
      wrap: true,
      width: '100px',
    },
    {
      name: 'Last Name',
      selector: row => row.lname,
      wrap: true,
      width: '100px',
    },
    {
      name: 'Vat No',
      selector: row => row.vatNo,
      wrap: true,
      width: '60px',
    },
    {
      name: 'Date of Birth',
      selector: row => row.dob,
      width: '100px',
    },
    {
      name: 'Status',
      selector: row => (row.status === true) ? 'Active' : 'De-active',
      width: '60px',
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      width: '70px',
    },
    {
      name: 'Company Name',
      selector: row => row.companyName,
      wrap: true,
      width: '130px',
    },
    {
      name: 'Address',
      cell: row => {
        const formattedAddress = row.address.replace(/,/g, '');
        return <div>{formattedAddress}</div>;
      },
      wrap: true,
      width: '150px',
    },
    {
      name: 'Country',
      selector: row => row.countryName,
      wrap: true,
      width: '80px',
    },
    {
      name: 'Telephone',
      selector: row => row.tpNo,
      width: '100px',
      wrap: true,
    },
    {
      name: 'Mobile',
      selector: row => row.mobileNo,
      width: '100px',
      wrap: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      wrap: true,
      width: '180px',
    },
    {
      name: 'Industry',
      selector: row => row.industryName,
      wrap: true,
    },
    {
      name: 'seller',
      selector: row => row.sellerName,
      wrap: true,
      width: '70px',
    },

    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <DropdownButton id="dropdown-basic-button"
            title={<RiSettings3Line />}
            
            drop="down" // or "up" based on your preference
            
            
          >
            <Dropdown.Item
              onClick={() => editKeyClick(row.id)}
              className={row.status === true ? "success" : "hide"}
              
            >
              Generate Key
            </Dropdown.Item>
            <Dropdown.Item
              className="view"
              variant="success"
              
              onClick={() => handleViewKeysClick(row.id, row.fname, row.lname, setValue)}
            >
              View Keys
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => actionClick(row.id, 'edit')}
              
              className="edit"
            >
              Edit
            </Dropdown.Item>
            {activeCustomers && (
            <Dropdown.Item
              onClick={() => actionClick(row.id, 'delete')}
              className="delete"
              
            >
              Remove
            </Dropdown.Item>
            )}
            {transferCustAccess && (
              <Dropdown.Item
                onClick={() => TransferClick(row.id, row.sellerName, desc,  row.sellerId, row.fname, row.lname)}
                className={row.status === true ? "primary" : "hide"}
                
              >
                Transfer Customer
              </Dropdown.Item>
            )}
          </DropdownButton>
        </div>
      ),
    }
    
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        // override the row height
      },

    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
        position: 'sticky', // Make the table headers sticky
       top: '0', // Position the table headers at the top of the scrolling container
       zIndex: '100', // Ensure the table headers appear above the scrolling content
      },

    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        

      },
    },
  };


 
 
  
  //set customerId openEdit openDelete true base on action
  const actionClick = (id, action) => {
    if (action === 'edit') {
      setOpenEdit(true);
    } else if (action === 'delete') {
      setOpenDelete(true);
    }
    setCustomerId(id)
  }

  const handleViewKeysClick = (id, firstName, lastName, setValue) => {
    let Custname = firstName + ' ' + lastName;
    navigate('/license-keys'); // Navigate to the "license-keys" page
    setActionView(3); // Set the action to indicate "View Keys"
    setCustomerSearch(id); // Set the user ID for which keys need to be viewed
    setActiveKeyGenIdCustomer(activeKeyGenIdCustomer);
    setDisabledKeyGenIdCustomer(disabledKeyGenIdCustomer);
    localStorage.setItem('lastActiveTab', '3'); // Update the local storage
    setValue(extendKeyAccess ? 3 : 2); // Set the tab index to the "Manage Key" tab
  };
  
  
  
  //console.log("value ", value)

  const TransferClick = (id, sellerName, desc, sellerId, fName, lName) => {
    let name = fName + ' ' + lName
    setCustomerId(id)
    setSellerId(sellerId)
    setCustomerName(name)
    setSellerName(sellerName)
    setOpenTransfer(true)
    setDesc(desc);
    

    //console.log("Seller Id ", sellerId);
    //console.log("User Id ", id);
   
  }

  const editKeyClick = (id) => {
    navigate('/license-keys', { state: [true, id] })
    localStorage.setItem('lastActiveTab', '0');
  }

  const handleTransferComplete = (customerId) => {
    setTransferCustomerId(customerId);
  };

  const fetchCustomers = async (value) => {
    try {
      const response = await axios.get(CUSTOMER_BY_SELLER + `/${value}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
  
      if (status) {
        if (result) {
          // Filter active customers and set them
          activeCustomers = result.filter(customer => customer.status);
          setCustomers(activeCustomers);
          setCustomerSearch(activeCustomers);
          
          
          // Check for inactive customers and perform action
          result.forEach(customer => {
            if (!customer.status) {
              setDisableCustomer(); // Call your function for inactive customers
            }
          });
  
          
        } else {
          setCustomers([]);
        }
      } else {
        setCustomers([]);
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };


  useEffect(() => {
    fetchCustomers(type)
  }, [type])

  const handleDelete = (id) => {
    axios.delete(CUSTOMER_DELETE + `${id}`)
      .then(response => {
        const msg = response.data.msg

        if (response.status) {
          setOpenDelete(false);
          variant = 'success'
          enqueueSnackbar('Delete Successful!', { variant })
          fetchCustomers(type);
        } else {
          setOpenDelete(false)
          variant = 'error'
          enqueueSnackbar(msg, { variant })
        }
      })
      .catch(err => {
        setOpenDelete(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
        }
      });
  };

  const handleEdit = (id) => {
    navigate('/new-user', { state: ["customer", id] })
  };

  const customerData = useMemo(() => [...customers], [customers]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  useEffect(() => {
    //filter data for search
    if (searchBy === 'fname') {
      setFilteredItems(customerData.filter(item =>
        item.fname && item.fname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'lname') {
      setFilteredItems(customerData.filter(item =>
        item.lname && item.lname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'vatNo') {
      setFilteredItems(customerData.filter(item =>
        item.vatNo && item.vatNo.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'countryName') {
      setFilteredItems(customerData.filter(item =>
        item.countryName && item.countryName.toLowerCase().includes(filterText.toLowerCase()),
      ));
    }
    else if (searchBy === 'tpNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(customerData.filter(item => item.tpNo && item.tpNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(customerData);
      }
    } else if (searchBy === 'mobileNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(customerData.filter(item => item.mobileNo && item.mobileNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(customerData);
      }
    } else if (searchBy === 'email') {
      setFilteredItems(customerData.filter(item =>
        item.email && item.email.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else {
      //setFilteredItems(customers.filter((item) => item.sellerName === 'SYSTEM' && item.userType === 'Partner'));
      setFilteredItems(customerData);
    }
  }, [searchBy, filterText, customerData])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      
      <Col md='12' >
        <Row className='bg-blue p-2 mb-2'  >
          <Col md='3' className='mb-1 divider-right' >
            <Form.Group>
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option disabled value=''>Filter By...</option>
                {FilterByUsers.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md='3' className='mb-1 divider-right'>
            <Form.Group>
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setSearchBy(e.target.value)}
                value={searchBy}
              >
                <option disabled value=''>Search By...</option>
                {TablesFilterCU.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md='4' className='mb-1'>
            <Row>
            <Col xs='10' md='10' className='me-0 pe-0'>
                <Form.Control
                  id="search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search Input"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
              </Col>
              <Col xs='2' md='2' className='ms-0 ps-2'>
                <Button type="button" onClick={handleClear}>
                  X
                </Button>
              </Col>
            </Row>
          </Col>
          {createCustAccess && (
            <Col md='2' className='d-flex justify-content-end mb-1'>
              <Button onClick={() => navigate('/new-user', { state: ["customer"] })} color='primary'>Add New Customer</Button>
            </Col>
          )}
        </Row>

      </Col>
      

    );
  }, [filterText, resetPaginationToggle, createCustAccess, navigate, searchBy, type]);

  return (
    <>
      <ConfirmDialog
        open={openEdit}
        msg={editConfirm}
        setOpen={setOpenEdit}
        onYes={() => handleEdit(customerId)}
      />
      <ConfirmDialog
        open={openDelete}
        msg={deleteConfirm}
        setOpen={setOpenDelete}
        onYes={() => handleDelete(customerId)}
      />
      <TransferPopup
        open={openTransfer}
        onTransferComplete={handleTransferComplete}
        setOpen={setOpenTransfer}
        customerId={customerId}
        fromId={sellerId}
        transferFrom={sellerName}
        setTransferFrom={setSellerName}
        customer={customerName}
        setCustomer={setCustomerName}
        fetch={() => fetchCustomers(type, transferCustomerId)}
      />


      
      <SnackbarAlerts
        open={openAlert}
        setOpen={setOpenAlert}
        msg={alertMsg}
        severity={severity}
      />
      <DataTable
        noDataComponent={null}
        //pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        responsive
        customStyles={customStyles}
        
        
      />

      <div style={{ overflowY: 'auto' }}>
        <DataTable
          pagination
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle}
          //subHeaderComponent={subHeaderComponentMemo}
          //  persistTableHead
          responsive
          customStyles={customStyles}
        />
      </div>
    </>
  );
}

export default CustomerTable;