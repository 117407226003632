 // key types
  export const KeyTypes = [
    
    {value:'Permanent' , select: 'Permanent'},
    {value:'Temporary' , select: 'Temporary'},
    {value:'Perpetual' , select: 'Perpetual'},
    
    
  ];

  export const KeyTypeForManage = [
    
    {value:'Permanent' , select: 'Permanent'},
    {value:'Temporary' , select: 'Temporary'},
    {value:'Perpetual' , select: 'Perpetual'},
    { value: 'all', select: '[All]' },
    
    
  ];

   // key types
   export const keyStatus = [
    
    {value:'true' , select: 'Generated'},
    {value:'false' , select: 'Assigned'},
    { value: 'all', select: '[All]' },
    
  ];

// days for temporary key generation
export const DaysOptions = [
  {value:'0' , select: 0},
  {value:'5' , select: 5},
  {value:'10' , select: 10},
  {value:'15' , select: 15},
  {value:'20' , select: 20},
  {value:'25' , select: 25},
  {value:'30' , select: 30},
];

// years for permanent key generation
export const YearsOptions = [
  {value:'0' , select: 0},
  {value:'1' , select: 1},
  {value:'2' , select: 2},
  {value:'3' , select: 3},
  {value:'4' , select: 4},
  {value:'5' , select: 5},
];

//data for user access controller
export const UserAccess = [
  {
    userCreateType : 'USER_CREATE_TYPE_',
    adminTools : 'ADMIN_TOOLS'
  }
];

//search options in key view
export const KeyViewSearch = [
  
  {value:'uuid' , select: 'UUID'},
  {value:'customerName' , select: 'Customer'},
  /* {value:'months' , select: 'Months'}, */
  {value:'days' , select: 'Days'},
  {value:'userCount' , select: 'User Count'},
  
 {value:'sellerName' , select: 'Seller Name'}, 
  /*{value:'isGenerated' , select: 'Status'}, */
];

//Key view filter options
export const FilterByUsers = [
  {value:'0' , select: 'User'},
  {value:'1' , select: 'Members'},
  {value:'2' , select: '[All]'},
  
]

export const FilterByKeys = [
  
  {value:'1' , select: 'Extended'},
  
]

export const FilterDisabledKeys = [
  
    {value:'0' , select: 'Disabled'},
    
 
]

//Drag and drop
export const UserAccessData = [
  {id: 1, value:'uuid' , select: 'UUID'},
  {id: 2, value:'customerName' , select: 'Customer'},
  /* {id: 3, value:'months' , select: 'Months'}, */
  {id: 3, value:'days' , select: 'Days'},
  {id: 4, value:'userCount' , select: 'User Count'},
  {id: 5, value:'sellerId' , select: 'Seller ID'},
]

//filter data for partner and customer tables
export const TablesFilterCU = [
  {value:'fname' , select: 'First Name'},
  {value:'lname' , select: 'Last Name'},
  {value:'vatNo' , select: 'Vat No'},
  {value:'countryName' , select: 'Country'},
  {value:'tpNo' , select: 'Telephone'},
  {value:'mobileNo' , select: 'Mobile'},
  {value:'email' , select: 'Email'},
]



export const TablesFilterSeller = [
  {value:'fname' , select: 'First Name'},
  {value:'lname' , select: 'Last Name'},
  {value:'vatNo' , select: 'Vat No'},
  {value:'tpNo' , select: 'Telephone'},
  {value:'mobileNo' , select: 'Mobile'},
  {value:'email' , select: 'Email'},
]

//Key view filter options
export const Status = [
  {value:true , select: 'Active'},
  {value:false , select: 'De-active'},
]

export const Priority = [
  {value:1 , select: '1'},
  {value:10 , select: '10'},
  {value:20, select: '20'},
  {value:30, select: '30'},
]


//filter data for partner and customer tables
export const ErrorMsgs = [
  { noResponse:'No Server Response!', 
  fetchError: 'Internal Server Error Occurred!',
  loginError: 'You do not have permission to log in',
  customNoResponse: "Data is not fetching correctly!"
}
   //removed noResponse:'No Server Response!',
]

//filter data for partner and customer tables
export const FakeData = [
  {value:true , select: 'First Name'},
  {value:false , select: 'Last Name'},
  {value:'vatNo' , select: 'Vat No'},
  {value:'countryName' , select: 'Country'},
  {value:'tpNo' , select: 'Telephone'},
  {value:'mobileNo' , select: 'Mobile'},
  {value:'email' , select: 'Email'},
  {value:'tpNo' , select: 'Telephone'},
  {value:'mobileNo' , select: 'Mobile'},
  {value:'email' , select: 'Email'},
  {value:'email' , select: 'Email'},
  {value:'tpNo' , select: 'Telephone'},
  {value:'mobileNo' , select: 'Mobile'},
  {value:'email' , select: 'Email'},
  {value:'email' , select: 'Email'},
]

//Key view filter options
export const FilterUsers = [
  {value:'0' , select: 'User'},
  {value:'1' , select: 'Members'},
]

export const Variables = {
  aMonth : 31,
  considerAMonth : 14,
  maxUserCount : 50,
  minMonth : '1',
  minDays : 5,
}


