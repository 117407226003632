import './UserAccess.css'
import React, {useState, useEffect, useContext} from 'react'
import { MDBRow, MDBCol, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SnackbarAlerts from "../SnackbarAlerts";
import DataContext from '../../context/DataContext';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeAheadClear from '../TypeAheadClear';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from '../../api/axios'
import {ErrorMsgs} from '../../Data/Data'
import { enqueueSnackbar } from 'notistack';

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const USER_GROUP_URL = process.env.REACT_APP_API_USER_GROUP_FIND_ALL; 
const ASSIGNED_GROUPS = process.env.REACT_APP_API_USER_IN_GROUPS_FIND_USER_IN_GROUPS;
const ASSIGN_GROUP = process.env.REACT_APP_API_USER_IN_GROUPS_ASSIGN_USER_TO_GROUP;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

//Moves an item from one list to another list.
const move = (
   source,
   destination,
   droppableSource,
   droppableDestination
 ) => {
   const sourceClone = Array.from(source);
   const destClone = Array.from(destination);
   const [removed] = sourceClone.splice(droppableSource.index, 1);
 
   destClone.splice(droppableDestination.index, 0, removed);
 
   const result = {};
   result[droppableSource.droppableId] = sourceClone;
   result[droppableDestination.droppableId] = destClone;
 
   return result;
 };

const grid = 3;
  
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: 8,
  borderStyle:'solid',
  borderWidth: 1,
  borderColor: '#4fc3f7',

  // change background colour if dragging
  background: isDragging ? '#e0f7fa' : '#b3e5fc',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#e0f2f1' : '',
  padding: grid,
  width: 550,
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: '#4fc3f7',
});


const UserAccess = () => {

  let variant

  const [open, setOpen] = useState(false);
  const [Msg, setMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [buttonDisable, setButtonDisable] = useState(true);
  const {isEmptyArray,  userType, setUserType} = useContext(DataContext)

  const [userOptions, setUserOptioins] = useState([]);
  const [user, setUser] = useState([])
  const [userFocus, setUserFocus] = useState(false);
  const [noUser, setNoUser] = useState(false)
  const [userId, setUserId] = useState(0)
  const [type, setType] = useState(2)
  const [assignedUserGroups, setAssignedUserGroups] = useState([]);
  
  const [userGroups, setUserGroups] = useState([])
  const [items, setItems] = useState([]);
  const [itemsFirst, setItemsFirst] = useState([])
  const [ItemsAdd, setItemsAdd] = useState([])
  const [selected, setSelected] = useState([]);
  const [selectedFirst, setSelectedFirst] = useState([]);
  const [SelectedAdd, setSelectedAdd] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
 // const [status, setStatus] = useState(1) --never used

  const fetchUsers = async (type) => {
    try {
      const response = await axios.get(USER_URL+ `/${type}`);
      const status= response.data.status;
      const result = response.data.result;
      const msg = response.data.msg
      //console.log("user ",result)
      if(status){
        setUserOptioins(result);
        setSelected([]);
        setSelectedFirst([])
      }else{
        setUserOptioins([]);
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

  const fetchGroups = async () => {
    try {
      const response = await axios.get(USER_GROUP_URL + `/1`);
      const status= response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if(status && result){

        setUserGroups(result.map(item => ({
          ...item,
          id: String(item.id)
        })));

      }else{
        setUserGroups([]);
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

  const fetchAssignedGroups = async (userId) => {
    try {
      const response = await axios.get(ASSIGNED_GROUPS + `${userId}`);
      const status= response.data.status;
      const result = response.data.result;
      const msg = response.data.msg
      //console.log('Res', response.data)
      if(status && result.length !== 0){
        
        let access = result.map(({userGroup}) => userGroup)
        let accessArr = access.map(item => ({
          ...item,
          id: String(item.id)
        }))
        .sort((a, b) => a.priority - b.priority); 
        setSelected(accessArr);
        setSelectedFirst(accessArr)
      }else{
        setSelected([]);
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

  useEffect(() => {
    fetchGroups();  
  }, [])

  useEffect(() => {
    fetchUsers(type);   
  }, [type])
  
  useEffect(() => {
    if(user.length !== 0){
      setUserId(user[0].id)

    }else{
      setNoUser(true)
    }   
  }, [user])

  useEffect(() => {  
    fetchAssignedGroups(userId) 
  }, [userId])
  
  useEffect(() => {
    //get id of selected user group
    if(user.length !== 0){
      setUserId(user[0].id)
      //remove items which are already on assigned access from system access      
      let new_arr = userGroups.filter(item1 => !selected.some(item2 => item2.id === item1.id));
      let arr = userGroups.filter(item1 => !selectedFirst.some(item2 => item2.id === item1.id));
      setItemsFirst(arr)
      setItems(new_arr)
    }else{
      setUserId(0)
      setNoUser(true)
    }  

    //turn selected item's ID to numbers
    if (selected.length !== 0){
      setSelectedId(selected.map(({ id }) => Number(id))); 
    }

  }, [userGroups, selected, selectedFirst, user])

  useEffect(() => {  
    let items_add
    let selected_add
    //add items which are not in itemsFirst to a ItemAdd array
    if(items.length !== 0){
      items_add = items.filter(item1 => !itemsFirst.some(item2 => item2.id === item1.id));
      setItemsAdd(items_add)
    }
    
    //add items which are not in selectedFirst to a selectAdd array
    if( selected.length !== 0){
      selected_add = selected.filter(item1 => !selectedFirst.some(item2 => item2.id === item1.id));
      setSelectedAdd(selected_add)
    }

  }, [items, selected, itemsFirst, selectedFirst])

  useEffect(() => {
    // Update the assigned user groups array
    setAssignedUserGroups(selected);

    // Check if there are any changes in the assigned groups
    const addedItems = selected.filter(item => !selectedFirst.some(firstItem => firstItem.id === item.id));
    const removedItems = selectedFirst.filter(item => !selected.some(selectedItem => selectedItem.id === item.id));

    // Determine if the user has multiple groups or just one
    const hasMultipleGroups = assignedUserGroups.length > 1;
    const hasOnlyOneGroup = assignedUserGroups.length === 1;

    // Determine if there are changes
    const hasChanges = addedItems.length > 0 || removedItems.length > 0;

    // Disable the save button if:
    // 1. There are no changes, and
    // 2. The user has only one group and is trying to remove it
    // 3. Assigned User Groups list is empty
    setButtonDisable(!(hasChanges || (hasMultipleGroups || !hasOnlyOneGroup)) || assignedUserGroups.length === 0);
}, [selected, selectedFirst, assignedUserGroups]);


  useEffect(() => {    
    if(user.length === 0){
      setItems([])
      setSelected([])
    }
  }, [user])

  //check whether customer is empty for validation
  const validateUser = () => {
    if (noUser){
      return true
    }else{
      return false
    }
  } 

  //save method
  const handleSave = async() => {
    setButtonDisable(true)
    const newData = {id: userId, idList: selectedId}
    try {
      const response = await axios.post(ASSIGN_GROUP, newData);
      const status= response.data.status;
      const msg = response.data.msg
      
      if(status){
        setItems([])
        setSelected([])
        setUser([])
        
        variant = 'success'
        enqueueSnackbar('Changes Saved Successfully!', {variant})
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  } 

  //console.log("typr ", userType)

  const id2List = {
    droppable: 'items',
    droppable2: 'selected',
    };
      
  const getList = (id) => {
  return id2List[id] === 'items' ? items : selected;
  };
      
  const onDragEnd = (result) => {
    const { source, destination } = result;
    
    // dropped outside the list
    if (!destination) {
        return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppable2') {
        setSelected(items);
      } else {
        setItems(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setItems(result.droppable);
      setSelected(result.droppable2);
    }

};

  return (
    <>       
    <SnackbarAlerts 
      open={open} 
      setOpen={setOpen} 
      msg={Msg} 
      severity={severity}
    />
    <MDBCard className='card-table'>
      <MDBCardBody className='p-0'>
        <MDBRow className='bg-blue p-3 mb-2'>
          <MDBCol md={12}>
            <MDBRow>
              <MDBCol md={12}>
                <Form.Group className='mb-3' >
                  <Form.Label className='white-lable' size='sm'>Select User</Form.Label>    
                  <span className="mandatory"> *</span>                   
                  <Typeahead 
                    id="customersId" 
                    labelKey={(userOptions)=> `${userOptions.fname} ${userOptions.lname}`}
                    onChange={(selected) => setUser(selected)}
                    options={userOptions}
                    placeholder="User ..."
                    selected={user} 
                    value={user}
                    inputProps={{ required: true }}
                    onFocus={() => setUserFocus(true)}
                    onBlur={validateUser}
                    style={{display: 'flex'}}
                  ><TypeAheadClear select={user} setSelect={setUser}/></Typeahead>              
                    <p className={user.length === 0 && userFocus ? "validate" : "offscreen"}>
                      Select a valid User.<br />
                    </p>            
                </Form.Group> 
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className='bg-white p-3 h-80'>
          <MDBCol md={12} >
            <DragDropContext onDragEnd={onDragEnd}>
              <div style={{ display: 'flex' }}>
                <MDBRow className='m-1 p-1'>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                      <div className='heading' >User Groups</div>
                        {items.map((item, index) => (
                          <Draggable 
                            key={item.id} 
                            draggableId={item.id} 
                            index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  >                                    
                                  <MDBRow 
                                    className={(ItemsAdd.some(newItemObj => newItemObj.id === item.id)) 
                                    ? 'location-access m-0 p-0 center' :'location m-0 p-0 center'}
                                  >
                                    {item.group}  
                                  </MDBRow>
                                </div>
                              )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      </div>
                      )}
                    </Droppable>
                  </MDBRow>
                  <MDBRow className='m-1 p-1'>
                    <Droppable droppableId="droppable2">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                        <div className='heading' >Assigned User Groups</div>
                          {selected.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <MDBRow 
                                  className={(SelectedAdd.some(newItemObj => newItemObj.id === item.id)) 
                                  ? 'location-assigned m-0 p-0 center' :'location m-0 p-0 center'}
                                >
                                  {item.group}  
                                </MDBRow>
                              </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                    )}
                  </Droppable>
                </MDBRow>
              </div>
            </DragDropContext>
            <div className='m-3 mb-0 d-flex justify-content-end'>
              <Button
                disabled = {buttonDisable}
                onClick={() => handleSave()}
              >
                Save
              </Button>   
            </div>        
          </MDBCol> 
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
    </>
  )
}

export default UserAccess