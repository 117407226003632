import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const AUTH_RENEW = process.env.REACT_APP_API_AUTH_RENEW;

export const axiosNoAuth = axios.create({
    baseURL
});

axios.interceptors.request.use(async config => {
    let userToken = sessionStorage.getItem('token');
    if (config.url !== '/' ) {
        config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(async response => {
    return response;
}, async error => {

    let userToken = sessionStorage.getItem('token');
    let refresh_token = sessionStorage.getItem('refresh-Token');
    const errorResponse = error.response.status
    
    const originalRequest = error.config;
    if (errorResponse === 403 && !originalRequest._retry ) {
        originalRequest._retry = true;
        try {
            const response = await axiosNoAuth.post(AUTH_RENEW, { refreshToken: refresh_token });
            const result = response.data.result
            userToken = result.token
            refresh_token = result.refreshToken
            sessionStorage.setItem('token', result.token);
            sessionStorage.setItem('refresh-Token', result.refreshToken);
        } catch (err) {
            console.log('catch error in 403 in auth renew - 401')            
            sessionStorage.setItem('err', true);
            
            
        }
        // Update the Authorization header with the new token
        axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
        return await axios(originalRequest);
    }else if (errorResponse === 401){
        sessionStorage.setItem('err', true);
    }
    return Promise.reject(error);
});

axios.defaults.baseURL = baseURL;
export default axios;



