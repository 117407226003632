import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from "../ConfirmDialog";
import SnackbarAlerts from "../SnackbarAlerts";
import AddKeyPopup from "./AddKeyPopup";
import { TablesFilterCU, FilterByUsers } from "../../Data/Data"
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { RiSettings3Line } from 'react-icons/ri';
import View from '../KeyGenerate/View';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import "./UserTable.css";

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const USER_DELETE = process.env.REACT_APP_API_USER_DELETE;
//const SELLER_LIST = process.env.REACT_APP_API_SELLER_ID;//Seller ID
//const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
//const USER_ACCESS_BY_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;

const UserTable = () => {

  let variant

  const location = useLocation();
  const navigate = useNavigate()
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this user?"
  const [openDelete, setOpenDelete] = useState(false)
 // const [openView, setOpenView] = useState(false)
 // const [sellers, setSellers] = useState([]);
  const deleteConfirm = "Are you sure you want to delete this user?"

  const { setActionView,  actionView, nameSearch, setSellerSearch, setUserSearch, activeKeyGenIdSeller, setActiveKeyGenIdSeller, setDisabledKeyGenIdSeller, disabledKeyGenIdSeller, userType, setDataId } = useContext(DataContext)
  const { addKeyAccess, partnerAccess, hasCreatePartnerPermission, extendKeyAccess, setHasCreatePartnerPermission, isKeyDeleted , setIsKeyDeleted } = useContext(AuthProvider)
  const { setValue , value} = useContext(KeyGenData)

  const [severity, setServerity] = useState('success')
  const [openAddKey, setOpenAddKey] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [type, setType] = useState(0);
  //const [sentRequest, setSendRequest] = useState(false)

  const columns = [
    {
      name: 'First Name',
      selector: row => row.fname,
      wrap: true,
      width: '120px',
    },
    {
      name: 'Last Name',
      selector: row => row.lname,
      wrap: true,
      width: '120px',
    },
    {
      name: 'Vat No',
      selector: row => row.vatNo,
      wrap: true,
    },
    {
      name: 'Date of Birth',
      selector: row => row.dob,
    },
    {
      name: 'Company Name',
      selector: row => row.companyName,
      wrap: true,
      width: '100px',
    },
    {
      name: 'Status',
      selector: row => row.status === true ? 'Active' : 'Deactive',
      width: '70px',
    },
    {
      name: 'Address',
      cell: row => {
        const formattedAddress = row.address.replace(/,/g, '');
        return <div>{formattedAddress}</div>;
      },
      wrap: true,
      width: '250px',
    },
    {
      name: 'Country',
      selector: row => row.countryName,
      wrap: true,
    },
    {
      name: 'Telephone',
      selector: row => row.tpNo,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Mobile',
      selector: row => row.mobileNo,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      wrap: true,
      width: '200px',
    },
    {
      name: 'User Type',
      selector: row => row.userType,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DropdownButton id="dropdown-basic-button"
            title={<RiSettings3Line />}
          >
            {addKeyAccess && (
              <Dropdown.Item
                onClick={() => addKeyClick(row.id, row.fname, row.lname)}
                className={row.status === true ? 'success' : 'hide'}>
                Wallet
              </Dropdown.Item>
            )}
            <Dropdown.Item className="view"
              variant="success"
              onClick={() => handleViewKeysClick(row.id, row.userType)}>
              Generated Keys
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => actionClick(row.id, 'edit')}
              className="edit">
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => actionClick(row.id, 'delete')}
              className="delete" >
              Remove
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'

      },
    },
  };

  //set userId openEdit openDelete true base on action
  const actionClick = (id, action) => {
    if (action === 'edit') {
      setOpenEdit(true);
    } else if (action === 'delete') {
      setOpenDelete(true);
    }

    setUserId(id);
  }
 
  //console.log("Add new Partner", createPartnerAccess);

  
  

  const handleViewKeysClick = (sellerID) => {
    const newActionView = extendKeyAccess ? 3 : 2;
  
    // Set the actionView directly
    setActionView(newActionView);
    
    //console.log('Action View (before navigation):', newActionView);
  
    // Navigate to the "license-keys" page
    navigate('/license-keys');
  
    // Set the user ID for which keys need to be viewed
    setSellerSearch(sellerID);
    setActiveKeyGenIdSeller(activeKeyGenIdSeller);
    setDisabledKeyGenIdSeller(disabledKeyGenIdSeller);
    setIsKeyDeleted(isKeyDeleted);
    localStorage.setItem('lastActiveTab', '3');
    setValue(extendKeyAccess ? 3 : 2);
    

    // Log the actionView after navigation
   // console.log('Action View (after navigation):', newActionView);
  };
  

  const addKeyClick = (id, Fname, Lname) => {
    const space = (' ');
    setOpenAddKey(true);
    setCustomerName(Fname + space + Lname);
    setUserId(id);
  }

  const fetchUsers = async (type) => {
    try {
      const response = await axios.get(USER_URL + `/${type}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if (status) {
        if (result) {
          setUsers(result);
          setHasCreatePartnerPermission(true)
        } else {
          setUsers(result);
          setHasCreatePartnerPermission(false)
        }
        //console.log('result :' , result);
      } else {
        setUsers([]);
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  const handleDelete = (id) => {
    axios.delete(USER_DELETE + `${id}`)
      .then(response => {
        const msg = response.data.msg
        if (response.status) {
          setOpenDelete(false);
          variant = 'success'
          enqueueSnackbar('Successfully Deleted!', { variant })
          fetchUsers(type);
        } else {
          setOpenDelete(false)
          variant = 'error'
          enqueueSnackbar(msg, { variant })
        }
      })
      .catch(err => {
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
        }
      });
  };

  const handleEdit = (id) => {
    navigate('/new-user', { state: ["user", id] })
  };
  useEffect(() => {
    fetchUsers(type);
  }, [type])

  const userData = useMemo(() => [...users], [users]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  useEffect(() => {
    //filter data for search
    if (searchBy === 'fname') {
      setFilteredItems(userData.filter(item =>
        item.fname && item.fname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'lname') {
      setFilteredItems(userData.filter(item =>
        item.lname && item.lname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'vatNo') {
      setFilteredItems(userData.filter(item =>
        item.vatNo && item.vatNo.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'countryName') {
      setFilteredItems(userData.filter(item =>
        item.countryName && item.countryName.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'tpNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => item.tpNo && item.tpNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(userData);
      }
    } else if (searchBy === 'mobileNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => item.mobileNo && item.mobileNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(userData);
      }
    } else if (searchBy === 'email') {
      setFilteredItems(userData.filter(item =>
        item.email && item.email.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'id') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => (item.id) === filterNumber));
      } else {
        setFilteredItems(userData);
      }
    }
    else {
      setFilteredItems(userData);
    }
  }, [searchBy, filterText, userData])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <MDBCol md='12'>
        <MDBRow className='bg-blue p-3 mb-2'>
          <MDBCol md='3' className='mb-1 divider-right'>
            <Form.Group >
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option disabled value=''>Filter By...</option>
                {FilterByUsers.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </MDBCol>
          <MDBCol md='3' className='mb-1 divider-right'>
            <Form.Group >
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setSearchBy(e.target.value)}
                value={searchBy}
              >
                <option disabled value=''>Search By...</option>
                {TablesFilterCU.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </MDBCol>
          <MDBCol md='4' className='mb-1'>
          <MDBRow>
          <div className='d-flex align-items-center'>
            <MDBCol xs='10' md='10' className='me-0 pe-0'>
                <Form.Control
                  id="search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search Input"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
              </MDBCol>
              <MDBCol xs='2' md='2' className='ms-0 ps-2 align-self-center'>
                <Button id="search-clear-button" type="button" onClick={handleClear}>
                  X
                </Button>
              </MDBCol>
            </div>
            </MDBRow>
          </MDBCol>
          {partnerAccess && (
            <MDBCol md='2' className='d-flex justify-content-end mb-1'>
              <Button
                onClick={() => navigate('/new-user', { state: ["user"] })}
                color='primary' >
                Add New Partner
              </Button>
            </MDBCol>
          )}
        </MDBRow>
      </MDBCol>
    );
  }, [filterText, resetPaginationToggle, navigate, partnerAccess, searchBy, type]);

  return (
    <>
      <ConfirmDialog
        open={openEdit}
        msg={editConfirm}
        setOpen={setOpenEdit}
        onYes={() => handleEdit(userId)}
      />
      <ConfirmDialog
        open={openDelete}
        msg={deleteConfirm}
        setOpen={setOpenDelete}
        onYes={() => handleDelete(userId)}
      />
      <SnackbarAlerts
        open={openAlert}
        setOpen={setOpenAlert}
        msg={alertMsg}
        severity={severity}
      />
      <AddKeyPopup
        open={openAddKey}
        setOpen={setOpenAddKey}
        customerName={customerName}
        id={userId}
      />
      <DataTable
        /* title="Users" */
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        responsive
        customStyles={customStyles}
      />
    </>
  );
}

export default UserTable