import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "../../api/axios";
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, Variables } from '../../Data/Data'; //Variables never used
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger'; --never used
//import { Tooltip } from 'react-bootstrap'; --never used
import ConfirmDialog from "../ConfirmDialog";
import EditUUIDPopup from "./EditUUIDPopup";

const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const GENERATE_DE_ACTIVE = process.env.REACT_APP_API_KEY_DISABLE_GENERATED_KEY;
const KEY_LIST = process.env.REACT_APP_API_GET_KEYS_LIST;

const deactivatekey = 'ACTIVE_KEY_DEACTIVATE_Administrator';
const updateuuid = 'ACTIVE_KEY_CHANGE_UUID_Administrator';

const customStyles = {
  rows: {
    style: {
      minHeight: '60px', // override the row height
    },
  },
  headCells: {
    style: {
      borderStyle: 'solid',
      borderColor: '#40c4ff',
      borderWidth: '1px',
      borderCollapse: 'collapse',
      fontWeight: 'bold',
      padding: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#b2ebf2',
    },
  },
  cells: {
    style: {
      overFlowWrap: 'break-word',
      borderStyle: 'solid',
      borderColor: '#40c4ff',
      borderWidth: '1px',
      borderCollapse: 'collapse',
      padding: '5px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};

const KeyDetailsTable = ({ data }) => {

  let variant

  const { setUuidEdit, uuidEdit, setKeyGenId, uuidEdited } = useContext(KeyGenData) //setValue, setUuidEdited
  const { adminAccess, setAdminAccess } = useContext(AuthProvider) //uuidSearch, partnerAccess, setUuidSearch, userSearch, setUserSearch,setPartnerAccess, sellerSearch, setSellerSearch, actionView, setActionView

  const [openDeActiveG, setOpenDeActiveG] = useState(false);
  const [openEditUUID, setOpenEditUUID] = useState(false);
  const [fetchDeactivateAccessPermission, setfetchDeactivateAccessPermission] = useState(true);
  const [fetchUpdateSuccess, setFetchUpdateSuccess] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [val, setVal] = useState('0')
  // const [isAnyKeyActive, setIsKeyActive] = useState(false);
  //const [activeKeys, setActiveKeys] = useState([]); --never used
  //const [disabledKeys, setDisabledKeys] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openEdit, setOpenEdit] = useState(false)
  //const [disabledKeyGenIds, setDisabledKeyGenIds] = useState(""); --never used
  //const [activeKeyGenIds, setActiveKeyGenIds] = useState(""); --never used

  const deleteConfirm = "Are you sure you want to de-active this key?"
  const columns = React.useMemo(
    () => [
      {
        name: 'UUID',
        selector: row => row['uuid'],
        wrap: true,

      },
      {
        name: 'Customer',
        selector: row => row['customerName'],
        wrap: true,

      },
      {
        name: 'Created On',
        selector: row => row['createdDate'],

      },
      {
        name: 'Valid From',
        selector: row => row['validFrom'],
      },
      {
        name: 'Valid To',
        selector: row => row['validTo'],
      },
      {
        name: 'Key Type',
        selector: row => {
          switch (row.keyType) {
            case 'PERMANENT':
              return 'Permanent';
            case 'TEMPORARY':
              return 'Temporary';
            case 'PERPETUAL':
              return 'Perpetual';
            default:
              return 'Unknown';
          }
        },
        wrap: true,
        width: '85px',
      },
      {
        name: 'Days',
        selector: row => row['days'],
        width: '85px',
      },
      {
        name: 'User Count',
        selector: row => row['userCount'],
        width: '85px',
      },
      {
        name: 'Description',
        selector: row => row['description'],
        wrap: true,

      },
      {
        name: 'Seller Name',
        selector: row => row['sellerName'],
        width: '85px',

      },

      {
        name: "Actions",
        button: true,
        // width: '170px',
        cell: (row) => (
          <div className="menu">
            {
              (
                <DropdownButton
                  id="dropdown-basic-button"
                  title=""
                  className="menu" >
                  {(
                    <Dropdown.Item onClick={() => actionDeActiveKey(row.uuid, row.keyGenId)} className="delete"
                    >
                      De-active
                    </Dropdown.Item>
                  )}
                  {(
                    <Dropdown.Item onClick={() => editUUIDKey(row.uuid)} className="primary"
                    >
                      Change UUID
                    </Dropdown.Item>
                  )}

                </DropdownButton>
              )}
          </div>
        ),
      }

    ],
    []
  );

  const fetchKeys = async (val, type) => {
    let result;
    try {
      const response = await axios.get(KEY_LIST + `/${val}/${type}/0`);
      const status = response.data.status;
      result = response.data.result;
      const msg = response.data.msg;

      if (status) {
        // Check if result is an array and not empty
        if (Array.isArray(result) && result.length > 0) {
          setKeyList(result);
        } else {
          setKeyList([]);
        }
        //console.log("keys", response.data.result);
      } else {
        setKeyList([]);
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        console.log("error");
      }
    }
    // Always return the result, even if it's an empty array or undefined
    return result || [];
  };

  const fetchDeactivateKeyAccess = async () => {
    try {
      const response = await axios.get(ACCESS_BY_ACCESS + `${deactivatekey}`);
      const data = response.data;
      setAdminAccess(data.result);
      if (data.result !== undefined && data.result.length > 0) {
        setfetchDeactivateAccessPermission(true);
        //console.log("After fetching Deactivate Key Access, fetchDeactivateAccessPermission:", fetchDeactivateAccessPermission);
      } else {
        // Introduce a delay of 2000ms and then set to false
        setfetchDeactivateAccessPermission(false);
      }
    } catch (err) {
      setfetchDeactivateAccessPermission(false);
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response.status !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };

  //console.log("After fetching Deactivate Key Access, fetchDeactivateAccessPermission:", fetchDeactivateAccessPermission);

  const fetchUpdateAccess = async () => {
    try {
      // const combinedPermissions = permissions.join('/');
      const response = await axios.get(ACCESS_BY_ACCESS + `${updateuuid}`);
      const data = response.data;
      setAdminAccess(data.result);
      if (data.result !== undefined && data.result.length > 0) {
        setFetchUpdateSuccess(true);
        //console.log("update ", fetchUpdateSuccess)
      } else {
        setFetchUpdateSuccess(false);
      }
    } catch (err) {
      setFetchUpdateSuccess(false);
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response.status !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };

  useEffect(() => {
    fetchUpdateAccess(['updateuuid']);
    fetchDeactivateKeyAccess(['deactivatekey']);
  }, []);


  const actionDeActiveKey = (uuid) => {
    setUuidEdit(uuid);
    // Update the keyList state to set isDisabled property
    const updatedKeyList = keyList.map((key) => {
      if (key.uuid === uuid) {
        return { ...key, isDisabled: true }; // Create a new object with isDisabled updated
      }
      return key; // Return other keys unchanged
    });
    setKeyList(updatedKeyList);
    setOpenDeActiveG(true);

  };
  //console.log('Data', data)

  //Open Edit UUID Button
  const editUUIDKey = (uuid) => {
    setUuidEdit(uuid);
    setOpenEditUUID(true);
    setShowTable(true)

  }

  const handleDeActiveG = async (e) => {
    setOpenDeActiveG(false)
    const newData = { uuid: uuidEdit }
    try {
      const response = await axios.post(GENERATE_DE_ACTIVE, newData);
      setUuidEdit('')
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        variant = 'success'
        enqueueSnackbar('Key De-activated Successfully!', { variant })
        setShowTable(false);
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  //console.log('Data Length:', data.length);

  return (
    <>
      <EditUUIDPopup
        open={openEditUUID}
        setOpen={setOpenEditUUID}
        fetch={async () => {
          await fetchKeys(val, 1);
          await fetchKeys(val, 0);
        }} />

      <ConfirmDialog
        open={openDeActiveG}
        msg={deleteConfirm}
        setOpen={setOpenDeActiveG}
        onYes={() => handleDeActiveG()} />
        {/* showTable && !uuidEdited && */}

      { showTable && data.length > 0 ? (
        <DataTable
          columns={columns}
          data={data}
          customStyles={customStyles}
          noHeader
          highlightOnHover
        />
      ) : (
        <p>Data not found</p>
      )}
    </>
  );
};

export default KeyDetailsTable;
