/* import Reports from './Reports';
import ReSellerReport from './ReSellerReport';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';

import './Report.css';
import AuthProvider from '../../context/AuthProvider';
import DistributorReport from './DistributorReport';
import DistributorFullReport from './DistributorFullReport';
import KeyActivationReport from './KeyActivationReport';  // Ensure this import is correct

function TabPanel(props) {
  const { children, value, index, menuValue, ...other } = props;
  const [tabHeight, setTabHeight] = useState('auto');

  useEffect(() => {
    if (menuValue === 1 || menuValue === 2) {
      setTabHeight('908px');
    } else {
      setTabHeight('auto');
    }
  }, [menuValue]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: tabHeight }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  menuValue: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { menuValue, setMenuValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);
  const { extendKeyAccess, manageKey, reSellerReport, distributorReport, sellerReports, DistributorFullports , ActivationReports } = useContext(AuthProvider);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial load

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    localStorage.setItem('lastActiveTab', newValue.toString());
    setMenuValue(newValue);
  };

  useEffect(() => {
    // Determine initial tab based on permissions
    const setInitialMenuValue = () => {
      if (sellerReports) {
        setMenuValue(0);
      } else if (reSellerReport) {
        setMenuValue(0);
      } else if (distributorReport) {
        setMenuValue(0);
      }
    };

    setInitialMenuValue();
  }, [sellerReports, reSellerReport, distributorReport, setMenuValue]);

  const renderTabs = () => {
    let tabIndex = 0;
    const tabs = [];
  
    if (sellerReports) {
      tabs.push(
        <Tab
          key="sellerReports"
          component={Col}
          label="Seller Keys Report"
          {...a11yProps(tabIndex)}
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }
  
    if (reSellerReport) {
      tabs.push(
        <Tab
          key="reSellerReport"
          component={Col}
          label="Re-Seller Stock Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }
  
    if (distributorReport) {
      tabs.push(
        <Tab
          key="distributorReport"
          component={Col}
          label="Distributor Stock Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    tabs.push(
      <Tab
        key="distributorFullReport"
        component={Col}
        label="Distributor Full Report"
        {...a11yProps(tabIndex)}
        className="left-aligned-tab"
        sx={{
          '&.MuiTab-root': {
            width: '100%',
            paddingTop: '25px',
            backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
          },
        }}
      />
    );
    tabIndex++;

    // Add KeyActivationReport tab
    tabs.push(
      <Tab
        key="keyActivationReport"
        component={Col}
        label="Key Activation Report"
        {...a11yProps(tabIndex)}
        className="left-aligned-tab"
        sx={{
          '&.MuiTab-root': {
            width: '100%',
            paddingTop: '25px',
            backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
          },
        }}
      />
    );

    return tabs;
  };
  
  const renderTabPanels = () => {
    const panels = [];
    let panelIndex = 0;

    if (sellerReports) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="sellerReportsPanel">
          <Reports />
        </TabPanel>
      );
      panelIndex++;
    }

    if (reSellerReport) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="reSellerReportPanel">
          <div className='full-width'>
            <ReSellerReport />
          </div>
        </TabPanel>
      );
      panelIndex++;
    }

    if (distributorReport) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="distributorReportPanel">
          <div className='full-width'>
            <DistributorReport />
          </div>
        </TabPanel>
      );
      panelIndex++;
    }

    panels.push(
      <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="distributorFullReportPanel">
        <div className='full-width'>
          <DistributorFullReport />
        </div>
      </TabPanel>
    );
    panelIndex++;

    // Add KeyActivationReport panel
    panels.push(
      <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="keyActivationReportPanel">
        <div className='full-width'>
          <KeyActivationReport />
        </div>
      </TabPanel>
    );

    return panels;
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={isSmallScreen ? 2 : 1} className={isSmallScreen ? 'menu-col-small' : 'report-menu-col'}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={menuValue}
              onChange={handleChange}
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none', // Remove the vertical line
                },
                '& .MuiTabs-flexContainer': {
                  flexDirection: isSmallScreen ? 'row' : 'column',
                  alignItems: isSmallScreen ? 'center' : 'flex-start',
                  justifyContent: isSmallScreen ? 'space-between' : 'left',
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
                display: isSmallScreen ? 'flex' : 'block', // Show horizontally on small screens
              }}
            >
              {renderTabs()}
            </Tabs>
          </Box>
        </Col>
        <Col xs={12} md={isSmallScreen ? 12 : 10} className={isSmallScreen ? 'form-col-small' : 'form-col'}>
          {renderTabPanels()}
        </Col>
      </Row>
    </Container>
  );
}
 */

import Reports from './Reports';
import ReSellerReport from './ReSellerReport';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-bootstrap';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
import './Report.css';
import AuthProvider from '../../context/AuthProvider';
import DistributorReport from './DistributorReport';
import DistributorFullReport from './DistributorFullReport';
import KeyActivationReport from './KeyActivationReport';  // Ensure this import is correct

function TabPanel(props) {
  const { children, value, index, menuValue, ...other } = props;
  const [tabHeight, setTabHeight] = useState('auto');

  useEffect(() => {
    if (menuValue === 1 || menuValue === 2) {
      setTabHeight('908px');
    } else {
      setTabHeight('auto');
    }
  }, [menuValue]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: tabHeight }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  menuValue: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KeyGenerate() {
  const { menuValue, setMenuValue } = useContext(KeyGenData);
  const { actionView } = useContext(DataContext);
  const { extendKeyAccess, manageKey, reSellerReport, distributorReport, sellerReports, DistributorFullports, ActivationReports } = useContext(AuthProvider);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial load

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    localStorage.setItem('lastActiveTab', newValue.toString());
    setMenuValue(newValue);
  };

  useEffect(() => {
    // Determine initial tab based on permissions
    const setInitialMenuValue = () => {
      if (sellerReports) {
        setMenuValue(0);
      } else if (reSellerReport) {
        setMenuValue(0);
      } else if (distributorReport) {
        setMenuValue(0);
      } else if (DistributorFullports) {
        setMenuValue(0);
      } else if (ActivationReports) {
        setMenuValue(0);
      }
    };

    setInitialMenuValue();
  }, [sellerReports, reSellerReport, distributorReport, DistributorFullports, ActivationReports, setMenuValue]);

  const renderTabs = () => {
    let tabIndex = 0;
    const tabs = [];

    if (sellerReports) {
      tabs.push(
        <Tab
          key="sellerReports"
          component={Col}
          label="Seller Keys Report"
          {...a11yProps(tabIndex)}
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    if (reSellerReport) {
      tabs.push(
        <Tab
          key="reSellerReport"
          component={Col}
          label="Re-Seller Stock Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    if (distributorReport) {
      tabs.push(
        <Tab
          key="distributorReport"
          component={Col}
          label="Distributor Stock Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    if (DistributorFullports) {
      tabs.push(
        <Tab
          key="distributorFullReport"
          component={Col}
          label="Distributor Full Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    if (ActivationReports) {
      tabs.push(
        <Tab
          key="keyActivationReport"
          component={Col}
          label="Key Activation Report"
          {...a11yProps(tabIndex)}
          className="left-aligned-tab"
          sx={{
            '&.MuiTab-root': {
              width: '100%',
              paddingTop: '25px',
              backgroundColor: menuValue === tabIndex ? 'lightblue' : 'inherit',
            },
          }}
        />
      );
      tabIndex++;
    }

    return tabs;
  };

  const renderTabPanels = () => {
    const panels = [];
    let panelIndex = 0;

    if (sellerReports) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="sellerReportsPanel">
          <Reports />
        </TabPanel>
      );
      panelIndex++;
    }

    if (reSellerReport) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="reSellerReportPanel">
          <div className='full-width'>
            <ReSellerReport />
          </div>
        </TabPanel>
      );
      panelIndex++;
    }

    if (distributorReport) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="distributorReportPanel">
          <div className='full-width'>
            <DistributorReport />
          </div>
        </TabPanel>
      );
      panelIndex++;
    }

    if (DistributorFullports) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="distributorFullReportPanel">
          <div className='full-width'>
            <DistributorFullReport />
          </div>
        </TabPanel>
      );
      panelIndex++;
    }

    if (ActivationReports) {
      panels.push(
        <TabPanel value={menuValue} index={panelIndex} menuValue={menuValue} key="keyActivationReportPanel">
          <div className='full-width'>
            <KeyActivationReport />
          </div>
        </TabPanel>
      );
    }

    return panels;
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={isSmallScreen ? 2 : 1} className={isSmallScreen ? 'menu-col-small' : 'report-menu-col'}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={menuValue}
              onChange={handleChange}
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none', // Remove the vertical line
                },
                '& .MuiTabs-flexContainer': {
                  flexDirection: isSmallScreen ? 'row' : 'column',
                  alignItems: isSmallScreen ? 'center' : 'flex-start',
                  justifyContent: isSmallScreen ? 'space-between' : 'left',
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
                display: isSmallScreen ? 'flex' : 'block', // Show horizontally on small screens
              }}
            >
              {renderTabs()}
            </Tabs>
          </Box>
        </Col>
        <Col xs={12} md={isSmallScreen ? 12 : 10} className={isSmallScreen ? 'form-col-small' : 'form-col'}>
          {renderTabPanels()}
        </Col>
      </Row>
    </Container>
  );
}
