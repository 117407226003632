import React, {useState, useContext} from 'react'
import Form from 'react-bootstrap/Form';
import { MDBRow, MDBCol}from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import DataContext from '../context/DataContext';
import { useEffect } from 'react';

const SearchBar = (props) => {
    
  const [search, setSearch] = useState('');
  const {setFilteredData} = useContext(DataContext)

  const handleClear = () => {
      if (search){
          setSearch('');
      }
  }
  useEffect(() => {
    setFilteredData(props.data.filter((item) =>
    ((item.name).toLowerCase()).includes(search.toLowerCase())))
  },[search, props.data, setFilteredData])
  
  return (
    <div>
        <MDBRow className='mb-2'>              
            <MDBCol md='6' className='mb-1 mu-1'>
            <MDBRow>
              <MDBCol md='10' className='me-0 pe-0'>
                <Form.Control
                    id="search"
                    type="text"
                    placeholder="Search..."
                    aria-label="Search Input"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                </MDBCol>
                <MDBCol md='2' className='ms-0 ps-0'>
                <Button type="button"onClick={handleClear}>
                  X
                </Button>
              </MDBCol>
            </MDBRow>
            </MDBCol>                
          </MDBRow>
    </div>
  )
}

export default SearchBar