import React,{useState, useEffect} from 'react';
import { MDBRow, MDBCol}from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../../api/axios';
import { GrClose } from 'react-icons/gr';
import SnackbarAlerts from '../SnackbarAlerts';


const PW_RESET =process.env.REACT_APP_API_USER_PASSWORD_RESET ;
const PWD_REGEX = /^.{6,10}$/;

const EditPassword = (props) => {

    function useValidation(value, regex) {
        const [isValid, setIsValid] = useState(false);
      
        const validate = () => {
          setIsValid(regex.test(value));
        };
      
        return [isValid, validate];
    }

    const [password, setPassword] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [isValidPwd, validatePwd] = useValidation(password, PWD_REGEX)

    const [newPassword, setNewPassword] = useState('');
    const [validNewPwd, setValidNewPwd] = useState(false);
    const [newPwdFocus, setNewPwdFocus] = useState(false);
    const [isValidNewPwd, validateNewPwd] = useValidation(password, PWD_REGEX)

    const [confirmPassword, setConfirmPassword] = useState('');
    const [validConPwd, setValidConPwd] = useState(false);
    const [conPwdFocus, setConPwdFocus] = useState(false); 
    
    const [msg, setMsg] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [severity ,setServerity] = useState('success')
    const [status, setStatus] = useState(true)

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(password));
        setValidNewPwd(PWD_REGEX.test(newPassword));
        setValidConPwd(newPassword === confirmPassword);
    }, [password, newPassword, confirmPassword])


    useEffect(() => {
        if (!props.open) {
          // Clear password fields when the component is unmounted or when props.open changes to false
          setPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setPwdFocus(false);
          setNewPwdFocus(false);
          setConPwdFocus(false);
          setMsg('');
        }
      }, [props.open]);

    const validateConPwd = () => {
        if(validConPwd){
            return true;
        }else{
            return false;
        }
      }

    const handleReset = async() => {

        const newData = {currentP:password, newP:newPassword, confirmP:confirmPassword}
        try {
            const response = await axios.post(PW_RESET, newData);
            const status= response.data.status;
            const result = response.data.result;
            const message = response.data.msg;
            setStatus(status);
            if(status){
                setOpenAlert(true)
                setServerity('success')
                setAlertMsg('Password Reset Successful!');
                setPassword('');
                setNewPassword('');
                setConfirmPassword('')
                setPwdFocus(false);
                setConPwdFocus(false);
                setNewPwdFocus(false);
                setMsg('');                
                props.setOpen(false);
            }else{
              setMsg(message);
            }
          } catch (err) {
            setOpenAlert(true)
            setServerity('error')
            if (!err.response) {
                setAlertMsg('No Server Response!');
            }else if(err.response !== 403){
                setAlertMsg('Internal server error occurred while trying to fetch data!');  
            }
        }
    }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  }

  return (
    <div className={props.open ? 'glass-effect' : ''}>                
        <SnackbarAlerts 
            open={openAlert} 
            setOpen={setOpenAlert} 
            msg={alertMsg} 
            severity={severity}
        />
        <div className='pop-over-div'>
        <Popover
            className='pop-over'
            open={props.open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
            anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
        >
            <div className='popup-container p-5'>
            <div className='end-div p-0'>
                <GrClose onClick={() => props.setOpen(false)} />
            </div>
                <MDBRow>
                    <MDBCol>                        
                        <h5>Reset Password</h5>
                        <p className={status ? '' : 'error-msg'}>{msg}</p>
                        <Form className='pt-3'>                            
                            <Form.Group className='mb-3' >
                                <Form.Label size='sm'>Password</Form.Label> 
                                <span className="mandatory"> *</span>
                                <Form.Control
                                    type='password'
                                    id='passwordId'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    value={password}           
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={validatePwd}                          
                                />   
                                <p className={pwdFocus && !validPwd ? "validate" : "notVisible"}>                                    
                                    Password should contain 6 to 10 characters
                                </p>                  
                            </Form.Group>                            
                            <Form.Group className='mb-3' >
                                <Form.Label size='sm'>New Password</Form.Label> 
                                <span className="mandatory"> *</span>
                                <Form.Control
                                    type='password'
                                    id='newPasswordId'
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    value={newPassword} 
                                    onFocus={() => setNewPwdFocus(true)}
                                    onBlur={validateNewPwd}
                                />  
                                <p className={newPwdFocus &&  !validNewPwd ? "validate" : "notVisible"}>                                    
                                    Password should contain 6 to 10 characters
                                </p>                     
                            </Form.Group>                            
                            <Form.Group className='mb-3' >
                                <Form.Label size='sm'>Confirm Password</Form.Label> 
                                <span className="mandatory"> *</span>
                                <Form.Control
                                    type='password'
                                    id='confirmPasswordId'
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    value={confirmPassword} 
                                    onFocus={() => setConPwdFocus(true)}
                                    onBlur={validateConPwd}
                                />  
                                <p className={conPwdFocus &&  !validConPwd ? "validate" : "notVisible"}>                                    
                                    Does not match with new password.
                                </p>                     
                            </Form.Group> 
                            <MDBRow className='d-flex justify-content-end p-0 pt-3'>
                                <MDBCol md={6} className="d-flex justify-content-end">
                                    <Button 
                                        onClick={() => handleReset()}
                                        disabled={(!validPwd || !validNewPwd || !validConPwd) ? true : false}
                                    >
                                        Reset
                                    </Button>
                                </MDBCol>
                            </MDBRow>
                        </Form>
                    </MDBCol>
                </MDBRow> 
            </div>
        </Popover>
        </div>
    </div>
  );
}

export default EditPassword