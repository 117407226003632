/* import React, { useEffect, useState, useContext } from 'react';
import axios from '../../src/api/axios';
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../src/Data/Data'
import DataContext from '../../src/context/DataContext';

const ACCESS_BY_USER_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;
const USER_TYPE_URL =process.env.REACT_APP_API_USER_TYPE_FIND_ALL;

const useUserType = () => {
    let variant = 'sucecss'
    const {isEmptyArray, setUserType, userType} = useContext(DataContext)

    const [userTypeOptions, setUserTypeOptions] = useState([]);
    const [UserAccess, setUserAccess] = useState([]);
    const [allowedUserType, setAllowedUserTypes]=useState([]);
  
    useEffect(() => {
      const fetchAccessById = async () => {            
          try {
              const response = await axios.get(ACCESS_BY_USER_ID);
              const data = response.data;
              const status = data.status;
              const result = data.result;
              console.log("Group value:", result);
              if(status){
                  setUserAccess(result);
                  const userGroup = result[0]?.userGroup;
                  if (userGroup) {
                      console.log("Group value:", userGroup.group);
                      setUserType(userGroup.group);
                  }
              } else {        
                  setUserAccess([]);
              }
          } catch (err) {
              variant = 'error';
              if (!err.response) {
                  enqueueSnackbar(ErrorMsgs[0].noResponse, {variant});
              } else if (err.response !== 403) {
                  enqueueSnackbar(ErrorMsgs[0].fetchError, {variant});
              }               
          }
      };
  
      fetchAccessById(); // Call the function inside useEffect
  
  }, [userType]); // Empty dependency array, meaning it only runs once after component mount

  console.log(userType)
  
    const fetchUserTypes = async () => {
        try {
        const response = await axios.get(USER_TYPE_URL+`/1`);
        const status= response.data.status;
        const result = response.data.result;
        console.log("Group value:", result);
        if(status){
            setUserTypeOptions(result);
        }else{
            setUserTypeOptions([]);
        }
        }catch (err) {
        variant = 'error'
        if(!err.response){
            enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
        }else if(err.response !== 403){
            enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
        } 
        }
    }

    useEffect(() => {
       
        fetchUserTypes();
      },[])

      useEffect(() => {

        if(!isEmptyArray(userTypeOptions) && !isEmptyArray(UserAccess)){
          const userCreateType = userTypeOptions.filter(userType => {
            const type = userType.type
            const userCreateType = ('USER_CREATE_TYPE_' + type)
             return UserAccess.some(access => access.access.access === userCreateType
            );            
          })
          setAllowedUserTypes(userCreateType)
          
        }
      
      },[userTypeOptions, UserAccess, isEmptyArray])

  return allowedUserType
}

export default useUserType */


import React, { useEffect, useState, useContext } from 'react';
import axios from '../../src/api/axios';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../src/Data/Data';
import DataContext from '../../src/context/DataContext';

const ACCESS_BY_USER_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;
const USER_TYPE_URL = process.env.REACT_APP_API_USER_TYPE_FIND_ALL;

const useUserType = () => {
    let variant = 'success';
    const { isEmptyArray, setUserType, userType } = useContext(DataContext);

    const [userTypeOptions, setUserTypeOptions] = useState([]);
    const [UserAccess, setUserAccess] = useState([]);
    const [allowedUserType, setAllowedUserTypes] = useState([]);

    useEffect(() => {
        const fetchAccessById = async () => {
            try {
                const response = await axios.get(ACCESS_BY_USER_ID);
                const data = response.data;
                const status = data.status;
                const result = data.result;

                if (status && result.length > 0) {
                    const userGroup = result[0]?.userGroup;
                    if (userGroup) {
                        setUserType(userGroup.group);
                    }
                    setUserAccess(result);
                } else {
                    setUserAccess([]);
                }
            } catch (err) {
                variant = 'error';
                if (!err.response) {
                    enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
                } else if (err.response.status !== 403) {
                    enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
                }
            }
        };

        fetchAccessById();
    }, []); // No dependencies here, runs only once


    useEffect(() => {
        const fetchUserTypes = async () => {
            try {
                const response = await axios.get(USER_TYPE_URL + `/1`);
                const status = response.data.status;
                const result = response.data.result;

                if (status) {
                    setUserTypeOptions(result);
                } else {
                    setUserTypeOptions([]);
                }
            } catch (err) {
                variant = 'error';
                if (!err.response) {
                    enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
                } else if (err.response.status !== 403) {
                    enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
                }
            }
        };

        fetchUserTypes();
    }, []); // Fetch user types once on mount

    useEffect(() => {
        if (!isEmptyArray(userTypeOptions) && !isEmptyArray(UserAccess)) {
            const userCreateType = userTypeOptions.filter(userType => {
                const type = userType.type;
                //console.log("User Type Options:", userTypeOptions);
            //console.log("User Access:", UserAccess);
                const userCreateType = 'USER_CREATE_TYPE_' + type;
                return UserAccess.some(access => access.access.access === userCreateType);
            });
            setAllowedUserTypes(userCreateType);
        }
    }, [userTypeOptions, UserAccess]); // Only dependent on these two

    return allowedUserType;
};

export default useUserType;
