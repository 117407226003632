import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from '../../api/axios';
import SnackbarAlerts from "../SnackbarAlerts";
import DataContext from '../../context/DataContext';
import { Status } from '../../Data/Data';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data';
import DataTable from 'react-data-table-component';
import ConfirmDialog from '../ConfirmDialog';

const USER_GROUP_URL = process.env.REACT_APP_API_USER_GROUP_FIND_ALL;
const USER_GROUP_BY_ID = process.env.REACT_APP_API_USER_GROUP_FIND_BY_ID;
const USER_GROUP_SAVE = process.env.REACT_APP_API_USER_GROUP_SAVE;
const USER_GROUP_UPDATE = process.env.REACT_APP_API_USER_GROUP_UPDATE;
const USER_GROUP_HAS_USERS = process.env.REACT_APP_API_USER_GROUP_HAS_USERS;

const UserGroups = () => {
  let variant;

  const { isEmpty } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('success');
  const [priority, setPriority] = useState(0);
  const [group, setGroup] = useState('');
  const [status, setStatus] = useState('');

  const [dataId, setDataId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const editConfirm = "Are you sure you want to update this field?";
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [editObj, setEditObj] = useState({});
  const [initialValues, setInitialValues] = useState({ group: '', status: '', priority: 0 });

  const columns = [
    {
      name: 'Group',
      selector: row => row.group,
      wrap: true,
    },
    {
      name: 'Status',
      selector: row => (row.status === true) ? 'Active' : 'De-active',
      wrap: true,
    },
    {
      name: 'Priority',
      selector: row => row.priority,
      wrap: true,
    },
    {
      name: 'Actions',
      button: true,
      width: '300px',
      selector: row => row.vatNo,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            className="edit-button"
            variant="warning"
            onClick={() => actionClick(row.id, 'edit')}
          >
            Edit
          </Button>
          <Button
            className={row.status === true ? "delete-button" : 'hide'}
            variant="danger"
            onClick={() => enableDisable(row.id, 'disable', row.group, row.priority)}
          >
            Disable
          </Button>
          <Button
            className={row.status === false ? "success-button" : 'hide'}
            variant="success"
            onClick={() => enableDisable(row.id, 'enable', row.group, row.priority )}
          >
            Enable
          </Button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overflowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'
      },
    },
  };

  const actionClick = (id, action) => {
    setDataId(id);
    if (action === 'edit') {
      setOpenEdit(true);
    }
  }

  const enableDisable = async (id, action, group, priority) => {
    if (action === 'disable') {
      try {
        const response = await axios.get(USER_GROUP_HAS_USERS +  `${id}`);
        const hasUsers = response.data;
        //console.log(hasUsers)
        if (hasUsers.length > 0) {
          variant = 'error';
          enqueueSnackbar('Cannot disable this group because it has associated users.', { variant });
          return; // Stop further execution
        }
      } catch (err) {
        variant = 'error';
        enqueueSnackbar('Failed to check if the group has users. Please try again.', { variant });
        return; // Stop further execution
      }
    }
  
    setDataId(id);
    if (action === 'disable') {
      setOpenDelete(true);
      setConfirmMsg("Are you sure you want to disable this field?");
      setEditObj({ id, status: false, group, priority });
    } else if (action === 'enable') {
      setOpenDelete(true);
      setConfirmMsg("Are you sure you want to enable this field?");
      setEditObj({ id, status: true, group, priority });
    }
  }

  const handleEnableDisable = async () => {
    setOpenDelete(false);
    try {
      const response = await axios.put(USER_GROUP_UPDATE, editObj);
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        variant = 'success';
        fetchUserGroup();
        enqueueSnackbar('Updated Successfully!', { variant });
        setDataId(0);
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      setDataId(0);
    }
  }

  //fetch all userGroup
  const fetchUserGroup = async () => {
    try {
      const response = await axios.get(USER_GROUP_URL + `/2`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      //console.log('Result', result)
      if (status) {
        setData(result);
      } else {
        setData([]);
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  }

  useEffect(() => {
    fetchUserGroup();
  }, []);

  //handle submit method
  const handleSubmit = async (e) => {
    e.preventDefault();
    const intPriority = parseInt(priority, 10);
    const newStatus = status === "true" ? true : false;
    const newData = { group, status: newStatus, priority: intPriority };
    try {
      const response = await axios.post(USER_GROUP_SAVE, newData);
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        variant = 'success';
        enqueueSnackbar('Adding New User Group Successful!', { variant });
        setGroup('');
        setStatus('');
        setPriority(0);
        fetchUserGroup();
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  }

  const handleEdit = async (e) => {
    e.preventDefault();
    const intPriority = parseInt(priority, 10);
    const newStatus = status === "true" ? true : false;
    const updateGroup = { id: dataId, group, status: newStatus, priority: intPriority };
    try {
      const response = await axios.put(USER_GROUP_UPDATE, updateGroup);
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        variant = 'success';
        enqueueSnackbar('Updated Successfully!', { variant });
        setDataId(0);
        setGroup('');
        setStatus('');
        setPriority(0);
        fetchUserGroup();
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      setDataId(0);
    }
  }

  useEffect(() => {
    if (priority !== null) {
      setPriority(Number(priority));
    }
  }, [priority]);

  const validatePriority = (value) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue <= 0) {
      return false;
    }
    return true;
  };

  const handlePriorityChange = (e) => {
    const { value } = e.target;
    if (validatePriority(value)) {
      setPriority(value);
    } else {
      setPriority('');
    }
  };

  const canSubmit = group && status !== '' && validatePriority(priority);
  const hasChanges = group !== initialValues.group || status !== initialValues.status || priority !== initialValues.priority;

  //Get customer by ID to update
  const getUserGroup = async () => {
    setOpenEdit(false);
    try {
      const response = await axios.get(USER_GROUP_BY_ID + `${dataId}`);
      const status = response.data.status;
      const result = response.data.result;
      const priority = response.data.result.priority;
      const msg = response.data.msg;
      if (status) {
        setGroup(result.group);
        setStatus(result.status.toString());
        setPriority(priority);
        setInitialValues({ group: result.group, status: result.status.toString(), priority });
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  }

  return (
    <>
      <ConfirmDialog
        open={openEdit}
        msg={editConfirm}
        setOpen={setOpenEdit}
        onYes={() => getUserGroup()}
      />
      <ConfirmDialog
        open={openDelete}
        msg={confirmMsg}
        setOpen={setOpenDelete}
        onYes={() => handleEnableDisable()}
      />
      <SnackbarAlerts
        open={open}
        setOpen={setOpen}
        msg={Msg}
        severity={severity}
      />
      <MDBCard className='card-table'>
        <MDBCardBody className='p-0'>
          <MDBRow className='bg-blue p-3 mb-2'>
            <Form onSubmit={handleSubmit}>
              <MDBRow>
                <MDBCol md='3' className='mb-1 mu-1'>
                  <Form.Group className='mb-3' >
                    <Form.Label className='white-label' size='sm'>Group</Form.Label>
                    <span className="mandatory"> *</span>
                    <Form.Control
                      type='text'
                      id='groupId'
                      onChange={(e) => setGroup(e.target.value)}
                      value={group}
                    />
                  </Form.Group>
                </MDBCol>
                <MDBCol md='3'>
                  <Form.Group className='mb-3' >
                    <Form.Label className='white-label' size='sm'>Status</Form.Label>
                    <span className="mandatory"> *</span>
                    <Form.Select
                      id='statusId'
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option disabled value=''>Status...</option>
                      {Status.map((options, index) => (
                        <option key={index} value={options.value}>{options.select}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </MDBCol>
                <MDBCol md='3' className='mb-1 mu-1'>
                  <Form.Group className='mb-3'>
                    <Form.Label className='white-label' size='sm'>Priority</Form.Label>
                    <span className="mandatory"> *</span>
                    <Form.Control
                      type="number"
                      value={priority}
                      onChange={handlePriorityChange}
                      required
                      min="0"
                    />
                  </Form.Group>
                </MDBCol>
                <MDBCol md='3' className='mb-3'>
                  <Button
                    className={dataId === 0 ? '' : 'hide-row'}
                    style={{ marginTop: '30px', width: '100%' }}
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                  >
                    Add New
                  </Button>
                  <Button
                    className={dataId === 0 ? 'hide-row' : ''}
                    style={{ marginTop: '30px', width: '100%' }}
                    onClick={handleEdit}
                    disabled={!hasChanges || !canSubmit}
                  >
                    Edit
                  </Button>
                </MDBCol>
              </MDBRow>
            </Form>
          </MDBRow>
          <MDBRow className='bg-white p-3 h-80'>
            <MDBCol md='12'>
              <MDBRow>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  persistTableHead
                  responsive
                  customStyles={customStyles}
                />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </>
  );
}

export default UserGroups;
