import React, {useEffect, useState} from 'react'
import './LeftSide.css';
import Cards from './Left/Cards/Cards';
import CardsTwo from './Left/Cards/CardsTwo';
import Table from './Left/Table/Table'

const LeftSide = () => {


  return (
    <div className='LeftSide'>
        <h1 className="Dash-text" >Dashboard</h1>
        <Cards /> 
        {/*<CardsTwo /> */}
        <Table />
    </div>
  )
}

export default LeftSide