import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, ProgressBar, Alert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { enqueueSnackbar } from 'notistack';
import axios from '../../api/axios';

const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/api/file/upload`;
const FILE_LIST = `${process.env.REACT_APP_API_URL}/api/file/list`;
const FILE_DELETE = `${process.env.REACT_APP_API_URL}/api/file/delete/`;

const FileUpload = () => {
  const [file, setFile] = useState(null); // Initialize as null
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [uploadKey, setUploadKey] = useState(0); // Key to reset file input
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get(FILE_LIST);
      if (response.data.status) {
        const files = response.data.result.map((fileName, index) => ({
          id: index + 1,
          fileName: fileName,
        }));
        setUploadedFiles(files);
      } else {
        enqueueSnackbar('Failed to fetch files.', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch files.', { variant: 'error' });
      console.error('Error fetching files:', error.message);
    }
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, [uploadKey]); // Refetch when uploadKey changes

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null); // Clear error message when a new file is selected
  };

  const handleFileUpload = async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }
  
    // Check if the file already exists in uploadedFiles
    const fileExists = uploadedFiles.some((uploadedFile) => uploadedFile.fileName === file.name);
    if (fileExists) {
      setError('This file has already been uploaded.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post(UPLOAD_URL, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentage);
        },
      });
  
      if (response.data === "SUCCESS") {
        enqueueSnackbar('Uploaded Successfully!', { variant: 'success' });
        setFile(null); // Clear file input
        setUploadKey(uploadKey + 1); // Increment key to reset file input
        fetchUploadedFiles(); // Refetch file list
      }
    } catch (error) {
      if (!error.response) {
        enqueueSnackbar('No response from server.', { variant: 'error' });
      } else if (error.response.status !== 403) {
        enqueueSnackbar('Failed to upload the file.', { variant: 'error' });
      }
      console.error('Error uploading file:', error.message);
    } finally {
      // Reset progress in finally block to ensure it always gets reset
      setProgress(0);
    }
  };

  const handleDeleteFile = async (fileName) => {
    try {
      const response = await axios.delete(FILE_DELETE + fileName);
      if (response.status === 200) {
        enqueueSnackbar('File deleted successfully!', { variant: 'success' });
        fetchUploadedFiles(); // Refresh file list
      }
    } catch (error) {
      enqueueSnackbar('Failed to delete the file.', { variant: 'error' });
      console.error('Error deleting file:', error.message);
    }
  };
  
  const columns = [
    {
      name: 'Index',
      selector: 'id',
      sortable: true,
      maxWidth: '70px',
    },
    {
      name: 'File Name',
      selector: 'fileName',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <Button
        className={ "delete-button" }
          variant="danger"
          onClick={() => handleDeleteFile(row.fileName)}
          style={{ height: '30px', padding: '0.2rem 0.5rem' }}
        >
          Delete
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overflowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  };

  // Use useMemo to create a new key when uploadKey changes
  const uploadKeyProp = useMemo(() => uploadKey, [uploadKey]);

  return (
    <MDBCard className='card-table'>
      <MDBCardBody className='p-0'>
        <MDBRow className='bg-blue p-3 mb-2'>
          <Form>
            <MDBRow>
              <MDBCol md='9' className='mb-1 mu-1'>
                <Form.Group controlId="formFile" className="mb-10">
                  <Form.Label>Upload File</Form.Label>
                  <Form.Control
                    key={uploadKeyProp} // Key to reset the component
                    type="file"
                    onChange={handleFileChange}
                    style={{ marginInlineEnd: '32.8rem' }}
                  />
                </Form.Group>
              </MDBCol>
              <MDBCol md='3' className='mb-3 d-flex align-items-end'>
                <Button
                  style={{ width: '100%', marginTop: '31.5px' }}
                  onClick={handleFileUpload}
                >
                  Upload
                </Button>
              </MDBCol>
            </MDBRow>
            {error && <Alert variant="danger">{error}</Alert>}
          </Form>
        </MDBRow>
        {progress > 0 && progress < 100 && (
          <MDBRow className='bg-blue p-3 mb-2'>
            <ProgressBar now={progress} label={`${progress}%`} />
          </MDBRow>
        )}
        <MDBRow className='bg-white p-3 h-80'>
          <MDBCol md='12'>
            <MDBRow>
              <DataTable
                columns={columns}
                data={uploadedFiles} // Use the uploadedFiles state for table data
                pagination
                persistTableHead
                responsive
                customStyles={customStyles}
              />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default FileUpload;
