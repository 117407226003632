import React, { useRef, useState, useEffect, useContext, } from 'react';
import { MDBContainer, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { axiosNoAuth } from '../../api/axios';
import DataContext from '../../context/DataContext';
import AuthContext from '../../context/AuthProvider';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data';
import axios from '../../api/axios';
/* import useAuth from '../../hooks/useAuth'; */

const LOGIN_URL = process.env.REACT_APP_API_AUTH_AUTHENTICATE
//const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;

function Login() {

  localStorage.removeItem('err');
  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();
  const login = 'LOGIN';
  const accessValue = 'LOGIN';
  let variant

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [logingAllowed, setLogingAllowed] = useState(true)
  //const [fetchAccessSuccess, setFetchAccessSuccess] = useState(true); // Default to true
  //const [isButtonEnabled, setButtonEnabled] = useState(false);

  const { loggedUser, setLoggedUser } = useContext(DataContext)
  const { adminAccess, setAdminAccess, type, fetchSellers, fetchUserAccessControls } = useContext(AuthContext);

  useEffect(() => {
    document.title = 'Login';
    userRef.current.focus();
    localStorage.clear();
  }, [])

  useEffect(() => {
    setErrMsg(errMsg);
    setLoggedUser(user);
  }, [user, pwd, setLoggedUser])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const login = { uname: user, password: pwd }
    try {
      const response = await axiosNoAuth.post(LOGIN_URL, login);
      const data = response.data;
      if (data.status) {
        sessionStorage.setItem('token', data.result.token);
        sessionStorage.setItem('refresh-Token', data.result.refreshToken);
        await fetchUserAccessControls();
        await fetchSellers(type);
        setLogingAllowed(true);
        navigate('/dashboard');

        /* sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJTWVNURU0iLCJleHAiOjE2ODU3MDA1MzksImlhdCI6MTY4NTcwMDUwOSwiYXV0aG9yaXRpZXMiOlt7ImF1dGhvcml0eSI6IkFkbWluaXN0cmF0b3IifV19.5bBjpE5dZvmHNdklpJiHRMo2Z3ilTpCMrXo51E9phfU');
        sessionStorage.setItem('refresh-Token', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJTWVNURU0iLCJleHAiOjE2ODU3MDA1MzksImlhdCI6MTY4NTcwMDUwOSwiYXV0aG9yaXRpZXMiOlt7ImF1dGhvcml0eSI6IkFkbWluaXN0cmF0b3IifV19.5bBjpE5dZvmHNdklpJiHRMo2Z3ilTpCMrXo51E9phfU');
         */
      } else {
        setErrMsg(data.msg)
      }
      errRef.current.focus();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setErrMsg(err.response.data.msg);
      } else {
        setErrMsg('No Server Response!');
      }
    }
   // console.log('errMsg:', errMsg);
    //console.log('logingAllowed:', logingAllowed);
  }

  const handleUsernameChange = (e) => {
    setUser(e.target.value);
    setErrMsg(''); // Clear error message when username changes
  };

  const handlePasswordChange = (e) => {
    setPwd(e.target.value);
    setErrMsg(''); // Clear error message when password changes
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault(); // Prevent the default action of the click event
    console.log("Forgot Password link clicked!"); // Check if the function is being called
    navigate('/forgot-password');
};

  

  return (
    <MDBContainer fluid className=' d-flex justify-content-center align-items-center' style={{ marginTop: '10%' }}>
      <MDBCard className='d-flex justify-content-center align-items-center p-3  login-card' style={{ borderRadius: '15px' }}>
        <MDBCardBody className='p-0 login-cardBody'>
          {errMsg && (
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
          )}

          <Form onSubmit={handleSubmit} className='text-center bg-warning; p-3'>
            <h1 className='mb-3 fs-3 fw-normal'>Login</h1>
            <Form.Group className='mb-3 '>
              <Form.Control
                type='text'
                id='username'
                ref={userRef}
                placeholder='Email address'
                autoComplete='off'
                //onChange={(e) => setUser(e.target.value)}
                onChange={handleUsernameChange}
                value={user}
                required
              />
            </Form.Group>
            <Form.Group className='mb-3 '>
              <Form.Control
                type='password'
                id='password'
                placeholder='password'
                //onChange={(e) => setPwd(e.target.value)}
                onChange={handlePasswordChange}
                value={pwd}
                required
              />
            </Form.Group>
            <div className='d-grid mb-3'>
              <Button type="submit" variant="primary" >Login</Button>
            </div>
            <div style={{ textAlign: 'left' }}>
      <Link to="/forgot-password" onClick={handleForgotPasswordClick}>Forgot Password?</Link>
    </div>
          </Form>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default Login;
