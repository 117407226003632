import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Button from 'react-bootstrap/Button';
import DataContext from '../../context/DataContext';
import AuthContext from '../../context/AuthProvider';



const ExtendedKeyTable = ({ extendedKeyDetails, setExtendedKeyDetails }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overflowWrap: 'break-word', // Fixed typo here
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  };
  const [openTable, setOpenTable] = useState(true);
  const { fullName } = useContext(DataContext)
const { printAccess } = useContext(AuthContext)

  const columns = React.useMemo(
    () => [
    
     /*  {
        name: 'UUID',
        selector: (row) => row.keyGen?.uuid || 'N/A',
        wrap: true,
        compact: true,
      }, */
     
      {
        name: 'Created By',
        selector: (row) => row.createdBy || 'N/A',
        wrap: true,
      },
      {
        name: 'Created Date',
        selector: (row) => row.modifiedDate || 'N/A',
        wrap: true,
      },
      {
        name: 'Days',
        selector: (row) => {
          const fromDate = new Date(row.fromDate);
          const toDate = new Date(row.toDate);
          const dateDifference = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24)); // Calculate difference in days
          return dateDifference;
        },
        wrap: true,
      },
      {
        name: 'Users',
        selector: (row) => row.users || 'N/A',
        wrap: true,
      },
      /* {
        name: 'Status',
        selector: (row) => (row.keyGen?.status ? 'Active' : 'Inactive') || 'N/A',
        wrap: true,
      }, */
      {
        name: 'From Date',
        selector: (row) => row.fromDate || 'N/A',
        wrap: true,
      },
      {
        name: 'To Date',
        selector: (row) => row.toDate || 'N/A',
        wrap: true,
      },
     
      // ... Add other columns based on your extendedKeyDetails structure
    ],
    [/* Add dependencies if needed */]
  );

  const flattenedData = extendedKeyDetails ? [...extendedKeyDetails].reverse() : []; // Reverse the array

  const calculateDaysDifference = (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      return 'N/A';
    }
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  };


  const handlePrint = () => {

    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();
    const reversedExtendedKeyDetails = [...extendedKeyDetails].reverse();

    const content = `
    
    <html>
      <head>
        <!-- Include any necessary styles here -->
        <style>
          /* Define print-specific styles here */
          body {
            font-family: 'Calibri Light', sans-serif;
          }
          .print {
            margin-top: none;
          }
          .header {
            font-size: 16px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .top-header {
            font-size: 16px;
            font-weight: bold;
            color: #595757; /* Light gray color */
          }
          .sub-header {
            font-size: 13px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .content {
            font-size: 13px;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table tbody { 
            padding-bottom: 30px;  
          }
          .table th {
            border: 1px solid #dddddd; /* Light gray border */
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table td {
            border: 1px solid #a6a6f5; /* Dark blue border */
            background-color: #fefefe;
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
          }
          .table th {
            background-color: #f2f2f2; /* Light gray background */
            color: #595757; /* Light gray color */
          }
          .footer {
            position: fixed;
            bottom: -10.5px;
            width: 100%;
            text-align: right;
            padding: 10px 0;
            border-top: 1px solid #ddd; /* Light gray border */
            margin-top: 50px;
          }
        </style>
      </head>
      <body>
      <div class="print">
      <div class="top-header">
        <div style="display: flex; justify-content: space-between; align-items: center;">
        <p style="margin: 0; font-size: 28px; ">KEY EXTEND DETAILS OF "${extendedKeyDetails.length > 0 ? extendedKeyDetails[0].keyGen?.uuid || 'N/A' : 'N/A'}" </p>
      
        <div style="text-align: right;">
            &nbsp; 
            <p style="color: #888888; margin: 0;">Date: ${printDateTime}</p>
            <p style="color: #888888; margin: 0;">User Name: ${fullName.result ? (fullName.result.fname + (fullName.result.lname ? ' ' + fullName.result.lname : '')) : 'N/A'}</p>
          </div>
        </div>
      </div>
    </div>
    
        <br>
    
    
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
              <th>User</th>
              <th>Days</th>
              
              <th>Users</th>
              <th>Key Type</th>
              <th>From Date</th>
              <th>To Date</th>
              
            </tr>
          </thead>
          <tbody>
            ${reversedExtendedKeyDetails.map(row => `
              <tr>
                <td>${row.createdDate || 'N/A'}</td>
                <td>${row.description || 'N/A'}</td>
                <td>${row.createdBy || 'N/A'}</td>
                <td>${calculateDaysDifference(row.fromDate, row.toDate) || 'N/A'}</td>
                <td>${row.users || 'N/A'}</td>
                
                
                <td>${row.keyGen.type || 'N/A'}</td>
               
                <td>${row.fromDate || 'N/A'}</td>
                <td>${row.toDate || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
    
        <script>
          
    
            // Trigger the printing process
            window.print();
            window.close(); // Close the window after printing (optional)
          
    
          
        </script>
    
      </body>
    </html>
    
    
    `;

    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();

    } else {
      console.error('Failed to open print window');
    }
  };




  return (


    <>
     
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className='responsive-div' style={{
        padding: '20px',
        borderBottom: '1px solid #40c4ff',
        fontSize: '18px',
        fontWeight: 'bold',
      }}>
        {flattenedData[0]?.keyGen?.uuid || 'N/A'} - {flattenedData[0]?.description || 'N/A'} Details
      </div>
      {printAccess && extendedKeyDetails && extendedKeyDetails.length > 0 && (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handlePrint} className="m-1" style={{ padding: '3px', width: '90px' }}>
            <IconButton size="small">
              <PrintIcon style={{ fontSize: '18px', color: 'white' }} />
            </IconButton>
            <span style={{ fontSize: '18px' }}>Print</span>
          </Button>
        </div>
      )}
    </div>

    <div style={{ overflowX: 'auto', maxHeight: '400px' }}> 
        {/* <DataTable
          columns={columns}
          data={extendedKeyDetails ?  [extendedKeyDetails] : []}
          customStyles={customStyles}
          noHeader
          highlightOnHover
        /> */}
        <DataTable
        columns={columns}
        striped
        data={flattenedData}
        pagination
        persistTableHead
        responsive
        customStyles={customStyles}
        highlightOnHover
      />
      </div>
    </>
  );
 
    // Render the table
  
    // Render the message
 
  
  
};

export default ExtendedKeyTable;
