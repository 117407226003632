import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { axiosNoAuth } from '../../api/axios'; // Import axiosNoAuth
import DataContext from '../../context/DataContext';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const VERIFY_EMAIL = process.env.REACT_APP_APP_AUTH_VERIFY;
const VERIFY_TEMP_PASSWORD = process.env.REACT_APP_API_VERIFY_TEMP_PASSWORD;
const RESET_PASSWORD =  process.env.REACT_APP_API_AUTH_RESET_PASSWORD;
const RESET_PASS_PERMANENTLY = process.env.REACT_APP_API_AUTH_PASS_RESET;

const ResetPassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const navigate = useNavigate();

    const [tempPassword, setTempPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
    const [userId, setUserId] = useState(null);
    const [validPwd, setValidPwd] = useState(false);
    //const [pwdFocus, setPwdFocus] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const PWD_REGEX = /^.{6,10}$/;

    useEffect(() => {
        const getUserIdByEmail = async () => {
            try {
                const response = await axiosNoAuth.post( VERIFY_EMAIL, { email });
                if (response.data.status) {
                    setUserId(response.data.result);
                    //setDisabled(true)
                } else {
                    setError('User not found with the provided email.');
                }
            } catch (error) {
                setError('An error occurred while retrieving the user ID.');
            }
        };

        getUserIdByEmail(); // Call the function when the component mounts
    }, [email]);

    const handleTempPasswordSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                userEmail: email,
                temporaryPassword: tempPassword
            };

            const response = await axiosNoAuth.post(VERIFY_TEMP_PASSWORD , data);

            if (response.data.status) {
                //setMessage(response.data.msg);
                setShowNewPasswordForm(true);
                setDisabled(true)
            } else {
                setError('Invalid temporary password.'); // Set error message for invalid temporary password
            }
        } catch (error) {
            setError('An error occurred while verifying the temporary password.');
        }
    };

    const handleTempPasswordChange = () => {
        setError('');
    };

    const handleNewPasswordSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            setError('User ID not found.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axiosNoAuth.post(RESET_PASSWORD , {
                idUser: userId,
                password: newPassword
            });

            if (response.data.status) {
                setMessage('Password reset successfully.');

                setTimeout(() => {
                    setMessage('');
                    navigate('/'); // Redirect to the login page
                }, 3000);
            } else {
                setError('Failed to reset password.');
            }
        } catch (error) {
            setError('An error occurred while resetting the password.');
        }
    };

    const handleNewPasswordChange = (e) => {
        
            const newPasswordValue = e.target.value;
            // Check if the new password contains any spaces
            if (newPasswordValue.includes(' ')) {
                
                setValidPwd(false);
            } else {
                setNewPassword(newPasswordValue);
                setError(''); // Clear error message when new password is valid
                setValidPwd(PWD_REGEX.test(newPasswordValue));
            }
        
        
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPasswordValue = e.target.value;
    
        if (confirmPasswordValue.includes(' ')) {
            //setError('Password cannot contain spaces.');
            setValidPwd(false);
        } else {
            setConfirmPassword(confirmPasswordValue);
            setError(''); // Clear error message when confirm password is changed
            setValidPwd(true);
        }
    };
    

    const handleResendTemporaryPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosNoAuth.post(VERIFY_EMAIL , { email });


            if (response.data.status) {
                const userId = response.data.result;
                // setMessage(`User ID: ${userId}`);
                setDisabled(true)
                // Call the reset password endpoint with the retrieved userId
                const resetResponse = await axiosNoAuth.post(RESET_PASS_PERMANENTLY , { userIds: [userId] });

                if (resetResponse.data.status) {
                    setMessage('Reset Password Resend successfully.');
                    setTimeout(() => {
                        window.location.href = `/license/reset-password?email=${email}`;
                    }, 2000);
                } else {
                    setError('Failed to reset password.');
                }
            } else {
                setError('User not found with the provided email');
            }
        } catch (error) {
            setError('An error occurred while retrieving the user ID.');
        }
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };



    return (
        <MDBContainer fluid className=' d-flex justify-content-center align-items-center' style={{ marginTop: '10%' }}>
            <MDBCard className='d-flex justify-content-center align-items-center p-3  login-card' style={{ borderRadius: '15px' }}>
                <MDBCardBody className='p-0 login-cardBody'>
                    {showNewPasswordForm ? (
                        <Form onSubmit={handleNewPasswordSubmit} className='text-center bg-warning; p-3'>
                            <h1 className='mb-3 fs-3 fw-normal'>Create New Password</h1>
                            <Form.Group className='mb-3'>
                            <InputGroup>
                                <Form.Control
                                    type={showNewPassword ? 'text' : 'password'}
                                    placeholder='Enter new password'
                                    onChange={handleNewPasswordChange}
                                    value={newPassword}

                                    required
                                />
                                 <InputGroup.Text>
                                        {showNewPassword ? <AiOutlineEyeInvisible onClick={toggleShowNewPassword} /> : <AiOutlineEye onClick={toggleShowNewPassword} />}
                                    </InputGroup.Text>
                                </InputGroup>

                            </Form.Group>
                            <Form.Group className='mb-3'>
                            <InputGroup>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder='Confirm new password'
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmPassword}
                                    required
                                />
                                  <InputGroup.Text>
                                        {showConfirmPassword ? <AiOutlineEyeInvisible onClick={toggleShowConfirmPassword} /> : <AiOutlineEye onClick={toggleShowConfirmPassword} />}
                                    </InputGroup.Text>
                                </InputGroup>

                            </Form.Group>
                            <div className='d-grid mb-3'>
                                <Button type='submit' variant='primary' disabled={!validPwd} >
                                    Reset Password
                                </Button>
                            </div>
                            <p className={!validPwd && newPassword !== "" ? "validate" : "hide"}>
                                Password should contain 6 to 10 characters
                            </p>
                            {error && <p className='mb-0' style={{ color: 'red' }}>{error}</p>}
                            {message && <p className='mb-0'>{message}</p>}
                            <div style={{ textAlign: 'left' }}>
                                <Link to="/">Go back to Login</Link>
                            </div>
                        </Form>
                    ) : (
                        <Form onSubmit={handleTempPasswordSubmit} className='text-center bg-warning; p-3'>
                            <h1 className='mb-3 fs-3 fw-normal'>Reset Password</h1>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type='password'
                                    placeholder='Enter temporary password'
                                    onChange={(e) => {
                                        setTempPassword(e.target.value);
                                        handleTempPasswordChange(); // Clear error message when temporary password is changed
                                    }}
                                    value={tempPassword}
                                    required
                                />
                            </Form.Group>
                            <div className='d-grid mb-3'>
                                <Button type='submit' variant='primary' disabled={disabled}>
                                    Submit
                                </Button>
                            </div>
                            <div className='d-grid mb-3'>
                                <Button onClick={handleResendTemporaryPassword} variant='secondary'>
                                    Resend
                                </Button>
                            </div>
                            {message && <p className='mb-0'>{message}</p>}
                            {error && <p className='mb-0' style={{ color: 'red' }}>{error}</p>}

                        </Form>
                    )}

                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
}

export default ResetPassword;
