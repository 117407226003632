import React,{useState, useEffect, useContext} from 'react';
import { MDBRow, MDBCol}from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import { GrClose } from 'react-icons/gr';
import DataContext from '../../context/DataContext';
import KeyGenData from '../../context/KeyGenData';
import Form from 'react-bootstrap/Form';
import { Variables, ErrorMsgs } from '../../Data/Data';
import Button from 'react-bootstrap/Button';
import { enqueueSnackbar } from 'notistack';
import axios from '../../api/axios';

const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;
const UUID_SEARCH = process.env.REACT_APP_API_CHECK_UUID;
const UUID_UPDATE = process.env.REACT_APP_API_KEY_UPDATE_UUID;

const EditUUIDPopup = (props) => {

    let variant

    function useValidation(value, regex) {
      const [isValid, setIsValid] = useState(false);
    
      const validate = () => {
        setIsValid(regex.test(value));
      };
    
      return [isValid, validate];
    }

    const {isEmpty} = useContext(DataContext);
    const {setUuidEdit, uuidEdit, uuidEdited, setUuidEdited} = useContext(KeyGenData)

    const [oldUuid, setOldUuid] = useState('')

    const [uuid, setUuid] = useState('')
    const [uuidFocus, setUuidFocus] = useState(false);
    const [isValidUuid, validateUuid] = useValidation(uuid, UUID_REGEX);
    const [validUuid, setValidUuid] = useState(false); 

    const [uuidStatus, setUuidStatus] = useState(true);
    const [uuidChecked, setUuidChecked] = useState(true)
    const [usedUuid, setUsedUuid] = useState(false)
    

    

  useEffect(() => {
    if(uuidEdit !== '' && props.open){
      setOldUuid(uuidEdit);
      setUuidEdit('')
    }
  },[uuidEdit, oldUuid, setUuidEdit, props])

    //validate uuid
    useEffect(() => {
      setValidUuid(UUID_REGEX.test(uuid));
      if(uuid.includes(' ')){
        setUuid(uuid.trim())
      }
    }, [uuid])

  const handleClose = (reason) => {
    setOldUuid('')
    setUuid('')
    setUuidFocus(false)
    setUsedUuid(false)
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  }

 /*  const handleSubmit = async (e) => {
    e.preventDefault();
    try {        
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
        const data = response.data
        setUuidStatus(data.status);
        if(data.status){
          setUuidChecked(true)
          setUsedUuid(true)
          return
        }else{
          setUuidChecked(false)
          setUsedUuid(false)
          const edit = {newUuid : uuid , oldUuid: oldUuid}
          try{
            const response = await axios.post(UUID_UPDATE, edit);
              const data = response.data;
              if(data.status){
                variant = 'success'
                enqueueSnackbar('UUID Successfully Updated!', {variant}) 
                setUuidEdited(true);
                props.setOpen(false);
                setOldUuid('')
                setUuid('')
                setUuidFocus(false)
                setUsedUuid(false)
                props.fetch()
              }else{
                variant = 'error'
                enqueueSnackbar('UUID Update Failed!', {variant}) 
                setUuidEdited(false);
                return
              }
          }catch (err){    
            variant = 'error'
            if(!err.response){
              enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
            }else if(err.response !== 403){
              enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
            }
          }
        }
    }catch(err){    
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  } */

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uuid === oldUuid) {
      variant = 'error';
      enqueueSnackbar('The new UUID is same as the old UUID.', { variant });
      return;
    }

    try {
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      const data = response.data;
      setUuidStatus(data.status);
      if (data.status) {
        setUuidChecked(true);
        setUsedUuid(true);
        return;
      } else {
        setUuidChecked(false);
        setUsedUuid(false);
        const edit = { newUuid: uuid, oldUuid: oldUuid };
        try {
          const response = await axios.post(UUID_UPDATE, edit);
          const data = response.data;
          if (data.status) {
            variant = 'success';
            enqueueSnackbar('UUID Successfully Updated!', { variant });
            setUuidEdited(true);
            props.setOpen(false);
            setOldUuid('');
            setUuid('');
            setUuidFocus(false);
            setUsedUuid(false);
            props.fetch();
          } else {
            variant = 'error';
            enqueueSnackbar('UUID Update Failed!', { variant });
            setUuidEdited(false);
            return;
          }
        } catch (err) {
          variant = 'error';
          if (!err.response) {
            enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
          } else if (err.response !== 403) {
            enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
          }
        }
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };

  
  return (
    <div className={props.open ? 'glass-effect' : ''}> 
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        >   
          <div className='table-div'> 
            <div className='end-div p-0'>
              <GrClose className='close-button' onClick={handleClose} />
            </div>   
              <div className='d-flex justify-content-center'>              
                <MDBCol md='12' className='bg-white p-4 popup-style m-1'>
                    <Form>
                    <Form.Group className='mb-3 me-0 pe-0' >
                        <Form.Label className='me-0 pe-0' >UUID</Form.Label>
                        <span className="mandatory"> *</span>
                        <div style={{display: 'flex'}}>
                          <Form.Control 
                            type='text' 
                            id='oldUuidId'
                            onChange={(e) => setOldUuid(e.target.value)}                    
                            required
                            value={oldUuid}
                            disabled = {true}
                          />    
                        </div>            
                      </Form.Group> 
                    <Form.Group className='mb-3 me-0 pe-0' >
                        <Form.Label className='me-0 pe-0' >New UUID</Form.Label>
                        <span className="mandatory"> *</span>
                        <div style={{display: 'flex'}}>
                          <Form.Control 
                            className={usedUuid ? 'not-valid' : ''}
                            type='text' 
                            id='vatCodeid'
                            onChange={(e) => setUuid(e.target.value)}                    
                            required
                            value={uuid}
                            onFocus={() => setUuidFocus(true)}
                            onBlur={validateUuid}
                          />   
                        </div> 
                        <span className={usedUuid ? 'validate' : 'hide'}>A key is already generated for this UUID.</span> 
                        <p className={uuidFocus && !validUuid && !isEmpty(uuid) ? 'validate' :'hide'}>Invalid UUID</p>             
                      </Form.Group> 
                        <MDBCol md='12' className='d-flex justify-content-end mb-1'>
                            <Button 
                              color='primary'
                              onClick={handleSubmit}
                              disabled={!validUuid || isEmpty(uuid) || uuid === oldUuid}
                            >
                                Update
                            </Button>
                        </MDBCol>  
                    </Form>                
                </MDBCol>  
              </div> 
          </div> 
      </Popover>
    </div>
  );
}

export default EditUUIDPopup