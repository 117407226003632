import { createContext, useState } from "react";

const KeyGenData = createContext({});

export const KeyGenProvider = ({ children }) => {
    const [value, setValue] = useState(0);
    const [menuValue, setMenuValue] = useState(0);
    const [uuidEdit, setUuidEdit] = useState('');
    const [uuidEdited, setUuidEdited] = useState(false);
    const [keyGenId, setKeyGenId] = useState(0);
    const [uuidExtend, setUuidExtend] = useState('');
    const [unlimited, setUnlimited] = useState(false);
    const [generateUuid, setGenerateUuid] = useState('');
    const [licenseType, setLicenseType] = useState('');
    

   
    
    return(
        <KeyGenData.Provider value ={{ 
            value, setValue, uuidEdit, setUuidEdit, licenseType, setLicenseType,
            keyGenId, setKeyGenId, uuidExtend, setUuidExtend, menuValue, setMenuValue,
            unlimited, setUnlimited, generateUuid, setGenerateUuid, uuidEdited, setUuidEdited
         }}>
            {children}
         </KeyGenData.Provider>
    )
}

export default KeyGenData;